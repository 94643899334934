import React, { useEffect, useState } from 'react'
import Page from '../../components/Page'
import { Box, IconButton, Stack, Container, Divider, Grid, Menu, TextField, ListItemText, ListItemButton, ListItem, Typography, Autocomplete, Button, TableContainer, Table, TableHead, TableCell, TableBody, TableRow, TablePagination } from '@mui/material'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { styled, alpha } from '@mui/material/styles';
import { LoadingButton, LocalizationProvider, MobileDatePicker } from '@mui/lab';
import OrdersTable from './OrdersTable';
import { fDateTo } from '../../utils/formatTime';
import { Api } from '../../components/api';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { CSVLink } from 'react-csv';
import moment from 'moment';


export default function MainWork() {
    useEffect(() => {
        function handleKeyDown(e) {
            if ((e.metaKey || e.ctrlKey) && e.key === 'p') {
                e.preventDefault();
            }
        }
        document.addEventListener('keydown', handleKeyDown);
    }, [document])

    const [search, setSearch] = useState('');
    const [rows, setRows] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const [filterType, setFilterType] = useState([{ label: "ดำเนินการ (P)", type: "P" }, { label: "สำเร็จ (F)", type: "F" }]);



    const schema = Yup.object().shape({

    });
    const selectedTime = new Date();
    const formik = useFormik({
        initialValues: {
            date: selectedTime.setHours(0, 0, 0),
        },
        validationSchema: schema,
        onSubmit: (values, { setSubmitting }) => {

            Api.get(`/TMS/Trip?date=${fDateTo(values.date, 'yyyy-MM-dd')}`)
                .then(res => {
                    setSubmitting(false)
                    setSearch('')
                    setRows(res.data)
                }
                )
        }
    })
    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

    const handelChange = ({ target: { value, name } }) => {
        setSearch(value)
    }
    const handleClickCloseMenu = () => {
        setAnchorEl(null);
        // setSearchGroup("")
    };
    const handleClickOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
        // setSearchGroup("")
    };


    const defaultFilterType = [
        { label: "ออกใบงาน (U)", type: "U" },
        { label: "ดำเนินการ (P)", type: "P" },
        { label: "ผิดพลาด (E)", type: "E" },
        { label: "สำเร็จ (F)", type: "F" },
        { label: "ยกเลิก (D)", type: "D" }
    ]
    const onChangeType = (change) => {

        if (filterType.find(v => v.type === change.type)) {
            const isfilter = filterType.filter(_filter => _filter.type !== change.type)
            setFilterType(isfilter)
            // setFilterType(filter => filter.filter(_filter => _filter.type !== change.type))
        } else (
            setFilterType(filter => filter.concat(change))
        )

    }

    return (
        <Page>
            {/* <Container>
                <Box flexGrow={1} padding={1}>
                    <Typography variant="h4">Work</Typography>
                </Box>
            </Container> */}
            <Divider />
            <MainStyle>
                {/* <Container sx={{ padding: 3 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6} sm={4}>
                                        <MobileDatePicker
                                            disableFuture={true}
                                            label="วันที่"
                                            allowKeyboardControl={false}
                                            value={values.date}
                                            inputFormat="dd/MM/yyyy"
                                            format="dd/MM/yyyy"
                                            onChange={(val) => {
                                                setFieldValue('date', val);
                                            }}
                                            renderInput={(params) => <TextField
                                                size="small"
                                                {...params}
                                                {...getFieldProps('date_r')}
                                                fullWidth
                                                placeholder="DD/MM/YYYY"
                                                autoComplete="off"
                                                error={Boolean(touched.date && errors.date)}
                                                helperText={touched.date && errors.date} />}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <LoadingButton
                                            style={{ height: 40, width: 80 }}
                                            // fullWidth={true}
                                            size="small"
                                            type="submit"
                                            variant="contained"
                                            loading={isSubmitting}

                                        >
                                            ค้นหา
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Form>
                        </FormikProvider>
                    </LocalizationProvider>
                </Container> */}
                <Container sx={{ padding: 2 }} maxWidth={false}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item xs={12} sm={6} md={6} >
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <FormikProvider value={formik}>
                                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                            <Grid container spacing={3}>
                                                <Grid item>
                                                    <MobileDatePicker
                                                        disableFuture={true}
                                                        label="วันที่"
                                                        allowKeyboardControl={false}
                                                        value={values.date}
                                                        inputFormat="dd/MM/yyyy"
                                                        format="dd/MM/yyyy"
                                                        onChange={(val) => {
                                                            setFieldValue('date', val);
                                                        }}
                                                        renderInput={(params) => <TextField
                                                            size="small"
                                                            {...params}
                                                            {...getFieldProps('date_r')}
                                                            placeholder="DD/MM/YYYY"
                                                            autoComplete="off"
                                                            error={Boolean(touched.date && errors.date)}
                                                            helperText={touched.date && errors.date} />}
                                                    />
                                                </Grid>
                                                <Grid item >
                                                    <LoadingButton
                                                        style={{ height: 40, width: 80, boxShadow: "none" }}
                                                        // fullWidth={true}
                                                        size="small"
                                                        type="submit"
                                                        variant="contained"
                                                        loading={isSubmitting}

                                                    >
                                                        ค้นหา
                                                    </LoadingButton>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    </FormikProvider>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} justifyContent="flex-end" >
                                <Stack spacing={1} direction="row" justifyContent="flex-end">
                                    <TextField disabled={rows.length === 0} sx={{ backgroundColor: "white" }} placeholder='Search' size="small" onChange={handelChange} value={search} />
                                    <IconButton
                                        onClick={handleClickOpenMenu}
                                        size="small"
                                        aria-haspopup="true"
                                    >
                                        <FilterAltOutlinedIcon />
                                    </IconButton>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Box
                            sx={{ paddingTop: "10px" }}
                        >
                            {
                                rows.length > 0 && (
                                    <Stack spacing={1}>
                                        <Stack spacing={1} direction="row" justifyContent={"flex-end"}>

                                            <Button as={CSVLink} filename={`${fDateTo(values.date, 'dd_MM_yyyy_HH:mm')}.csv`} data={
                                                rows.map((value, index) => {
                                                    const {
                                                        triptime_start, refcode, vehicle_id, type, odometer_start, soc_start, weight_total, weight_car, weight_net
                                                    } = value;
                                                    return {
                                                        "วันที่": `=""${fDateTo(new Date(moment(triptime_start, "DD-MM-YYYY HH:mm:ss")), 'dd/MM/yyyy HH:mm:ss')}""`,
                                                        "เลขอ้างอิง": `=""${refcode}""`,
                                                        "ทะเบียนรถ": `=""${vehicle_id}""`,
                                                        "ประเภท": `=""${type}""`,
                                                        "Odometer (Km)": `=""${odometer_start / 1000}""`,
                                                        "SOC": `=""${soc_start}""`,
                                                        "น้ำหนักรวม (kg)": `=""${weight_total}""`,
                                                        "น้ำหนักรถ (kg)": `=""${weight_car}""`,
                                                        "น้ำหนักสุทธิ (kg)": `=""${weight_net}""`
                                                    }
                                                }
                                                )
                                            } variant="contained" style={{
                                                textDecorationLine: 'none',
                                            }} >
                                                Download
                                            </Button>
                                        </Stack>
                                        <OrdersTable search={search} rows={rows} filterType={filterType} />
                                    </Stack>
                                )
                            }
                            {
                                rows.length == 0 && (
                                    <Box sx={{ textAlign: "center", padding: 10, fontWeight: "bold" }}>
                                        กรุณาค้นหาข้อมูล
                                    </Box>
                                )
                            }
                        </Box>
                    </Grid>
                </Container>
            </MainStyle>
            <StyledMenu
                id="group-menu"
                MenuListProps={{
                    'aria-labelledby': 'group-button'
                }}
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClickCloseMenu}
            >
                <nav aria-label="main">
                    {
                        defaultFilterType.map((_, indexType) =>
                            <ListItem
                                disablePadding
                                key={"defaultFilterType" + indexType}
                            >
                                <ListItemButton
                                    onClick={() => { onChangeType(_) }}
                                    selected={filterType.filter(v => v.type === _.type).length !== 0}
                                >
                                    <ListItemText primary={_.label} />
                                </ListItemButton>
                            </ListItem>
                        )
                    }
                </nav>
            </StyledMenu>
        </Page >
    )
}


const MainStyle = styled(Box)(({ theme }) => ({
    margin: 'auto',
    overflow: 'auto',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10
}));


const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        minWidth: 170,
        maxHeight: "calc(100vh - 100px)",
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));
import { Icon } from '@iconify/react';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import EditLocationOutlinedIcon from '@mui/icons-material/EditLocationOutlined';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import BadgeIcon from '@mui/icons-material/Badge';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import TerminalIcon from '@mui/icons-material/Terminal';
import RouterIcon from '@mui/icons-material/Router';
import RouteIcon from '@mui/icons-material/Route';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import TransgenderIcon from '@mui/icons-material/Transgender';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import BrandingWatermarkOutlinedIcon from '@mui/icons-material/BrandingWatermarkOutlined';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const SidebarSettingsConfig = [
    {
        title: 'preferences',
        path: '/settings/preferences',
        icon: <SettingsIcon />
    },
    {
        title: 'routes',
        path: '/settings/routes',
        icon: <RouteIcon />
    },
    {
        title: 'notifications',
        path: '/settings/notifications',
        icon: <NotificationsIcon />
    },
    {
        title: 'device',
        path: '/settings/devices',
        icon: <RouterIcon />
    },
    {
        title: 'groups device',
        path: '/settings/groups',
        icon: <FolderOpenIcon />
    },
    {
        title: 'companys',
        path: '/settings/companys',
        icon: <BusinessRoundedIcon />
    },
    {
        title: 'contypes',
        path: '/settings/contypes',
        icon: <PermContactCalendarIcon />
    },
    {
        title: 'group permission',
        path: '/settings/grouppermission',
        icon: <Diversity3Icon />
    },
    {
        title: 'concodes',
        path: '/settings/concodes',
        icon: <BrandingWatermarkOutlinedIcon />
    },

    {
        title: 'prefixs',
        path: '/settings/prefixs',
        icon: <FormatListBulletedIcon />
    },
    {
        title: 'sexs',
        path: '/settings/sexs',
        icon: <TransgenderIcon />
    },
    {
        title: 'administrator',
        path: '/settings/administrator',
        icon: <AdminPanelSettingsOutlinedIcon />
    },
    {
        title: 'accounts',
        path: '/settings/accounts',
        icon: <PersonIcon />
    },
    {
        title: 'customers',
        path: '/settings/customers',
        icon: <GroupsRoundedIcon />
    },
    {
        title: 'drivers',
        path: '/settings/drivers',
        icon: <BadgeIcon />
    },
    {
        title: 'Bus Hostess',
        path: '/settings/bushostess',
        icon: <AssignmentIndIcon />
    },
    // {
    //     title: 'address',
    //     path: '/settings/address',
    //     icon: <ContactMailIcon />
    // },
    {
        title: 'geofences',
        path: '/settings/geofences',
        icon: <EditLocationOutlinedIcon />
    },
    {
        title: 'POI',
        path: '/settings/poi',
        icon: <AddLocationAltIcon />
    },
    // {
    //     title: 'booking',
    //     path: '/settings/booking',
    //     icon: <BookOnlineIcon />
    // },
    {
        title: 'calendars',
        path: '/settings/calendars',
        icon: <EventOutlinedIcon />
    },
    {
        title: 'computed attributes',
        path: '/settings/attributes',
        icon: <ViewListOutlinedIcon />
    },
    {
        title: 'maintenances',
        path: '/settings/maintenances',
        icon: <BuildCircleIcon />
    },
    {
        title: 'saved commands',
        path: '/settings/commands',
        icon: <TerminalIcon />
    },
];

export default SidebarSettingsConfig;

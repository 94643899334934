import * as Yup from 'yup';
import React, { useState } from 'react';
// material
import {
    Button,
    Divider,
    TextField,
    Container,
    Collapse,
    ListItemButton,
    List,
    ListSubheader,
    ListItemText,
    Grid,
    Dialog, DialogTitle, DialogContent, DialogActions, IconButton
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';

// components
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Page from '../components/Page';
import { Api } from '../components/api';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
// ----------------------------------------------------------------------


// ----------------------------------------------------------------------



export default function GroupsEdit({ account, gorup_state }) {

    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const handleSnackbar = ({ variant, message }) => {
        enqueueSnackbar(message, { variant, autoHideDuration: 1500, anchorOrigin: { horizontal: 'right', vertical: 'bottom' } });
    };


    const URL = /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i

    const LoginSchema = Yup.object().shape({
        group_id: Yup.string().required('Id is required'),
        group_name: Yup.string().required('Name is required'),
        icon: Yup.string().matches(
            URL,
            'Enter correct url Icon!'
        )
        // .required('Icon is required'),
    });


    const formik = useFormik({
        initialValues: {
            ...gorup_state
        },
        validationSchema: LoginSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            const { c_coname } = account
            const data = {
                cname: c_coname,
                ...values,
            }
            Api.put('Tracking/Group', data)
                .then(res => {
                    handleSnackbar({ variant: 'success', message: 'success!' })
                    onClickClose()
                }
                ).catch(error => {
                    handleSnackbar({ variant: 'error', message: error.response.data.Message });
                }
                )
        }
    });
    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setErrors, resetForm, submitForm } = formik;

    const onClickOpen = () => {
        setOpen(true)
    }
    const onClickClose = () => {
        setOpen(false)
        resetForm()
    }
    return (
        <Box>
            <IconButton size="small" onClick={onClickOpen}>
                <EditNoteRoundedIcon fontSize="small" />
            </IconButton>
            <Dialog
                open={open}
                maxWidth="sm"
                fullWidth={true}
                onClose={onClickClose}
                slotProps={{
                    backdrop: {
                        style: {
                            background: "#a7d8de",
                            opacity: 0.2
                        }
                    }
                }}
            >
                <DialogTitle>แก้ไขข้อมูลอุปกรณ์</DialogTitle>
                <DialogContent dividers>
                    <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}  >
                            <Box margin="auto" padding={2} >
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="Id"
                                            {...getFieldProps('group_id')}
                                            error={Boolean(touched.group_id && errors.group_id)}
                                            helperText={touched.group_id && errors.group_id}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="Name"
                                            {...getFieldProps('group_name')}
                                            error={Boolean(touched.group_name && errors.group_name)}
                                            helperText={touched.group_name && errors.group_name}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            disabled
                                            size="small"
                                            label="Icon"
                                            {...getFieldProps('icon')}
                                            error={Boolean(touched.icon && errors.icon)}
                                            helperText={touched.icon && errors.icon}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Form>
                    </FormikProvider>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        size="medium"
                        type="submit"
                        variant="contained"
                        color="info"
                        loading={isSubmitting}
                        onClick={submitForm}
                    >
                        บันทึก
                    </LoadingButton>
                    <Button
                        onClick={onClickClose}
                        size="medium"
                        variant="outlined"
                    >
                        ยกเลิก
                    </Button>
                </DialogActions>
            </Dialog>
        </Box >
    );
}

import { Container, Backdrop, Fab, Stack, Card, Menu, MenuItem, alpha, TablePagination, List, ListItem, InputAdornment, ListItemButton, Button, TextField, ListItemIcon, IconButton, ListItemText, Box, Divider, Typography, TableContainer, Table, TableCell, TableHead, TableRow, TableBody, Paper } from '@mui/material'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import Page from '../../components/Page'
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import { Api } from '../../components/api';
import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import HomeRepairServiceOutlinedIcon from '@mui/icons-material/HomeRepairServiceOutlined';
import DialogQrcode from './DialogQrcode';
import CircularProgress from '@mui/material/CircularProgress';
import AddDevice from './AddDevice';
import EditDevice from './EditDevice';
import MobileScreenShareOutlinedIcon from '@mui/icons-material/MobileScreenShareOutlined';
import { TableVirtuoso } from 'react-virtuoso';
import Deviceshare from './Deviceshare';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DialogCerDlt from './DialogCerDlt';
import DeviceCerDlt from './DeviceCerDlt';

const FabStyle = styled(Fab)(({ theme }) => ({
    position: 'fixed',
    bottom: 16,
    right: 16,
    [theme.breakpoints.down('md')]: {
        bottom: 60,
    }
}));
const MainStyle = styled(Box)(({ theme }) => ({
    margin: 'auto',
    paddingBottom: 0,
    overflow: 'auto'
}));
export default function MainDevice({ account }) {
    const [listDevices, setDevice] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()

    const [anchorEl, setAnchorEl] = useState(null);
    const [group, setGroup] = useState(null);
    const [groups, setGroups] = useState([]);
    const [search, setSearch] = useState("");


    const getDevices = useCallback(() => {

        const { c_coname, contype } = account;
        const pCanme = contype === "00" ? `` : `&cname=${c_coname}`
        Api.get(`/v0/gps/devices?PageSize=9999999&Page=1${pCanme}`)
            .then(res => {
                var listDevices = res.data.devices;
                setDevice(listDevices);
                setLoading(false)
            }
            ).catch(e => {
                console.log('error', e)
            })
        Api.get(`Tracking/Group?cname=${c_coname}`)
            .then(res => {
                var list = res.data;
                setGroups(list)
            }
            ).catch(e => {
                console.log('error', e)
            })
    }, [account]);


    useEffect(() => {
        getDevices()
    }, [getDevices])


    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleSnackbar = ({ message, variant }) => {
        enqueueSnackbar(message,
            {
                autoHideDuration: 1000,
                anchorOrigin: {
                    horizontal: "right",
                    vertical: 'bottom'
                },
                variant: variant
            });
    }

    const handleSnackWithAction = ({ message, onHandle }) => {
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => (
                <Fragment>
                    <Stack direction="row" spacing={1}>
                        <Button
                            size='small'
                            color="error"
                            variant="contained"
                            onClick={() => {
                                closeSnackbar(key)
                                onHandle()
                            }}
                        >
                            <CheckRoundedIcon fontSize="small" />
                        </Button>
                        <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                            <CloseRoundedIcon fontSize="small" />
                        </Button>
                    </Stack>
                </Fragment>
            )
        });
    };
    const handleDel = (item) => {
        var data = {
            ...item
        }
        Api.delete('/v0/gps/device', { data: data })
            .then(res => {
                handleSnackbar({ message: "ลบข้อมูลสำเร็จ", variant: "success" })
                getDevices();
            })
            .catch(error => {
                const { message } = error
                handleSnackbar({ variant: 'error', message: "ลบข้อมูลไม่สำเร็จ" });
            })
    };


    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleChangeGroup = (value) => {
        setGroup(value)
    }
    const handleChange = (event) => {
        setSearch(event.target.value)
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const filterList = group !== null ?
        listDevices.filter((val) => {
            return (val.devicename?.toUpperCase().includes(search.toUpperCase()) || val.vehicle_id?.toUpperCase().includes(search.toUpperCase()) || val.vehicle_chassis_no?.toUpperCase().includes(search.toUpperCase())) && val.group_id?.toUpperCase().includes(group.toUpperCase())
        })
        :
        listDevices.filter((val) => {
            return (val.devicename?.toUpperCase().includes(search.toUpperCase()) || val.vehicle_id?.toUpperCase().includes(search.toUpperCase()) || val.vehicle_chassis_no?.toUpperCase().includes(search.toUpperCase()))
        })



    const options = [
        {
            icon: <LocalPrintshopIcon />,
            title: "DLT Cer",
            to: (row) => {
                return `/settings/devices/print/${row.vehicle_chassis_no}`
            },
            target: "_blank",
            component: Link
        }
    ];





    const handleGetData = (event) => {
        getDevices()
    }
    return (
        <Page>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Container maxWidth={false} style={{ padding: 0 }}>
                <Box flexGrow={1} padding={1}>
                    <Typography variant="h4">Device</Typography>
                </Box>
                <Divider />
                <MainStyle>
                    <Box sx={{
                        margin: "10px"
                    }}>
                        <Stack direction="row" spacing={1}>
                            <TextField
                                fullWidth
                                size="small"
                                label={`ค้นหา ชื่ออุปกรณ์/ป้ายทะเบียน/เลขตัวถัง ${group == null ? "" : "ใน " + groups.find(value => value.group_id === group).group_name}`}
                                value={search}
                                onChange={handleChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end' >
                                            <IconButton
                                                // ref={refDropDownTune}
                                                edge="end"
                                                onClick={handleClick}
                                                size="small"
                                                sx={{ ml: 2 }}
                                                aria-controls={open ? 'account-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={open ? 'true' : undefined}
                                            >
                                                <TuneRoundedIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <IconButton
                                // ref={refDropDownTune}
                                edge="end"
                                onClick={handleGetData}
                            >
                                <RefreshRoundedIcon />
                            </IconButton>
                            <AddDevice account={account} handleGetData={handleGetData} />
                        </Stack>
                    </Box>
                    <Paper style={{ height: "calc(100vh - 125px)", padding: "10px", margin: "10px" }}>
                        <TableVirtuoso
                            data={filterList}
                            components={VirtuosoTableComponents}
                            fixedHeaderContent={() => (
                                <TableRow sx={{ backgroundColor: "white" }}>
                                    <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", width: "120px", textAlign: "left" }}>ชื่ออุปกรณ์</TableCell>
                                    <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", width: "120px", textAlign: "left" }}>ชื่อรถ</TableCell>
                                    <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", width: "120px", textAlign: "left" }}>ป้ายทะเบียน</TableCell>
                                    <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", width: "170px", textAlign: "left" }}>เลขตัวถัง</TableCell>
                                    <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", width: "130px", textAlign: "left" }}>imei</TableCell>
                                    <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", width: "140px", textAlign: "left" }}></TableCell>
                                </TableRow>
                            )}
                            itemContent={(index, value) => (
                                <React.Fragment key={`replay-${index}`} >
                                    <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                        {value.devicename}
                                    </TableCell>
                                    <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                        {value.car_id}
                                    </TableCell>
                                    <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                        {value.vehicle_id}
                                    </TableCell>
                                    <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                        {value.vehicle_chassis_no}
                                    </TableCell>
                                    <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                        {value.imei}
                                    </TableCell>
                                    <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                        <Box>
                                            <Stack direction="row" spacing={1}>
                                                <Deviceshare device={value} account={account} />
                                                <EditDevice data={value} handleGetData={handleGetData} account={account} />
                                                <DialogQrcode data={value} />
                                                {/* <MenuMoreTable row={value} options={options} /> */}
                                                {/* <DialogCerDlt id={value.vehicle_chassis_no} /> */}
                                                <DeviceCerDlt chassis={value.vehicle_chassis_no} cocode={account.cocode} />
                                                <IconButton size="small" onClick={() => {
                                                    handleSnackWithAction({ message: "ต้องการที่จะลบ?", onHandle: () => { handleDel(value) } })
                                                }}>
                                                    <DeleteIcon fontSize="small" />
                                                </IconButton>
                                            </Stack>
                                        </Box>
                                    </TableCell>
                                </React.Fragment>
                            )}
                        />
                    </Paper>
                </MainStyle>
                <StyledMenu
                    id="group-menu"
                    MenuListProps={{
                        'aria-labelledby': 'group-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <List>
                        <ListItem disablePadding key={"groupsall"}>
                            <ListItemButton onClick={() => {
                                setGroup(null)
                                handleClose()
                            }}>
                                <ListItemText primary={"ทั้งหมด"} />
                            </ListItemButton>
                        </ListItem>
                        {
                            groups.map((value, index) => {

                                return (
                                    <ListItem disablePadding key={"groups" + index}>
                                        <ListItemButton onClick={() => {
                                            setGroup(value.group_id)
                                            handleClose()
                                        }}>
                                            <ListItemText primary={value.group_name} />
                                        </ListItemButton>
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                </StyledMenu>
            </Container >
        </Page >
    )

}


const MenuMoreTable = ({ row, options }) => {

    const [anchorElMore, setAnchorElMore] = React.useState(null);
    const openMore = Boolean(anchorElMore);
    const handleClickMore = (event) => {
        setAnchorElMore(event.currentTarget);
    };
    const handleCloseMore = () => {
        setAnchorElMore(null);
    };

    return (
        <>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={openMore ? 'long-menu' : undefined}
                aria-expanded={openMore ? 'true' : undefined}
                aria-haspopup="true"
                size="small"
                onClick={handleClickMore}
            >
                <MoreVertIcon fontSize="small" />
            </IconButton>
            <StyledMenu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorElMore}
                open={openMore}
                onClose={handleCloseMore}
                PaperProps={{
                    style: {
                        maxHeight: 48 * 4.5,
                        width: '20ch',
                    },
                }}
            >
                {options.map((option) => (
                    <MenuItem
                        key={option}
                        onClick={() => {
                            // option.onClick(row)
                            handleCloseMore()
                        }}
                        component={option.component}
                        to={option.to(row)}
                        target={option.target}
                    >
                        {option.icon} {option.title}
                    </MenuItem>
                ))}
            </StyledMenu>
        </>
    )
}



const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 200,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '8px',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

const VirtuosoTableComponents = {
    Scroller: React.forwardRef((props, ref) => (
        <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: (props) => (
        <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
    ),
    TableHead,
    TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
    TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};


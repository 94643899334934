import React, { useEffect, useRef, useState } from 'react'
import { Circle, MapContainer, Polygon, Polyline, Rectangle, TileLayer } from "react-leaflet";
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import ListTileLayer from "../../layouts/ListTileLayer";
import { ControlPoiLayerMap } from '../poi/ButtonLayerControl';
import DrawControl from './DrawControl';
import EditControlPOI from './EditControlPOI';
import CreatePOI from './CreatePOI';
import PoigeoItem from './PoigeoItem';
import EditPOI from './EditPOI';

export default function ContainerMap({
    map,
    setMap,
    refMapStyle,
    selectedMapStyle,
    setSelectedMapStyle,
    poigeolistshow,
    onGetPoigeoByCname,
    setpoigeolistshow,
    onChangeSelect
}) {

    const [editRef, setEditRef] = useState(null);
    const [poiSelect, setPoiSelect] = useState(null);
    const refControlPOI = useRef(null);
    const [handleEditData, setHandleEditData] = useState(null);
    const [modeFun, setModeFun] = useState('');

    const handleCreated = (poi) => {
        if (refControlPOI.current?.getLayers().length !== 0) {
            refControlPOI.current.clearLayers()
        }
        const created = {
            "cname": "FIRST",
            "poi_id": '',
            "poi_name": '',
            "group_id": '',
            "type": "Feature",
            "properties": {
                "radius": Math.floor(poi.properties?.radius || 0),
                "shape": poi.properties.shape || ''
            },
            "geometry": {
                "type": poi.geometry.type,
                "coordinates": poi.geometry.coordinates
            }
        }
        setPoiSelect(created)
        setHandleEditData(created)
        setModeFun('create')
        console.log('created', created)
    }
    const editResize = (radius) => {
        setHandleEditData(prevState => {
            return {
                ...prevState,
                properties: {
                    ...prevState?.properties,
                    radius: radius,

                }
            }
        })

    }
    const editMove = (latLng) => {
        setHandleEditData(prevState => {
            return {
                ...prevState,
                geometry: {
                    ...prevState?.geometry,
                    coordinates: latLng,

                }
            }
        })

    }

    const onClickLayer = (value) => {
        if (refMapStyle.current) {
            refMapStyle.current.setUrl(value.url);
            setSelectedMapStyle(value.id)
            localStorage.setItem('selectedMapStyle', value.id)
        }
    }


    useEffect(() => {
        if (poiSelect !== null && editRef !== null) {
            editRef._toolbars.edit._modes.edit.handler.enable()
        }
    }, [poiSelect])


    const onEdited = (select) => {
        setPoiSelect(select)
        setHandleEditData(select)
        setModeFun('edit')
    }
    return (

        <Box
            sx={{
                position: "relative"
            }}
        >
            <MapContainerStyle
                preferCanvas={true}
                whenCreated={(ref) => {
                    setMap(ref)
                }}
                center={center}
                zoom={zoom}
                minZoom={3}
                zoomControl={false}
                scrollWheelZoom={true}
                doubleClickZoom={false}
                attributionControl={false}
            >
                <TileLayer
                    ref={refMapStyle}
                    url={ListTileLayer.find(value => value.id === selectedMapStyle).url}
                    maxNativeZoom={19}
                    maxZoom={22}
                />
                <ControlPoiLayerMap onClick={onClickLayer} selected={selectedMapStyle} />

                <DrawControl editRef={editRef} setPoiSelect={setPoiSelect} setHandleEditData={setHandleEditData} />
                <EditControlPOI
                    poiSelect={poiSelect}
                    setEditRef={setEditRef}
                    handleCreated={handleCreated}
                    refControlPOI={refControlPOI}
                    editResize={editResize}
                    editMove={editMove}
                />
                <PoigeoItem poigeolistshow={poiSelect ? [] : poigeolistshow} map={map} onEdited={onEdited} />

            </MapContainerStyle>
            {
                modeFun === "create" && handleEditData !== null && (
                    <CreatePOI
                        handleEditData={handleEditData}
                        setHandleEditData={setHandleEditData}
                        setPoiSelect={setPoiSelect}
                        onGetPoigeoByCname={onGetPoigeoByCname}
                        onChangeSelect={onChangeSelect}
                    // handleCloseBackdrop={handleCloseBackdrop}
                    // onGetPoiGroup={onGetPoiGroup}
                    // listPoiShow={listPoiShow}
                    />
                )
            }
            {
                modeFun === "edit" && handleEditData !== null && (
                    <EditPOI
                        handleEditData={handleEditData}
                        setHandleEditData={setHandleEditData}
                        setPoiSelect={setPoiSelect}
                        onGetPoigeoByCname={onGetPoigeoByCname}
                    // handleCloseBackdrop={handleCloseBackdrop}
                    // onGetPoiGroup={onGetPoiGroup}
                    // listPoiShow={listPoiShow}
                    />
                )
            }
        </Box>
    )
}



const MapContainerStyle = styled(MapContainer)(({ theme }) => ({
    overflow: 'hidden',
    height: '100vh',
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
        height: "calc(100vh - 124px)",
        // height: '50vh'
    },
}));
const center = [13.839660198254604, 100.63469639884744];
const zoom = 13;


import React, { useState } from 'react'
import { Stack, IconButton, Typography, Dialog, DialogContent, DialogActions, DialogTitle, Box, Grid, Button, Backdrop, FormControlLabel, Checkbox, Table, TableHead, TableRow, TableBody, TableCell } from '@mui/material'
import axios from 'axios'
import { Api } from '../../components/api'
import EditDeviceCerDlt from './EditDeviceCerDlt'
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import SubtitlesOutlinedIcon from '@mui/icons-material/SubtitlesOutlined';
import DialogCerDlt from './DialogCerDlt'
import CloseIcon from '@mui/icons-material/Close';
import AddDeviceCerDlt from './AddDeviceCerDlt'
import { useSnackbar } from 'notistack'

export default function DeviceCerDlt({ cocode, chassis }) {


    const [open, setOpen] = useState(false)
    const [dltCers, setDltCers] = useState([])


    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleSnackbar = ({ message, variant }) => {
        enqueueSnackbar(message,
            {
                autoHideDuration: 1000,
                anchorOrigin: {
                    horizontal: "right",
                    vertical: 'bottom'
                },
                variant: variant
            });
    }

    const getCer = () => {
        Api.get(`/v0/gps/certificatesByChassis?cocode=${cocode}&chassis=${chassis}`)
            .then(res => {
                setDltCers(res.data)
                setOpen(true)
            })
            .catch(err => {
                console.log('err', err)
                setOpen(true)
            })
    }

    const onClickOpen = () => {
        if (chassis) {
            getCer()
        } else {
            handleSnackbar({ message: "กรุณาเพิ่มหมายเลขคัสซี", variant: "error" })
        }
    }
    const onClickClose = () => {
        setOpen(false)
    }
    return (
        <div>
            <IconButton size="small" onClick={onClickOpen}>
                <SubtitlesOutlinedIcon fontSize="small" />
            </IconButton>
            <Dialog
                // fullScreen
                fullWidth={true}
                maxWidth="md"
                open={open}
                onClose={onClickClose}
                slotProps={{
                    backdrop: {
                        style: {
                            background: "#a7d8de",
                            opacity: 0.2
                        }
                    }
                }}
            >
                <DialogTitle>
                    <Stack spacing={1} direction="row">
                        <Typography variant="h6">ข้อมูลใบรับรอง</Typography>
                    </Stack>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClickClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>docno</TableCell>
                                <TableCell>customer_name</TableCell>
                                <TableCell>vehicle_id</TableCell>
                                <TableCell>gpslicense</TableCell>
                                <TableCell>unit_id</TableCell>
                                <TableCell sx={{ justifyContent: "end", display: "flex" }}>
                                    <AddDeviceCerDlt />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                dltCers.map((cer, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>{cer.docno}</TableCell>
                                            <TableCell style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "205px" }}>{cer.customer_name}</TableCell>
                                            <TableCell>{cer.vehicle_id}</TableCell>
                                            <TableCell>{cer.gpslicense}</TableCell>
                                            <TableCell>{cer.unit_id}</TableCell>
                                            <TableCell>
                                                <Stack spacing={1} direction="row">
                                                    <EditDeviceCerDlt cer={cer} />
                                                    <DialogCerDlt cer={cer} />
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </DialogContent>
            </Dialog>

        </div>
    )
}

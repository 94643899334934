import React from 'react'
import { Circle, MapContainer, Polygon, Polyline, Rectangle, TileLayer, Tooltip } from "react-leaflet";

function PoigeoItem({ poigeolistshow, map, onEdited }) {
    return (
        <>
            {
                poigeolistshow.map((geopoi, index) => {
                    return (
                        <React.Fragment key={`poigeolistshow${index}`}>
                            {geopoi.poi_data?.geometry.type === "Circle" && (
                                <Circle
                                    children={<Tooltip direction={"top"} >{geopoi.poi_name}</Tooltip>}
                                    center={[geopoi.poi_data.geometry.coordinates[0][1], geopoi.poi_data.geometry.coordinates[0][0]]}
                                    radius={geopoi.poi_data.properties.radius}
                                    eventHandlers={{
                                        click: () => {
                                            const select = {
                                                "cname": geopoi.cname,
                                                "poi_id": geopoi.poi_id,
                                                "poi_name": geopoi.poi_name,
                                                "group_id": geopoi.group_id,
                                                "poi_data": geopoi.poi_data,
                                                "type": "Feature",
                                                "properties": {
                                                    "radius": geopoi.poi_data.properties.radius,
                                                    "shape": "circle"
                                                },
                                                "geometry": {
                                                    "type": "Point",
                                                    "coordinates": geopoi.poi_data.geometry.coordinates[0]
                                                }
                                            }
                                            onEdited(select)
                                            map.setView([geopoi.poi_data.geometry.coordinates[0][1], geopoi.poi_data.geometry.coordinates[0][0]], map.getZoom(), {
                                                "animate": true,
                                                "duration": 1,
                                            })
                                        }
                                    }}
                                />
                            )}
                            {geopoi.poi_data?.geometry.type === "Polygon" && (
                                <Polygon
                                    children={<Tooltip direction={"top"} >{geopoi.poi_name}</Tooltip>}
                                    eventHandlers={{
                                        click: () => {
                                            const select = {
                                                "cname": geopoi.cname,
                                                "poi_id": geopoi.poi_id,
                                                "poi_name": geopoi.poi_name,
                                                "group_id": geopoi.group_id,
                                                "poi_data": geopoi.poi_data,
                                                "type": "Feature",
                                                "properties": {
                                                    ...geopoi.poi_data.properties,
                                                    "shape": "polygon"
                                                },
                                                "geometry": {
                                                    "type": "Polygon",
                                                    "coordinates": geopoi.poi_data.geometry.coordinates
                                                }
                                            }
                                            onEdited(select)

                                            map.fitBounds(geopoi.poi_data.geometry.coordinates[0].map((rectangleGeo) => {
                                                return [rectangleGeo[1], rectangleGeo[0]];
                                            }), {
                                                "animate": true,
                                                "duration": 1,
                                            })
                                        }
                                    }}
                                    positions={
                                        geopoi.poi_data.geometry.coordinates[0].map((rectangleGeo) => {
                                            return [rectangleGeo[1], rectangleGeo[0]];
                                        })
                                    }
                                />
                            )}
                            {geopoi.poi_data?.geometry.type === "Polyline" && (
                                <Polyline
                                    children={<Tooltip direction={"top"} >{geopoi.poi_name}</Tooltip>}
                                    eventHandlers={{
                                        click: () => {
                                            const select = {
                                                "cname": geopoi.cname,
                                                "poi_id": geopoi.poi_id,
                                                "poi_name": geopoi.poi_name,
                                                "group_id": geopoi.group_id,
                                                "poi_data": geopoi.poi_data,
                                                "type": "Feature",
                                                "properties": {
                                                    ...geopoi.poi_data.properties,
                                                    "shape": "polyline"
                                                },
                                                "geometry": {
                                                    "type": "LineString",
                                                    "coordinates": geopoi.poi_data.geometry.coordinates
                                                }
                                            }
                                            onEdited(select)
                                            map.fitBounds(geopoi.poi_data.geometry.coordinates.map((polylineGeo) => {
                                                return [polylineGeo[1], polylineGeo[0]];
                                            }), {
                                                "animate": true,
                                                "duration": 1,
                                            })
                                        }
                                    }}
                                    positions={geopoi.poi_data.geometry.coordinates.map((polylineGeo) => {
                                        return [polylineGeo[1], polylineGeo[0]];
                                    })}
                                />
                            )}
                            {geopoi.poi_data?.geometry.type === "Rectangle" && (
                                <Rectangle
                                    children={<Tooltip direction={"top"} >{geopoi.poi_name}</Tooltip>}
                                    eventHandlers={{
                                        click: () => {
                                            const select = {
                                                "cname": geopoi.cname,
                                                "poi_id": geopoi.poi_id,
                                                "poi_name": geopoi.poi_name,
                                                "group_id": geopoi.group_id,
                                                "poi_data": geopoi.poi_data,
                                                "type": "Feature",
                                                "properties": {
                                                    ...geopoi.poi_data.properties,
                                                    "shape": "rectangle"
                                                },
                                                "geometry": {
                                                    "type": "Polygon",
                                                    "coordinates": geopoi.poi_data.geometry.coordinates
                                                }
                                            }
                                            onEdited(select)
                                            map.fitBounds(geopoi.poi_data.geometry.coordinates[0].map((rectangleGeo) => {
                                                return [rectangleGeo[1], rectangleGeo[0]];
                                            }), {
                                                "animate": true,
                                                "duration": 1,
                                            })
                                        }
                                    }}
                                    bounds={
                                        geopoi.poi_data.geometry.coordinates[0].map((rectangleGeo) => {
                                            return [rectangleGeo[1], rectangleGeo[0]];
                                        })
                                    }
                                />
                            )}
                        </React.Fragment>
                    )
                })
            }
        </>
    )
}


// export default React.memo(PoigeoItem);
export default PoigeoItem;

import { Container, Backdrop, Fab, List, ListItem, ListItemButton, Button, ListItemIcon, IconButton, ListItemText, Box, Divider, Typography, TableCell, TableRow, TableBody, TableContainer, TableHead, Table, Paper, TextField, Stack, Menu, MenuItem } from '@mui/material'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
// import DeleteIcon from '@mui/icons-material/Delete';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { FilterAlt } from '@mui/icons-material';
import Page from '../../../components/Page';
import { Api } from '../../../components/api';
import ButtonDialogCreateUser from './ButtonDialogCreateUser';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ButtonDialogUpdateUser from './ButtonDialogUpdateUser';
import CircularProgress from '@mui/material/CircularProgress';
import HasPermission from '../../../haspermission/HasPermission';
import UsersGroupPermission from './UsersGroupPermission';
import _ from 'lodash';

const FabStyle = styled(Fab)(({ theme }) => ({
    position: 'fixed',
    bottom: 16,
    right: 16,
    [theme.breakpoints.down('md')]: {
        bottom: 60
    }
}));
const MainStyle = styled(Box)(({ theme }) => ({
    margin: 'auto',
    paddingBottom: 60,
    overflow: 'auto'
}));
export default function User({ account, permissions, userId }) {
    const [listAccounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()

    const [search, setSearch] = useState('');

    const handleChange = ({ target: { value, name } }) => {
        setSearch(value)
    }




    const getAccounts = () => {
        const { cocode, c_coname } = account;
        Api.get(`v0/if/ContactByContype?contype=${"21"}&cname=${c_coname}`)
            .then(res => {
                var listAccounts = res.data;
                setAccounts(listAccounts);
                setLoading(false)
            }
            ).catch(e => {
                console.log('error', e)
            })
    }

    useEffect(() => {
        const { cocode, c_coname } = account;
        Api.get(`v0/if/ContactByContype?contype=${"21"}&cname=${c_coname}`)
            .then(res => {
                var listAccounts = res.data;
                setAccounts(listAccounts);
                setLoading(false)
            }
            ).catch(e => {
                console.log('error', e)
                setLoading(false)
            })
    }, [account])

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleClickVariant = (message, variant) => {
        enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });

    };

    const handleSnackWithAction = ({ message, onHandle }) => {
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => (
                <Fragment>
                    <Button
                        size='small'
                        color="error"
                        onClick={() => {
                            closeSnackbar(key)
                            onHandle()
                        }}
                    >
                        Yes
                    </Button>
                    <Button size='small' color="success" onClick={() => closeSnackbar(key)}>
                        Dismiss
                    </Button>
                </Fragment>
            )
        });
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const [openCreate, setOpenCreate] = useState(false)


    const onHandleDel = (d_data) => {
        const { cocode, cogcode } = account;

        const data = JSON.stringify({ ...d_data })
        Api.delete(`/v0/if/Contact`, { data: data, headers: { CompanyGroup: cogcode } })
            .then((res) => {
                console.log('res', res)
                // handleClickVariant("ลบข้อมูลเสร็จสิ้น !", "success")
                const { id } = res.data
                onHandleDelUser(id)
                getAccounts()
            })
            .catch((err) => {
                console.log('err', err)
                handleClickVariant("ลบข้อมูลไม่เสร็จสิ้น !", "error")
            })
    }


    const onHandleDelUser = (id) => {
        Api.get(`/v0/app/GetUserID?id=${id}`).then((res) => {
            const { cocode, cogcode } = account;
            const data = JSON.stringify({ ...res.data })
            Api.delete(`/v0/app/UserID`, { data: data, headers: { CompanyGroup: cogcode } })
                .then((res) => {
                    console.log('res', res)
                    handleClickVariant("ลบข้อมูลเสร็จสิ้น !", "success")
                })
                .catch((err) => {
                    console.log('err', err)
                    handleClickVariant("ลบข้อมูลไม่เสร็จสิ้น !", "error")
                })
        })
    }

    return (
        <Page>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Container maxWidth={false} style={{ padding: 0 }}>
                <Box flexGrow={1} padding={1}>
                    <Stack spacing={1} direction="row">
                        <TextField sx={{ backgroundColor: "white" }} placeholder='Search' size="small" onChange={handleChange} value={search} />
                        <HasPermission permissions={permissions} scope={["create"]}>
                            <ButtonDialogCreateUser dataAccount={account} onHandleGet={getAccounts} />
                        </HasPermission>
                    </Stack>
                </Box>
                <Divider />
                <MainStyle>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width={80} align="left">ชื่อ</TableCell>
                                    <TableCell width={80} align="left">นามสกุล</TableCell>
                                    <TableCell width={100} align="left">อีเมล</TableCell>
                                    <TableCell width={80} align="center"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.filter(listAccounts, (v) => v.firstname.toUpperCase().includes(search)).map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" align="left" scope="row">
                                            {row.firstname}
                                        </TableCell>
                                        <TableCell align="left">{row.lastname}</TableCell>
                                        <TableCell align="left">{row.email}</TableCell>
                                        <TableCell sx={{ justifyContent: "end", display: "flex" }}>
                                            <Stack spacing={1} direction="row">
                                                <HasPermission permissions={permissions} scope={["update"]}>
                                                    <UsersGroupPermission id={row.id} account={account} />
                                                    <ButtonDialogUpdateUser dataAccount={account} data={row} onHandleGet={getAccounts} dataUserId={userId} />
                                                </HasPermission>
                                                <HasPermission permissions={permissions} scope={["delete"]}>
                                                    <IconButton size="small" onClick={() => { handleSnackWithAction({ message: "ต้องการลบข้อมูลประเภท ข้อมูลผู้ใช้งาน?", onHandle: () => onHandleDel(row) }) }}><DeleteOutlineIcon fontSize="small" /></IconButton>
                                                </HasPermission>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </MainStyle>
            </Container>
        </Page >
    )
}

import RouterHasPermission from '../../haspermission/RouterHasPermission';
import HasPermission from '../../haspermission/HasPermission';

export default function MainDashboard() {

    // const hasWritePermission = useHasPermission('read.articles');
    const account = { userid: "demo" }
    // const f = false
    // const t = true

    return (
        <div>
            <RouterHasPermission
                userid={account.userid}
                permission={"p1"}
                scope={["read"]}
                RenderError={() => { return (<>ไม่มีสิทธิ์ ใช้งานกรุณาติดต่อเจ้าหน้าที่</>) }}
                RenderHasPermission={
                    ({ permissions }) => {
                        return (
                            <>
                                <HasPermission permissions={permissions} scope={["create"]}>
                                    <button>create</button>
                                </HasPermission>
                                <HasPermission permissions={permissions} scope={["update"]}>
                                    <button>update</button>
                                </HasPermission>
                                <HasPermission permissions={permissions} scope={["delete"]}>
                                    <button>delete</button>
                                </HasPermission>
                            </>
                        )
                    }
                }
            />
        </div>
    )
}
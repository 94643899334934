

export const geojson = {
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "properties": {
                "id": "ST00706",
                "name": "บริษัทเน็กซ์พอยท์",
                "time": "12:53:39",
                "time_in": "2024-07-11T00:00:10Z",
                "time_out": "2024-07-11T12:53:49Z",
                "stroke": "#ffffff",
                "fill": "#001eff"
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            100.73911165175731,
                            13.63297483
                        ],
                        [
                            100.73908675809356,
                            13.633220458518373
                        ],
                        [
                            100.73901303375189,
                            13.633456647670535
                        ],
                        [
                            100.73889331191737,
                            13.633674320839823
                        ],
                        [
                            100.73873219343297,
                            13.633865112968419
                        ],
                        [
                            100.7385358699917,
                            13.634021692021813
                        ],
                        [
                            100.7383118861933,
                            13.634138040754795
                        ],
                        [
                            100.73806884960952,
                            13.634209687950808
                        ],
                        [
                            100.7378161,
                            13.634233880248287
                        ],
                        [
                            100.73756335039049,
                            13.634209687950808
                        ],
                        [
                            100.7373203138067,
                            13.634138040754795
                        ],
                        [
                            100.73709633000831,
                            13.634021692021813
                        ],
                        [
                            100.73690000656704,
                            13.633865112968419
                        ],
                        [
                            100.73673888808264,
                            13.633674320839823
                        ],
                        [
                            100.73661916624812,
                            13.633456647670535
                        ],
                        [
                            100.73654544190644,
                            13.633220458518373
                        ],
                        [
                            100.7365205482427,
                            13.63297483
                        ],
                        [
                            100.73654544190644,
                            13.632729201481627
                        ],
                        [
                            100.73661916624812,
                            13.632493012329466
                        ],
                        [
                            100.73673888808264,
                            13.632275339160177
                        ],
                        [
                            100.73690000656704,
                            13.632084547031582
                        ],
                        [
                            100.73709633000831,
                            13.631927967978188
                        ],
                        [
                            100.7373203138067,
                            13.631811619245205
                        ],
                        [
                            100.73756335039049,
                            13.631739972049193
                        ],
                        [
                            100.7378161,
                            13.631715779751714
                        ],
                        [
                            100.73806884960952,
                            13.631739972049193
                        ],
                        [
                            100.7383118861933,
                            13.631811619245205
                        ],
                        [
                            100.7385358699917,
                            13.631927967978188
                        ],
                        [
                            100.73873219343297,
                            13.632084547031582
                        ],
                        [
                            100.73889331191737,
                            13.632275339160177
                        ],
                        [
                            100.73901303375189,
                            13.632493012329466
                        ],
                        [
                            100.73908675809356,
                            13.632729201481627
                        ],
                        [
                            100.73911165175731,
                            13.63297483
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "ST01379",
                "name": "รพ.จุฬารัตน์+9",
                "time": "00:00:06",
                "time_in": "2024-07-11T14:03:23Z",
                "time_out": "2024-07-11T14:03:29Z",
                "stroke": "#ffffff",
                "fill": "#001eff"
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            100.72265645442374,
                            13.67729396
                        ],
                        [
                            100.72264222679962,
                            13.677434319153356
                        ],
                        [
                            100.72260009068685,
                            13.677569284383162
                        ],
                        [
                            100.72253166535263,
                            13.677693669051328
                        ],
                        [
                            100.72243958034419,
                            13.67780269312481
                        ],
                        [
                            100.72232737443674,
                            13.677892166869606
                        ],
                        [
                            100.72219935964038,
                            13.677958651859884
                        ],
                        [
                            100.72206045549196,
                            13.677999593114746
                        ],
                        [
                            100.721916,
                            13.678013417284735
                        ],
                        [
                            100.72177154450803,
                            13.677999593114746
                        ],
                        [
                            100.7216326403596,
                            13.677958651859884
                        ],
                        [
                            100.72150462556324,
                            13.677892166869606
                        ],
                        [
                            100.7213924196558,
                            13.67780269312481
                        ],
                        [
                            100.72130033464735,
                            13.677693669051328
                        ],
                        [
                            100.72123190931313,
                            13.677569284383162
                        ],
                        [
                            100.72118977320036,
                            13.677434319153356
                        ],
                        [
                            100.72117554557624,
                            13.67729396
                        ],
                        [
                            100.72118977320036,
                            13.677153600846644
                        ],
                        [
                            100.72123190931313,
                            13.677018635616838
                        ],
                        [
                            100.72130033464735,
                            13.676894250948672
                        ],
                        [
                            100.7213924196558,
                            13.67678522687519
                        ],
                        [
                            100.72150462556324,
                            13.676695753130394
                        ],
                        [
                            100.7216326403596,
                            13.676629268140116
                        ],
                        [
                            100.72177154450803,
                            13.676588326885254
                        ],
                        [
                            100.721916,
                            13.676574502715265
                        ],
                        [
                            100.72206045549196,
                            13.676588326885254
                        ],
                        [
                            100.72219935964038,
                            13.676629268140116
                        ],
                        [
                            100.72232737443674,
                            13.676695753130394
                        ],
                        [
                            100.72243958034419,
                            13.67678522687519
                        ],
                        [
                            100.72253166535263,
                            13.676894250948672
                        ],
                        [
                            100.72260009068685,
                            13.677018635616838
                        ],
                        [
                            100.72264222679962,
                            13.677153600846644
                        ],
                        [
                            100.72265645442374,
                            13.67729396
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "ST01",
                "name": "เฟิสท์ทรานสปอร์ตหน้าออฟฟิศ+โรงซ่อม",
                "time": "00:15:40",
                "time_in": "2024-07-11T15:00:11Z",
                "time_out": "2024-07-11T15:15:51Z",
                "stroke": "#ffffff",
                "fill": "#001eff"
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            100.80338074781008,
                            13.716263
                        ],
                        [
                            100.80336847445002,
                            13.71638405976977
                        ],
                        [
                            100.80333212602817,
                            13.716500467280477
                        ],
                        [
                            100.803273099394,
                            13.71660774905677
                        ],
                        [
                            100.80319366290797,
                            13.716701782320149
                        ],
                        [
                            100.80309686926968,
                            13.716778953425036
                        ],
                        [
                            100.80298643820437,
                            13.716836296729149
                        ],
                        [
                            100.80286661351595,
                            13.716871608561469
                        ],
                        [
                            100.802742,
                            13.716883531908083
                        ],
                        [
                            100.80261738648404,
                            13.716871608561469
                        ],
                        [
                            100.80249756179562,
                            13.716836296729149
                        ],
                        [
                            100.80238713073031,
                            13.716778953425036
                        ],
                        [
                            100.80229033709202,
                            13.716701782320149
                        ],
                        [
                            100.80221090060598,
                            13.71660774905677
                        ],
                        [
                            100.80215187397182,
                            13.716500467280477
                        ],
                        [
                            100.80211552554997,
                            13.71638405976977
                        ],
                        [
                            100.80210325218991,
                            13.716263
                        ],
                        [
                            100.80211552554997,
                            13.71614194023023
                        ],
                        [
                            100.80215187397182,
                            13.716025532719522
                        ],
                        [
                            100.80221090060598,
                            13.71591825094323
                        ],
                        [
                            100.80229033709202,
                            13.71582421767985
                        ],
                        [
                            100.80238713073031,
                            13.715747046574963
                        ],
                        [
                            100.80249756179562,
                            13.71568970327085
                        ],
                        [
                            100.80261738648404,
                            13.71565439143853
                        ],
                        [
                            100.802742,
                            13.715642468091916
                        ],
                        [
                            100.80286661351595,
                            13.71565439143853
                        ],
                        [
                            100.80298643820437,
                            13.71568970327085
                        ],
                        [
                            100.80309686926968,
                            13.715747046574963
                        ],
                        [
                            100.80319366290797,
                            13.71582421767985
                        ],
                        [
                            100.803273099394,
                            13.71591825094323
                        ],
                        [
                            100.80333212602817,
                            13.716025532719522
                        ],
                        [
                            100.80336847445002,
                            13.71614194023023
                        ],
                        [
                            100.80338074781008,
                            13.716263
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "ST00706",
                "name": "บริษัทเน็กซ์พอยท์",
                "time": "08:08:44",
                "time_in": "2024-07-11T15:50:00Z",
                "time_out": "2024-07-11T23:58:44Z",
                "stroke": "#ffffff",
                "fill": "#001eff"
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            100.73911165175731,
                            13.63297483
                        ],
                        [
                            100.73908675809356,
                            13.633220458518373
                        ],
                        [
                            100.73901303375189,
                            13.633456647670535
                        ],
                        [
                            100.73889331191737,
                            13.633674320839823
                        ],
                        [
                            100.73873219343297,
                            13.633865112968419
                        ],
                        [
                            100.7385358699917,
                            13.634021692021813
                        ],
                        [
                            100.7383118861933,
                            13.634138040754795
                        ],
                        [
                            100.73806884960952,
                            13.634209687950808
                        ],
                        [
                            100.7378161,
                            13.634233880248287
                        ],
                        [
                            100.73756335039049,
                            13.634209687950808
                        ],
                        [
                            100.7373203138067,
                            13.634138040754795
                        ],
                        [
                            100.73709633000831,
                            13.634021692021813
                        ],
                        [
                            100.73690000656704,
                            13.633865112968419
                        ],
                        [
                            100.73673888808264,
                            13.633674320839823
                        ],
                        [
                            100.73661916624812,
                            13.633456647670535
                        ],
                        [
                            100.73654544190644,
                            13.633220458518373
                        ],
                        [
                            100.7365205482427,
                            13.63297483
                        ],
                        [
                            100.73654544190644,
                            13.632729201481627
                        ],
                        [
                            100.73661916624812,
                            13.632493012329466
                        ],
                        [
                            100.73673888808264,
                            13.632275339160177
                        ],
                        [
                            100.73690000656704,
                            13.632084547031582
                        ],
                        [
                            100.73709633000831,
                            13.631927967978188
                        ],
                        [
                            100.7373203138067,
                            13.631811619245205
                        ],
                        [
                            100.73756335039049,
                            13.631739972049193
                        ],
                        [
                            100.7378161,
                            13.631715779751714
                        ],
                        [
                            100.73806884960952,
                            13.631739972049193
                        ],
                        [
                            100.7383118861933,
                            13.631811619245205
                        ],
                        [
                            100.7385358699917,
                            13.631927967978188
                        ],
                        [
                            100.73873219343297,
                            13.632084547031582
                        ],
                        [
                            100.73889331191737,
                            13.632275339160177
                        ],
                        [
                            100.73901303375189,
                            13.632493012329466
                        ],
                        [
                            100.73908675809356,
                            13.632729201481627
                        ],
                        [
                            100.73911165175731,
                            13.63297483
                        ]
                    ]
                ],
                "type": "Polygon"
            },
            "id": 3
        },
        {
            "type": "Feature",
            "properties": {
                "id": ""
            },
            "geometry": {
                "coordinates": [
                    [
                        100.737866,
                        13.633191
                    ],
                    [
                        100.737866,
                        13.633191
                    ],
                    [
                        100.737838,
                        13.633136
                    ],
                    [
                        100.737855,
                        13.633195
                    ],
                    [
                        100.73788,
                        13.633201
                    ],
                    [
                        100.737865,
                        13.633193
                    ],
                    [
                        100.737881,
                        13.633193
                    ],
                    [
                        100.737895,
                        13.633185
                    ],
                    [
                        100.737911,
                        13.633168
                    ],
                    [
                        100.737985,
                        13.633116
                    ],
                    [
                        100.738033,
                        13.633098
                    ],
                    [
                        100.738111,
                        13.633055
                    ],
                    [
                        100.738173,
                        13.633015
                    ],
                    [
                        100.73822,
                        13.632975
                    ],
                    [
                        100.73836,
                        13.632881
                    ],
                    [
                        100.738603,
                        13.632696
                    ],
                    [
                        100.738668,
                        13.632638
                    ],
                    [
                        100.738723,
                        13.632576
                    ],
                    [
                        100.738886,
                        13.632451
                    ],
                    [
                        100.73892,
                        13.632441
                    ],
                    [
                        100.738963,
                        13.632435
                    ],
                    [
                        100.738983,
                        13.632438
                    ],
                    [
                        100.738998,
                        13.632448
                    ],
                    [
                        100.739028,
                        13.632488
                    ],
                    [
                        100.739041,
                        13.63252
                    ],
                    [
                        100.739196,
                        13.632843
                    ],
                    [
                        100.739406,
                        13.633288
                    ],
                    [
                        100.739675,
                        13.633881
                    ],
                    [
                        100.739898,
                        13.634375
                    ],
                    [
                        100.740143,
                        13.63492
                    ],
                    [
                        100.740345,
                        13.635376
                    ],
                    [
                        100.740555,
                        13.635848
                    ],
                    [
                        100.740806,
                        13.636423
                    ],
                    [
                        100.741015,
                        13.63689
                    ],
                    [
                        100.741268,
                        13.637476
                    ],
                    [
                        100.741491,
                        13.637995
                    ],
                    [
                        100.741778,
                        13.638651
                    ],
                    [
                        100.742028,
                        13.639215
                    ],
                    [
                        100.742281,
                        13.639766
                    ],
                    [
                        100.74258,
                        13.640415
                    ],
                    [
                        100.74283,
                        13.640991
                    ],
                    [
                        100.743088,
                        13.641571
                    ],
                    [
                        100.743393,
                        13.642266
                    ],
                    [
                        100.743658,
                        13.642858
                    ],
                    [
                        100.743958,
                        13.643533
                    ],
                    [
                        100.744201,
                        13.644071
                    ],
                    [
                        100.744438,
                        13.6446
                    ],
                    [
                        100.744723,
                        13.645203
                    ],
                    [
                        100.744993,
                        13.64568
                    ],
                    [
                        100.745276,
                        13.646315
                    ],
                    [
                        100.745443,
                        13.646775
                    ],
                    [
                        100.745576,
                        13.64712
                    ],
                    [
                        100.745663,
                        13.647218
                    ],
                    [
                        100.74574,
                        13.647243
                    ],
                    [
                        100.745868,
                        13.647225
                    ],
                    [
                        100.745985,
                        13.647161
                    ],
                    [
                        100.746066,
                        13.647115
                    ],
                    [
                        100.746163,
                        13.646976
                    ],
                    [
                        100.746148,
                        13.646873
                    ],
                    [
                        100.746048,
                        13.646643
                    ],
                    [
                        100.745883,
                        13.646231
                    ],
                    [
                        100.745601,
                        13.645581
                    ],
                    [
                        100.745331,
                        13.644965
                    ],
                    [
                        100.745098,
                        13.644426
                    ],
                    [
                        100.744833,
                        13.643841
                    ],
                    [
                        100.744591,
                        13.643333
                    ],
                    [
                        100.744321,
                        13.642821
                    ],
                    [
                        100.743966,
                        13.642175
                    ],
                    [
                        100.74366,
                        13.641531
                    ],
                    [
                        100.743253,
                        13.640683
                    ],
                    [
                        100.742886,
                        13.639878
                    ],
                    [
                        100.742495,
                        13.638981
                    ],
                    [
                        100.742076,
                        13.63804
                    ],
                    [
                        100.741566,
                        13.636861
                    ],
                    [
                        100.741041,
                        13.635633
                    ],
                    [
                        100.74058,
                        13.634605
                    ],
                    [
                        100.740045,
                        13.633365
                    ],
                    [
                        100.739618,
                        13.632325
                    ],
                    [
                        100.739171,
                        13.63105
                    ],
                    [
                        100.738771,
                        13.630036
                    ],
                    [
                        100.738346,
                        13.629031
                    ],
                    [
                        100.73788,
                        13.627855
                    ],
                    [
                        100.737476,
                        13.626918
                    ],
                    [
                        100.737163,
                        13.626176
                    ],
                    [
                        100.736621,
                        13.625
                    ],
                    [
                        100.73625,
                        13.624166
                    ],
                    [
                        100.735848,
                        13.623198
                    ],
                    [
                        100.735813,
                        13.622598
                    ],
                    [
                        100.735911,
                        13.622326
                    ],
                    [
                        100.73609,
                        13.622091
                    ],
                    [
                        100.736603,
                        13.621758
                    ],
                    [
                        100.736736,
                        13.621693
                    ],
                    [
                        100.737226,
                        13.621446
                    ],
                    [
                        100.737848,
                        13.621138
                    ],
                    [
                        100.738563,
                        13.620801
                    ],
                    [
                        100.739213,
                        13.62051
                    ],
                    [
                        100.739795,
                        13.620246
                    ],
                    [
                        100.740396,
                        13.61997
                    ],
                    [
                        100.74116,
                        13.619628
                    ],
                    [
                        100.741765,
                        13.619355
                    ],
                    [
                        100.74242,
                        13.619038
                    ],
                    [
                        100.742975,
                        13.618765
                    ],
                    [
                        100.743505,
                        13.618538
                    ],
                    [
                        100.744168,
                        13.618246
                    ],
                    [
                        100.74471,
                        13.618006
                    ],
                    [
                        100.74528,
                        13.617753
                    ],
                    [
                        100.746015,
                        13.617426
                    ],
                    [
                        100.74671,
                        13.617121
                    ],
                    [
                        100.747628,
                        13.616723
                    ],
                    [
                        100.748431,
                        13.616378
                    ],
                    [
                        100.74925,
                        13.616018
                    ],
                    [
                        100.750166,
                        13.61561
                    ],
                    [
                        100.750893,
                        13.615281
                    ],
                    [
                        100.751755,
                        13.614876
                    ],
                    [
                        100.752446,
                        13.614583
                    ],
                    [
                        100.753141,
                        13.614276
                    ],
                    [
                        100.754015,
                        13.613885
                    ],
                    [
                        100.75472,
                        13.613571
                    ],
                    [
                        100.755441,
                        13.613253
                    ],
                    [
                        100.756066,
                        13.612976
                    ],
                    [
                        100.756755,
                        13.61266
                    ],
                    [
                        100.757621,
                        13.61227
                    ],
                    [
                        100.75833,
                        13.611958
                    ],
                    [
                        100.759153,
                        13.611603
                    ],
                    [
                        100.759735,
                        13.61136
                    ],
                    [
                        100.760266,
                        13.611123
                    ],
                    [
                        100.760603,
                        13.610998
                    ],
                    [
                        100.760861,
                        13.610916
                    ],
                    [
                        100.761038,
                        13.610863
                    ],
                    [
                        100.761191,
                        13.610815
                    ],
                    [
                        100.761356,
                        13.610773
                    ],
                    [
                        100.761573,
                        13.610711
                    ],
                    [
                        100.76188,
                        13.610623
                    ],
                    [
                        100.762026,
                        13.610573
                    ],
                    [
                        100.762138,
                        13.610531
                    ],
                    [
                        100.762295,
                        13.610476
                    ],
                    [
                        100.762446,
                        13.610423
                    ],
                    [
                        100.762595,
                        13.610368
                    ],
                    [
                        100.76273,
                        13.61032
                    ],
                    [
                        100.7629,
                        13.610258
                    ],
                    [
                        100.763041,
                        13.610205
                    ],
                    [
                        100.763273,
                        13.610116
                    ],
                    [
                        100.763583,
                        13.610005
                    ],
                    [
                        100.763953,
                        13.609871
                    ],
                    [
                        100.764518,
                        13.609678
                    ],
                    [
                        100.765053,
                        13.609483
                    ],
                    [
                        100.765675,
                        13.609245
                    ],
                    [
                        100.766558,
                        13.608931
                    ],
                    [
                        100.767326,
                        13.608661
                    ],
                    [
                        100.768175,
                        13.608355
                    ],
                    [
                        100.768885,
                        13.608098
                    ],
                    [
                        100.769691,
                        13.607788
                    ],
                    [
                        100.770288,
                        13.607566
                    ],
                    [
                        100.77073,
                        13.607403
                    ],
                    [
                        100.771155,
                        13.607243
                    ],
                    [
                        100.771385,
                        13.607161
                    ],
                    [
                        100.77159,
                        13.607088
                    ],
                    [
                        100.771936,
                        13.606963
                    ],
                    [
                        100.772333,
                        13.606813
                    ],
                    [
                        100.7728,
                        13.606641
                    ],
                    [
                        100.773433,
                        13.606403
                    ],
                    [
                        100.77404,
                        13.606176
                    ],
                    [
                        100.774691,
                        13.605936
                    ],
                    [
                        100.775053,
                        13.605801
                    ],
                    [
                        100.775271,
                        13.605718
                    ],
                    [
                        100.77543,
                        13.605661
                    ],
                    [
                        100.775533,
                        13.605621
                    ],
                    [
                        100.775701,
                        13.605556
                    ],
                    [
                        100.775941,
                        13.605466
                    ],
                    [
                        100.776128,
                        13.605398
                    ],
                    [
                        100.776281,
                        13.605346
                    ],
                    [
                        100.776343,
                        13.605328
                    ],
                    [
                        100.77646,
                        13.605288
                    ],
                    [
                        100.77654,
                        13.605258
                    ],
                    [
                        100.776575,
                        13.605248
                    ],
                    [
                        100.776593,
                        13.60526
                    ],
                    [
                        100.776611,
                        13.60526
                    ],
                    [
                        100.776621,
                        13.605263
                    ],
                    [
                        100.776631,
                        13.605265
                    ],
                    [
                        100.776638,
                        13.605263
                    ],
                    [
                        100.776643,
                        13.60526
                    ],
                    [
                        100.776673,
                        13.605251
                    ],
                    [
                        100.776733,
                        13.60523
                    ],
                    [
                        100.776768,
                        13.605223
                    ],
                    [
                        100.77685,
                        13.605203
                    ],
                    [
                        100.77701,
                        13.605141
                    ],
                    [
                        100.777268,
                        13.605045
                    ],
                    [
                        100.777533,
                        13.60495
                    ],
                    [
                        100.777793,
                        13.604856
                    ],
                    [
                        100.778095,
                        13.604745
                    ],
                    [
                        100.778298,
                        13.604675
                    ],
                    [
                        100.77847,
                        13.60461
                    ],
                    [
                        100.778718,
                        13.60452
                    ],
                    [
                        100.779118,
                        13.604376
                    ],
                    [
                        100.779471,
                        13.60425
                    ],
                    [
                        100.779681,
                        13.604178
                    ],
                    [
                        100.779803,
                        13.604135
                    ],
                    [
                        100.779931,
                        13.604086
                    ],
                    [
                        100.780141,
                        13.604005
                    ],
                    [
                        100.78033,
                        13.603936
                    ],
                    [
                        100.780498,
                        13.603878
                    ],
                    [
                        100.780665,
                        13.60382
                    ],
                    [
                        100.780773,
                        13.603778
                    ],
                    [
                        100.780806,
                        13.603766
                    ],
                    [
                        100.780893,
                        13.603735
                    ],
                    [
                        100.78101,
                        13.603696
                    ],
                    [
                        100.781066,
                        13.603678
                    ],
                    [
                        100.781121,
                        13.603663
                    ],
                    [
                        100.78134,
                        13.603575
                    ],
                    [
                        100.781491,
                        13.603515
                    ],
                    [
                        100.78156,
                        13.60349
                    ],
                    [
                        100.781705,
                        13.603435
                    ],
                    [
                        100.781821,
                        13.603381
                    ],
                    [
                        100.781916,
                        13.60333
                    ],
                    [
                        100.782023,
                        13.603286
                    ],
                    [
                        100.782125,
                        13.603246
                    ],
                    [
                        100.782193,
                        13.603221
                    ],
                    [
                        100.782261,
                        13.603196
                    ],
                    [
                        100.782338,
                        13.60317
                    ],
                    [
                        100.782405,
                        13.603146
                    ],
                    [
                        100.782513,
                        13.603106
                    ],
                    [
                        100.782641,
                        13.603056
                    ],
                    [
                        100.782851,
                        13.602983
                    ],
                    [
                        100.783143,
                        13.602875
                    ],
                    [
                        100.783415,
                        13.602775
                    ],
                    [
                        100.783751,
                        13.602653
                    ],
                    [
                        100.784141,
                        13.602516
                    ],
                    [
                        100.78448,
                        13.602391
                    ],
                    [
                        100.784838,
                        13.602263
                    ],
                    [
                        100.785143,
                        13.602153
                    ],
                    [
                        100.785511,
                        13.602036
                    ],
                    [
                        100.78576,
                        13.601915
                    ],
                    [
                        100.785801,
                        13.601818
                    ],
                    [
                        100.785785,
                        13.60171
                    ],
                    [
                        100.785758,
                        13.601671
                    ],
                    [
                        100.785713,
                        13.601633
                    ],
                    [
                        100.785531,
                        13.601601
                    ],
                    [
                        100.78526,
                        13.601691
                    ],
                    [
                        100.784886,
                        13.601835
                    ],
                    [
                        100.78447,
                        13.601978
                    ],
                    [
                        100.783911,
                        13.602173
                    ],
                    [
                        100.783385,
                        13.602356
                    ],
                    [
                        100.782821,
                        13.60254
                    ],
                    [
                        100.78235,
                        13.602695
                    ],
                    [
                        100.781725,
                        13.602915
                    ],
                    [
                        100.78118,
                        13.603108
                    ],
                    [
                        100.780761,
                        13.603253
                    ],
                    [
                        100.780316,
                        13.6034
                    ],
                    [
                        100.779908,
                        13.60353
                    ],
                    [
                        100.77934,
                        13.603736
                    ],
                    [
                        100.778815,
                        13.603923
                    ],
                    [
                        100.77828,
                        13.604138
                    ],
                    [
                        100.777456,
                        13.604438
                    ],
                    [
                        100.77672,
                        13.604708
                    ],
                    [
                        100.775765,
                        13.605045
                    ],
                    [
                        100.774986,
                        13.605321
                    ],
                    [
                        100.774276,
                        13.605573
                    ],
                    [
                        100.773576,
                        13.605795
                    ],
                    [
                        100.773066,
                        13.60597
                    ],
                    [
                        100.772458,
                        13.606166
                    ],
                    [
                        100.772223,
                        13.60624
                    ],
                    [
                        100.772221,
                        13.606235
                    ],
                    [
                        100.772218,
                        13.606233
                    ],
                    [
                        100.772218,
                        13.606231
                    ],
                    [
                        100.772215,
                        13.606231
                    ],
                    [
                        100.772211,
                        13.606218
                    ],
                    [
                        100.772211,
                        13.60621
                    ],
                    [
                        100.772208,
                        13.606215
                    ],
                    [
                        100.772196,
                        13.606216
                    ],
                    [
                        100.772191,
                        13.606225
                    ],
                    [
                        100.772185,
                        13.606225
                    ],
                    [
                        100.772168,
                        13.606225
                    ],
                    [
                        100.77217,
                        13.606236
                    ],
                    [
                        100.77217,
                        13.606235
                    ],
                    [
                        100.77217,
                        13.606236
                    ],
                    [
                        100.772015,
                        13.606315
                    ],
                    [
                        100.771668,
                        13.606465
                    ],
                    [
                        100.77118,
                        13.60665
                    ],
                    [
                        100.770453,
                        13.606916
                    ],
                    [
                        100.769798,
                        13.60717
                    ],
                    [
                        100.769045,
                        13.607455
                    ],
                    [
                        100.76838,
                        13.607696
                    ],
                    [
                        100.767545,
                        13.608013
                    ],
                    [
                        100.766856,
                        13.608263
                    ],
                    [
                        100.766121,
                        13.608513
                    ],
                    [
                        100.765248,
                        13.608813
                    ],
                    [
                        100.764596,
                        13.609003
                    ],
                    [
                        100.763983,
                        13.609215
                    ],
                    [
                        100.763295,
                        13.609473
                    ],
                    [
                        100.762888,
                        13.609635
                    ],
                    [
                        100.762501,
                        13.609778
                    ],
                    [
                        100.7623,
                        13.60985
                    ],
                    [
                        100.762093,
                        13.609925
                    ],
                    [
                        100.761791,
                        13.610038
                    ],
                    [
                        100.761535,
                        13.61013
                    ],
                    [
                        100.761278,
                        13.610223
                    ],
                    [
                        100.760973,
                        13.610336
                    ],
                    [
                        100.76075,
                        13.610418
                    ],
                    [
                        100.760436,
                        13.610538
                    ],
                    [
                        100.76012,
                        13.610665
                    ],
                    [
                        100.75977,
                        13.610806
                    ],
                    [
                        100.759226,
                        13.611036
                    ],
                    [
                        100.758706,
                        13.61125
                    ],
                    [
                        100.758056,
                        13.611518
                    ],
                    [
                        100.757498,
                        13.611758
                    ],
                    [
                        100.756856,
                        13.612031
                    ],
                    [
                        100.756363,
                        13.612245
                    ],
                    [
                        100.75593,
                        13.612431
                    ],
                    [
                        100.7554,
                        13.61266
                    ],
                    [
                        100.755025,
                        13.61282
                    ],
                    [
                        100.754626,
                        13.613001
                    ],
                    [
                        100.754173,
                        13.613208
                    ],
                    [
                        100.753763,
                        13.61339
                    ],
                    [
                        100.753121,
                        13.613695
                    ],
                    [
                        100.752601,
                        13.613926
                    ],
                    [
                        100.75209,
                        13.614166
                    ],
                    [
                        100.751423,
                        13.614475
                    ],
                    [
                        100.750936,
                        13.614688
                    ],
                    [
                        100.750265,
                        13.614981
                    ],
                    [
                        100.749688,
                        13.615231
                    ],
                    [
                        100.749036,
                        13.61552
                    ],
                    [
                        100.748553,
                        13.615725
                    ],
                    [
                        100.748096,
                        13.615913
                    ],
                    [
                        100.747513,
                        13.616168
                    ],
                    [
                        100.747016,
                        13.616385
                    ],
                    [
                        100.746546,
                        13.61658
                    ],
                    [
                        100.746248,
                        13.616676
                    ],
                    [
                        100.746196,
                        13.61663
                    ],
                    [
                        100.746153,
                        13.616615
                    ],
                    [
                        100.746135,
                        13.616625
                    ],
                    [
                        100.746123,
                        13.616643
                    ],
                    [
                        100.74612,
                        13.61666
                    ],
                    [
                        100.746116,
                        13.616663
                    ],
                    [
                        100.746128,
                        13.616621
                    ],
                    [
                        100.746123,
                        13.616613
                    ],
                    [
                        100.74612,
                        13.616618
                    ],
                    [
                        100.746116,
                        13.616621
                    ],
                    [
                        100.7461,
                        13.616635
                    ],
                    [
                        100.746093,
                        13.616646
                    ],
                    [
                        100.746085,
                        13.616643
                    ],
                    [
                        100.74609,
                        13.616655
                    ],
                    [
                        100.746095,
                        13.616661
                    ],
                    [
                        100.746098,
                        13.61666
                    ],
                    [
                        100.746096,
                        13.616661
                    ],
                    [
                        100.746096,
                        13.616663
                    ],
                    [
                        100.746096,
                        13.616665
                    ],
                    [
                        100.74609,
                        13.616691
                    ],
                    [
                        100.746068,
                        13.616795
                    ],
                    [
                        100.746021,
                        13.616853
                    ],
                    [
                        100.745951,
                        13.616881
                    ],
                    [
                        100.74568,
                        13.617018
                    ],
                    [
                        100.7453,
                        13.617191
                    ],
                    [
                        100.744761,
                        13.617456
                    ],
                    [
                        100.74433,
                        13.617655
                    ],
                    [
                        100.7438,
                        13.617885
                    ],
                    [
                        100.743295,
                        13.618103
                    ],
                    [
                        100.742855,
                        13.618325
                    ],
                    [
                        100.742193,
                        13.618591
                    ],
                    [
                        100.741705,
                        13.618793
                    ],
                    [
                        100.74109,
                        13.619043
                    ],
                    [
                        100.740573,
                        13.619266
                    ],
                    [
                        100.739866,
                        13.619558
                    ],
                    [
                        100.739205,
                        13.619805
                    ],
                    [
                        100.738491,
                        13.620095
                    ],
                    [
                        100.73753,
                        13.620481
                    ],
                    [
                        100.736698,
                        13.620796
                    ],
                    [
                        100.73576,
                        13.621166
                    ],
                    [
                        100.735226,
                        13.621628
                    ],
                    [
                        100.735085,
                        13.622148
                    ],
                    [
                        100.735123,
                        13.622436
                    ],
                    [
                        100.735218,
                        13.62273
                    ],
                    [
                        100.73547,
                        13.623351
                    ],
                    [
                        100.73582,
                        13.624181
                    ],
                    [
                        100.736158,
                        13.624943
                    ],
                    [
                        100.736488,
                        13.625808
                    ],
                    [
                        100.736738,
                        13.626733
                    ],
                    [
                        100.736883,
                        13.627495
                    ],
                    [
                        100.737085,
                        13.628088
                    ],
                    [
                        100.737336,
                        13.628781
                    ],
                    [
                        100.73747,
                        13.629155
                    ],
                    [
                        100.737558,
                        13.629475
                    ],
                    [
                        100.737773,
                        13.629891
                    ],
                    [
                        100.738108,
                        13.63049
                    ],
                    [
                        100.738393,
                        13.631035
                    ],
                    [
                        100.738635,
                        13.63156
                    ],
                    [
                        100.738835,
                        13.63204
                    ],
                    [
                        100.738933,
                        13.632361
                    ],
                    [
                        100.738911,
                        13.6324
                    ],
                    [
                        100.738816,
                        13.63246
                    ],
                    [
                        100.738701,
                        13.632566
                    ],
                    [
                        100.738596,
                        13.632686
                    ],
                    [
                        100.738493,
                        13.632766
                    ],
                    [
                        100.738188,
                        13.63296
                    ],
                    [
                        100.738001,
                        13.63308
                    ],
                    [
                        100.737905,
                        13.633145
                    ],
                    [
                        100.737805,
                        13.633195
                    ],
                    [
                        100.737765,
                        13.633215
                    ],
                    [
                        100.737763,
                        13.633215
                    ],
                    [
                        100.737783,
                        13.6332
                    ],
                    [
                        100.737801,
                        13.633173
                    ],
                    [
                        100.737806,
                        13.633141
                    ],
                    [
                        100.7378,
                        13.633085
                    ],
                    [
                        100.7378,
                        13.63309
                    ],
                    [
                        100.737801,
                        13.633095
                    ],
                    [
                        100.737805,
                        13.6331
                    ],
                    [
                        100.737805,
                        13.633101
                    ],
                    [
                        100.737805,
                        13.633103
                    ],
                    [
                        100.737805,
                        13.633105
                    ],
                    [
                        100.737805,
                        13.633108
                    ],
                    [
                        100.737805,
                        13.633115
                    ],
                    [
                        100.737805,
                        13.633118
                    ],
                    [
                        100.737805,
                        13.633121
                    ],
                    [
                        100.737806,
                        13.63314
                    ],
                    [
                        100.73782,
                        13.633145
                    ],
                    [
                        100.737835,
                        13.633143
                    ],
                    [
                        100.737846,
                        13.63315
                    ],
                    [
                        100.737856,
                        13.633156
                    ],
                    [
                        100.73784,
                        13.633158
                    ],
                    [
                        100.737826,
                        13.633151
                    ],
                    [
                        100.73779,
                        13.633083
                    ],
                    [
                        100.737786,
                        13.63308
                    ],
                    [
                        100.737788,
                        13.633083
                    ],
                    [
                        100.7378,
                        13.633093
                    ],
                    [
                        100.737808,
                        13.633095
                    ],
                    [
                        100.737806,
                        13.633093
                    ],
                    [
                        100.737803,
                        13.633081
                    ],
                    [
                        100.737808,
                        13.633085
                    ],
                    [
                        100.737823,
                        13.63311
                    ],
                    [
                        100.737806,
                        13.63311
                    ],
                    [
                        100.737806,
                        13.633113
                    ],
                    [
                        100.737815,
                        13.633113
                    ],
                    [
                        100.737828,
                        13.633126
                    ],
                    [
                        100.738003,
                        13.633088
                    ],
                    [
                        100.738071,
                        13.633065
                    ],
                    [
                        100.738211,
                        13.63299
                    ],
                    [
                        100.738345,
                        13.632915
                    ],
                    [
                        100.738421,
                        13.632883
                    ],
                    [
                        100.73844,
                        13.63286
                    ],
                    [
                        100.738488,
                        13.63281
                    ],
                    [
                        100.738613,
                        13.6327
                    ],
                    [
                        100.738708,
                        13.632601
                    ],
                    [
                        100.738721,
                        13.632581
                    ],
                    [
                        100.738758,
                        13.632543
                    ],
                    [
                        100.73884,
                        13.632478
                    ],
                    [
                        100.738883,
                        13.632451
                    ],
                    [
                        100.73895,
                        13.632431
                    ],
                    [
                        100.738991,
                        13.632451
                    ],
                    [
                        100.73901,
                        13.632478
                    ],
                    [
                        100.739161,
                        13.632798
                    ],
                    [
                        100.739366,
                        13.633215
                    ],
                    [
                        100.739593,
                        13.633691
                    ],
                    [
                        100.739861,
                        13.634293
                    ],
                    [
                        100.740075,
                        13.634778
                    ],
                    [
                        100.740378,
                        13.63546
                    ],
                    [
                        100.740656,
                        13.636085
                    ],
                    [
                        100.741001,
                        13.63687
                    ],
                    [
                        100.741285,
                        13.637523
                    ],
                    [
                        100.741625,
                        13.638296
                    ],
                    [
                        100.741918,
                        13.63896
                    ],
                    [
                        100.742205,
                        13.639603
                    ],
                    [
                        100.742531,
                        13.640321
                    ],
                    [
                        100.742845,
                        13.641035
                    ],
                    [
                        100.74307,
                        13.641531
                    ],
                    [
                        100.743401,
                        13.642271
                    ],
                    [
                        100.74367,
                        13.642878
                    ],
                    [
                        100.743965,
                        13.643546
                    ],
                    [
                        100.7442,
                        13.644083
                    ],
                    [
                        100.744405,
                        13.644548
                    ],
                    [
                        100.744651,
                        13.64507
                    ],
                    [
                        100.744938,
                        13.645585
                    ],
                    [
                        100.745256,
                        13.646273
                    ],
                    [
                        100.74545,
                        13.646785
                    ],
                    [
                        100.745648,
                        13.64719
                    ],
                    [
                        100.745758,
                        13.64723
                    ],
                    [
                        100.745875,
                        13.647213
                    ],
                    [
                        100.745926,
                        13.647188
                    ],
                    [
                        100.74614,
                        13.646988
                    ],
                    [
                        100.746148,
                        13.646933
                    ],
                    [
                        100.746118,
                        13.646813
                    ],
                    [
                        100.745995,
                        13.64654
                    ],
                    [
                        100.745783,
                        13.646031
                    ],
                    [
                        100.74559,
                        13.645586
                    ],
                    [
                        100.745338,
                        13.645006
                    ],
                    [
                        100.745123,
                        13.644521
                    ],
                    [
                        100.744903,
                        13.644021
                    ],
                    [
                        100.744598,
                        13.643373
                    ],
                    [
                        100.744323,
                        13.642836
                    ],
                    [
                        100.743931,
                        13.642128
                    ],
                    [
                        100.743608,
                        13.641421
                    ],
                    [
                        100.74319,
                        13.640503
                    ],
                    [
                        100.742825,
                        13.639671
                    ],
                    [
                        100.74243,
                        13.63878
                    ],
                    [
                        100.741965,
                        13.637731
                    ],
                    [
                        100.741593,
                        13.636901
                    ],
                    [
                        100.741145,
                        13.63589
                    ],
                    [
                        100.740783,
                        13.635071
                    ],
                    [
                        100.740326,
                        13.634038
                    ],
                    [
                        100.739911,
                        13.633115
                    ],
                    [
                        100.73943,
                        13.6319
                    ],
                    [
                        100.739055,
                        13.630835
                    ],
                    [
                        100.738635,
                        13.62979
                    ],
                    [
                        100.738096,
                        13.628525
                    ],
                    [
                        100.737668,
                        13.627441
                    ],
                    [
                        100.737203,
                        13.62636
                    ],
                    [
                        100.736681,
                        13.625135
                    ],
                    [
                        100.736293,
                        13.62426
                    ],
                    [
                        100.735965,
                        13.623481
                    ],
                    [
                        100.7358,
                        13.622846
                    ],
                    [
                        100.735811,
                        13.62269
                    ],
                    [
                        100.735898,
                        13.622386
                    ],
                    [
                        100.736181,
                        13.621998
                    ],
                    [
                        100.736306,
                        13.6219
                    ],
                    [
                        100.737325,
                        13.62134
                    ],
                    [
                        100.738005,
                        13.621006
                    ],
                    [
                        100.738773,
                        13.620658
                    ],
                    [
                        100.739416,
                        13.620371
                    ],
                    [
                        100.740013,
                        13.62011
                    ],
                    [
                        100.740245,
                        13.620011
                    ],
                    [
                        100.740683,
                        13.619823
                    ],
                    [
                        100.741375,
                        13.619518
                    ],
                    [
                        100.741965,
                        13.619248
                    ],
                    [
                        100.742493,
                        13.619003
                    ],
                    [
                        100.743196,
                        13.618693
                    ],
                    [
                        100.74388,
                        13.618381
                    ],
                    [
                        100.744741,
                        13.617985
                    ],
                    [
                        100.745373,
                        13.617696
                    ],
                    [
                        100.745841,
                        13.617493
                    ],
                    [
                        100.746228,
                        13.6173
                    ],
                    [
                        100.746586,
                        13.617131
                    ],
                    [
                        100.746913,
                        13.616986
                    ],
                    [
                        100.747198,
                        13.616855
                    ],
                    [
                        100.747595,
                        13.616676
                    ],
                    [
                        100.748191,
                        13.616406
                    ],
                    [
                        100.748786,
                        13.616146
                    ],
                    [
                        100.749496,
                        13.615833
                    ],
                    [
                        100.75005,
                        13.61559
                    ],
                    [
                        100.750593,
                        13.615365
                    ],
                    [
                        100.750825,
                        13.6152
                    ],
                    [
                        100.750848,
                        13.615145
                    ],
                    [
                        100.750805,
                        13.614981
                    ],
                    [
                        100.750708,
                        13.614921
                    ],
                    [
                        100.750571,
                        13.614918
                    ],
                    [
                        100.75027,
                        13.615061
                    ],
                    [
                        100.749703,
                        13.615311
                    ],
                    [
                        100.74904,
                        13.615603
                    ],
                    [
                        100.748353,
                        13.615901
                    ],
                    [
                        100.74777,
                        13.616155
                    ],
                    [
                        100.74722,
                        13.616388
                    ],
                    [
                        100.746523,
                        13.61667
                    ],
                    [
                        100.745845,
                        13.616955
                    ],
                    [
                        100.744996,
                        13.617316
                    ],
                    [
                        100.744306,
                        13.617621
                    ],
                    [
                        100.743505,
                        13.61798
                    ],
                    [
                        100.742983,
                        13.618196
                    ],
                    [
                        100.742528,
                        13.618381
                    ],
                    [
                        100.742201,
                        13.6185
                    ],
                    [
                        100.742151,
                        13.618511
                    ],
                    [
                        100.742145,
                        13.618511
                    ],
                    [
                        100.742138,
                        13.618506
                    ],
                    [
                        100.742135,
                        13.618503
                    ],
                    [
                        100.742133,
                        13.618501
                    ],
                    [
                        100.742138,
                        13.618511
                    ],
                    [
                        100.742136,
                        13.618508
                    ],
                    [
                        100.742133,
                        13.618513
                    ],
                    [
                        100.742126,
                        13.618515
                    ],
                    [
                        100.742133,
                        13.618515
                    ],
                    [
                        100.742143,
                        13.618501
                    ],
                    [
                        100.742148,
                        13.618491
                    ],
                    [
                        100.742145,
                        13.618481
                    ],
                    [
                        100.742141,
                        13.618481
                    ],
                    [
                        100.741913,
                        13.618601
                    ],
                    [
                        100.7415,
                        13.6188
                    ],
                    [
                        100.741096,
                        13.618968
                    ],
                    [
                        100.740501,
                        13.619236
                    ],
                    [
                        100.739938,
                        13.61948
                    ],
                    [
                        100.739123,
                        13.619781
                    ],
                    [
                        100.73837,
                        13.620015
                    ],
                    [
                        100.737633,
                        13.620271
                    ],
                    [
                        100.736696,
                        13.620621
                    ],
                    [
                        100.735883,
                        13.620953
                    ],
                    [
                        100.734918,
                        13.621375
                    ],
                    [
                        100.734118,
                        13.621726
                    ],
                    [
                        100.733153,
                        13.622173
                    ],
                    [
                        100.73237,
                        13.622581
                    ],
                    [
                        100.731628,
                        13.622971
                    ],
                    [
                        100.730815,
                        13.623393
                    ],
                    [
                        100.730121,
                        13.623753
                    ],
                    [
                        100.729221,
                        13.624226
                    ],
                    [
                        100.728456,
                        13.62461
                    ],
                    [
                        100.727683,
                        13.624958
                    ],
                    [
                        100.726735,
                        13.625371
                    ],
                    [
                        100.725948,
                        13.625731
                    ],
                    [
                        100.724951,
                        13.626168
                    ],
                    [
                        100.724071,
                        13.626561
                    ],
                    [
                        100.723168,
                        13.626975
                    ],
                    [
                        100.72211,
                        13.62749
                    ],
                    [
                        100.721263,
                        13.627911
                    ],
                    [
                        100.720316,
                        13.628395
                    ],
                    [
                        100.71973,
                        13.628711
                    ],
                    [
                        100.719328,
                        13.628921
                    ],
                    [
                        100.71901,
                        13.629081
                    ],
                    [
                        100.71865,
                        13.629283
                    ],
                    [
                        100.718206,
                        13.629531
                    ],
                    [
                        100.717546,
                        13.629896
                    ],
                    [
                        100.716895,
                        13.630238
                    ],
                    [
                        100.716023,
                        13.630716
                    ],
                    [
                        100.715291,
                        13.631118
                    ],
                    [
                        100.714445,
                        13.63159
                    ],
                    [
                        100.713803,
                        13.631953
                    ],
                    [
                        100.713183,
                        13.632283
                    ],
                    [
                        100.712578,
                        13.63263
                    ],
                    [
                        100.71205,
                        13.632921
                    ],
                    [
                        100.71138,
                        13.633291
                    ],
                    [
                        100.710883,
                        13.633573
                    ],
                    [
                        100.710365,
                        13.63385
                    ],
                    [
                        100.709835,
                        13.634146
                    ],
                    [
                        100.709561,
                        13.63421
                    ],
                    [
                        100.7095,
                        13.634116
                    ],
                    [
                        100.709365,
                        13.633858
                    ],
                    [
                        100.70917,
                        13.633468
                    ],
                    [
                        100.708893,
                        13.63293
                    ],
                    [
                        100.708675,
                        13.632515
                    ],
                    [
                        100.708426,
                        13.632058
                    ],
                    [
                        100.708225,
                        13.631593
                    ],
                    [
                        100.708136,
                        13.63134
                    ],
                    [
                        100.707883,
                        13.63051
                    ],
                    [
                        100.70764,
                        13.629728
                    ],
                    [
                        100.70738,
                        13.628905
                    ],
                    [
                        100.707063,
                        13.627915
                    ],
                    [
                        100.706825,
                        13.627145
                    ],
                    [
                        100.706581,
                        13.626378
                    ],
                    [
                        100.706291,
                        13.625461
                    ],
                    [
                        100.706053,
                        13.624708
                    ],
                    [
                        100.705785,
                        13.623863
                    ],
                    [
                        100.70556,
                        13.623151
                    ],
                    [
                        100.705335,
                        13.62245
                    ],
                    [
                        100.705075,
                        13.621645
                    ],
                    [
                        100.70486,
                        13.620961
                    ],
                    [
                        100.704653,
                        13.620316
                    ],
                    [
                        100.704428,
                        13.619595
                    ],
                    [
                        100.70422,
                        13.618965
                    ],
                    [
                        100.704048,
                        13.61843
                    ],
                    [
                        100.70394,
                        13.618088
                    ],
                    [
                        100.70391,
                        13.617991
                    ],
                    [
                        100.703911,
                        13.61799
                    ],
                    [
                        100.703861,
                        13.617823
                    ],
                    [
                        100.703745,
                        13.617465
                    ],
                    [
                        100.703606,
                        13.61702
                    ],
                    [
                        100.703466,
                        13.616575
                    ],
                    [
                        100.70332,
                        13.616136
                    ],
                    [
                        100.703155,
                        13.615615
                    ],
                    [
                        100.703,
                        13.615121
                    ],
                    [
                        100.702798,
                        13.614496
                    ],
                    [
                        100.70263,
                        13.613966
                    ],
                    [
                        100.702436,
                        13.613371
                    ],
                    [
                        100.702208,
                        13.612665
                    ],
                    [
                        100.702006,
                        13.61205
                    ],
                    [
                        100.701751,
                        13.611243
                    ],
                    [
                        100.7015,
                        13.610533
                    ],
                    [
                        100.701231,
                        13.609776
                    ],
                    [
                        100.700943,
                        13.60888
                    ],
                    [
                        100.700708,
                        13.608208
                    ],
                    [
                        100.70047,
                        13.607396
                    ],
                    [
                        100.700285,
                        13.606781
                    ],
                    [
                        100.700148,
                        13.606315
                    ],
                    [
                        100.700026,
                        13.605783
                    ],
                    [
                        100.69999,
                        13.605318
                    ],
                    [
                        100.7,
                        13.605005
                    ],
                    [
                        100.70001,
                        13.604951
                    ],
                    [
                        100.700013,
                        13.60495
                    ],
                    [
                        100.700013,
                        13.604948
                    ],
                    [
                        100.700011,
                        13.604948
                    ],
                    [
                        100.70001,
                        13.60495
                    ],
                    [
                        100.700008,
                        13.604948
                    ],
                    [
                        100.700005,
                        13.60495
                    ],
                    [
                        100.700003,
                        13.604948
                    ],
                    [
                        100.700001,
                        13.60495
                    ],
                    [
                        100.7,
                        13.604951
                    ],
                    [
                        100.700006,
                        13.604948
                    ],
                    [
                        100.70001,
                        13.604946
                    ],
                    [
                        100.700006,
                        13.604946
                    ],
                    [
                        100.700006,
                        13.604943
                    ],
                    [
                        100.700005,
                        13.60494
                    ],
                    [
                        100.7,
                        13.60494
                    ],
                    [
                        100.699996,
                        13.60494
                    ],
                    [
                        100.699991,
                        13.604938
                    ],
                    [
                        100.69999,
                        13.604938
                    ],
                    [
                        100.699991,
                        13.604936
                    ],
                    [
                        100.699991,
                        13.604935
                    ],
                    [
                        100.69999,
                        13.604933
                    ],
                    [
                        100.69999,
                        13.604935
                    ],
                    [
                        100.699991,
                        13.604935
                    ],
                    [
                        100.699993,
                        13.604936
                    ],
                    [
                        100.699993,
                        13.604935
                    ],
                    [
                        100.700006,
                        13.60471
                    ],
                    [
                        100.700026,
                        13.604423
                    ],
                    [
                        100.70004,
                        13.604208
                    ],
                    [
                        100.700011,
                        13.604133
                    ],
                    [
                        100.699933,
                        13.604081
                    ],
                    [
                        100.699833,
                        13.604063
                    ],
                    [
                        100.699406,
                        13.604168
                    ],
                    [
                        100.698888,
                        13.604296
                    ],
                    [
                        100.698425,
                        13.60441
                    ],
                    [
                        100.6975,
                        13.60464
                    ],
                    [
                        100.696748,
                        13.604821
                    ],
                    [
                        100.695696,
                        13.605075
                    ],
                    [
                        100.694831,
                        13.605285
                    ],
                    [
                        100.693871,
                        13.605525
                    ],
                    [
                        100.693095,
                        13.605718
                    ],
                    [
                        100.692305,
                        13.60591
                    ],
                    [
                        100.691335,
                        13.606143
                    ],
                    [
                        100.690486,
                        13.606345
                    ],
                    [
                        100.689663,
                        13.606546
                    ],
                    [
                        100.688666,
                        13.606793
                    ],
                    [
                        100.687828,
                        13.606998
                    ],
                    [
                        100.686886,
                        13.607233
                    ],
                    [
                        100.686225,
                        13.607395
                    ],
                    [
                        100.685565,
                        13.60756
                    ],
                    [
                        100.684735,
                        13.60776
                    ],
                    [
                        100.683983,
                        13.607946
                    ],
                    [
                        100.683153,
                        13.608151
                    ],
                    [
                        100.68246,
                        13.608321
                    ],
                    [
                        100.681728,
                        13.608491
                    ],
                    [
                        100.68084,
                        13.60871
                    ],
                    [
                        100.680055,
                        13.608906
                    ],
                    [
                        100.679195,
                        13.609118
                    ],
                    [
                        100.678555,
                        13.609303
                    ],
                    [
                        100.678223,
                        13.609388
                    ],
                    [
                        100.678173,
                        13.6094
                    ],
                    [
                        100.67805,
                        13.609431
                    ],
                    [
                        100.677978,
                        13.60945
                    ],
                    [
                        100.67796,
                        13.609461
                    ],
                    [
                        100.677948,
                        13.609485
                    ],
                    [
                        100.677953,
                        13.609513
                    ],
                    [
                        100.677978,
                        13.60953
                    ],
                    [
                        100.678011,
                        13.609528
                    ],
                    [
                        100.678316,
                        13.609433
                    ],
                    [
                        100.678916,
                        13.609285
                    ],
                    [
                        100.679736,
                        13.609083
                    ],
                    [
                        100.680668,
                        13.60886
                    ],
                    [
                        100.681635,
                        13.608625
                    ],
                    [
                        100.682488,
                        13.608416
                    ],
                    [
                        100.683503,
                        13.608168
                    ],
                    [
                        100.68433,
                        13.607963
                    ],
                    [
                        100.685281,
                        13.607705
                    ],
                    [
                        100.686126,
                        13.607505
                    ],
                    [
                        100.686986,
                        13.607321
                    ],
                    [
                        100.687845,
                        13.607133
                    ],
                    [
                        100.688403,
                        13.606993
                    ],
                    [
                        100.688966,
                        13.606838
                    ],
                    [
                        100.689503,
                        13.606705
                    ],
                    [
                        100.690086,
                        13.606565
                    ],
                    [
                        100.690853,
                        13.60639
                    ],
                    [
                        100.691341,
                        13.60628
                    ],
                    [
                        100.691555,
                        13.60624
                    ],
                    [
                        100.691976,
                        13.606676
                    ],
                    [
                        100.691956,
                        13.60667
                    ],
                    [
                        100.691921,
                        13.60666
                    ],
                    [
                        100.691898,
                        13.60665
                    ],
                    [
                        100.691865,
                        13.606655
                    ],
                    [
                        100.691863,
                        13.606656
                    ],
                    [
                        100.691866,
                        13.606653
                    ],
                    [
                        100.691863,
                        13.606651
                    ],
                    [
                        100.691861,
                        13.606653
                    ],
                    [
                        100.691868,
                        13.606656
                    ],
                    [
                        100.691873,
                        13.606653
                    ],
                    [
                        100.691871,
                        13.606645
                    ],
                    [
                        100.691868,
                        13.606643
                    ],
                    [
                        100.691871,
                        13.606641
                    ],
                    [
                        100.691875,
                        13.606645
                    ],
                    [
                        100.691883,
                        13.606645
                    ],
                    [
                        100.691893,
                        13.606646
                    ],
                    [
                        100.69188,
                        13.606648
                    ],
                    [
                        100.691935,
                        13.606568
                    ],
                    [
                        100.69191,
                        13.606438
                    ],
                    [
                        100.691935,
                        13.606276
                    ],
                    [
                        100.691965,
                        13.606266
                    ],
                    [
                        100.692075,
                        13.60624
                    ],
                    [
                        100.692193,
                        13.60619
                    ],
                    [
                        100.692245,
                        13.606131
                    ],
                    [
                        100.692346,
                        13.606061
                    ],
                    [
                        100.692733,
                        13.60596
                    ],
                    [
                        100.693311,
                        13.60582
                    ],
                    [
                        100.693815,
                        13.605695
                    ],
                    [
                        100.694568,
                        13.60551
                    ],
                    [
                        100.695341,
                        13.605323
                    ],
                    [
                        100.69607,
                        13.605141
                    ],
                    [
                        100.69676,
                        13.604973
                    ],
                    [
                        100.696946,
                        13.604928
                    ],
                    [
                        100.696966,
                        13.604921
                    ],
                    [
                        100.696968,
                        13.604916
                    ],
                    [
                        100.696971,
                        13.604913
                    ],
                    [
                        100.696973,
                        13.604913
                    ],
                    [
                        100.696973,
                        13.604911
                    ],
                    [
                        100.697001,
                        13.60491
                    ],
                    [
                        100.697051,
                        13.604898
                    ],
                    [
                        100.69732,
                        13.604833
                    ],
                    [
                        100.697791,
                        13.604726
                    ],
                    [
                        100.69823,
                        13.604631
                    ],
                    [
                        100.69875,
                        13.604515
                    ],
                    [
                        100.699238,
                        13.604396
                    ],
                    [
                        100.699571,
                        13.60432
                    ],
                    [
                        100.69975,
                        13.604348
                    ],
                    [
                        100.699845,
                        13.604455
                    ],
                    [
                        100.699886,
                        13.604698
                    ],
                    [
                        100.699883,
                        13.605116
                    ],
                    [
                        100.69991,
                        13.605648
                    ],
                    [
                        100.700041,
                        13.606186
                    ],
                    [
                        100.700201,
                        13.606781
                    ],
                    [
                        100.700411,
                        13.607615
                    ],
                    [
                        100.700508,
                        13.607916
                    ],
                    [
                        100.700538,
                        13.607976
                    ],
                    [
                        100.700713,
                        13.608535
                    ],
                    [
                        100.70087,
                        13.60906
                    ],
                    [
                        100.701076,
                        13.60973
                    ],
                    [
                        100.70123,
                        13.610196
                    ],
                    [
                        100.701458,
                        13.610891
                    ],
                    [
                        100.701671,
                        13.611533
                    ],
                    [
                        100.701835,
                        13.612033
                    ],
                    [
                        100.702026,
                        13.6126
                    ],
                    [
                        100.702238,
                        13.613233
                    ],
                    [
                        100.70253,
                        13.614078
                    ],
                    [
                        100.70273,
                        13.614713
                    ],
                    [
                        100.702821,
                        13.615005
                    ],
                    [
                        100.702943,
                        13.615378
                    ],
                    [
                        100.70297,
                        13.615458
                    ],
                    [
                        100.70303,
                        13.615655
                    ],
                    [
                        100.703161,
                        13.616128
                    ],
                    [
                        100.703251,
                        13.616415
                    ],
                    [
                        100.703301,
                        13.616568
                    ],
                    [
                        100.703355,
                        13.616745
                    ],
                    [
                        100.70348,
                        13.617078
                    ],
                    [
                        100.703695,
                        13.617708
                    ],
                    [
                        100.703925,
                        13.618391
                    ],
                    [
                        100.704246,
                        13.619353
                    ],
                    [
                        100.704533,
                        13.620235
                    ],
                    [
                        100.7049,
                        13.621378
                    ],
                    [
                        100.705148,
                        13.622165
                    ],
                    [
                        100.705531,
                        13.623366
                    ],
                    [
                        100.705908,
                        13.624528
                    ],
                    [
                        100.70621,
                        13.625483
                    ],
                    [
                        100.706566,
                        13.626601
                    ],
                    [
                        100.706855,
                        13.627506
                    ],
                    [
                        100.707133,
                        13.628398
                    ],
                    [
                        100.707443,
                        13.629366
                    ],
                    [
                        100.707676,
                        13.630111
                    ],
                    [
                        100.707943,
                        13.630958
                    ],
                    [
                        100.708161,
                        13.631668
                    ],
                    [
                        100.708406,
                        13.63233
                    ],
                    [
                        100.70881,
                        13.633136
                    ],
                    [
                        100.709175,
                        13.633838
                    ],
                    [
                        100.709633,
                        13.6347
                    ],
                    [
                        100.710056,
                        13.6355
                    ],
                    [
                        100.710505,
                        13.636356
                    ],
                    [
                        100.711035,
                        13.637381
                    ],
                    [
                        100.711461,
                        13.638235
                    ],
                    [
                        100.711878,
                        13.639101
                    ],
                    [
                        100.71223,
                        13.640206
                    ],
                    [
                        100.712493,
                        13.641113
                    ],
                    [
                        100.71278,
                        13.64211
                    ],
                    [
                        100.713005,
                        13.642885
                    ],
                    [
                        100.713273,
                        13.643853
                    ],
                    [
                        100.71347,
                        13.644606
                    ],
                    [
                        100.713651,
                        13.645306
                    ],
                    [
                        100.713896,
                        13.64619
                    ],
                    [
                        100.714111,
                        13.647001
                    ],
                    [
                        100.71434,
                        13.647866
                    ],
                    [
                        100.71463,
                        13.64897
                    ],
                    [
                        100.71487,
                        13.649873
                    ],
                    [
                        100.715148,
                        13.650921
                    ],
                    [
                        100.715403,
                        13.651818
                    ],
                    [
                        100.715711,
                        13.652911
                    ],
                    [
                        100.715941,
                        13.653718
                    ],
                    [
                        100.716143,
                        13.65441
                    ],
                    [
                        100.716308,
                        13.654995
                    ],
                    [
                        100.716351,
                        13.655141
                    ],
                    [
                        100.716405,
                        13.655325
                    ],
                    [
                        100.716526,
                        13.655743
                    ],
                    [
                        100.716665,
                        13.656226
                    ],
                    [
                        100.71681,
                        13.656738
                    ],
                    [
                        100.716991,
                        13.657363
                    ],
                    [
                        100.717248,
                        13.658255
                    ],
                    [
                        100.717495,
                        13.659075
                    ],
                    [
                        100.717828,
                        13.660158
                    ],
                    [
                        100.718058,
                        13.660908
                    ],
                    [
                        100.718398,
                        13.662031
                    ],
                    [
                        100.718688,
                        13.663085
                    ],
                    [
                        100.718931,
                        13.66397
                    ],
                    [
                        100.719235,
                        13.665051
                    ],
                    [
                        100.71946,
                        13.665878
                    ],
                    [
                        100.719691,
                        13.666705
                    ],
                    [
                        100.719843,
                        13.667253
                    ],
                    [
                        100.720015,
                        13.667886
                    ],
                    [
                        100.720258,
                        13.668783
                    ],
                    [
                        100.720451,
                        13.669476
                    ],
                    [
                        100.720608,
                        13.670035
                    ],
                    [
                        100.720798,
                        13.670715
                    ],
                    [
                        100.720965,
                        13.671311
                    ],
                    [
                        100.721113,
                        13.671856
                    ],
                    [
                        100.721293,
                        13.672506
                    ],
                    [
                        100.721473,
                        13.673158
                    ],
                    [
                        100.721616,
                        13.673715
                    ],
                    [
                        100.72176,
                        13.674328
                    ],
                    [
                        100.721886,
                        13.674905
                    ],
                    [
                        100.721991,
                        13.675403
                    ],
                    [
                        100.722176,
                        13.676228
                    ],
                    [
                        100.722351,
                        13.676701
                    ],
                    [
                        100.72256,
                        13.677111
                    ],
                    [
                        100.722668,
                        13.677338
                    ],
                    [
                        100.722751,
                        13.677508
                    ],
                    [
                        100.722815,
                        13.677641
                    ],
                    [
                        100.722873,
                        13.677758
                    ],
                    [
                        100.72307,
                        13.678121
                    ],
                    [
                        100.723141,
                        13.678256
                    ],
                    [
                        100.723146,
                        13.678263
                    ],
                    [
                        100.723148,
                        13.678268
                    ],
                    [
                        100.723163,
                        13.678295
                    ],
                    [
                        100.723251,
                        13.67847
                    ],
                    [
                        100.723276,
                        13.678516
                    ],
                    [
                        100.723391,
                        13.678741
                    ],
                    [
                        100.723645,
                        13.679243
                    ],
                    [
                        100.723828,
                        13.679605
                    ],
                    [
                        100.724091,
                        13.680125
                    ],
                    [
                        100.724358,
                        13.68065
                    ],
                    [
                        100.724681,
                        13.681281
                    ],
                    [
                        100.725081,
                        13.682073
                    ],
                    [
                        100.725411,
                        13.682698
                    ],
                    [
                        100.725828,
                        13.683496
                    ],
                    [
                        100.726183,
                        13.684176
                    ],
                    [
                        100.72662,
                        13.685005
                    ],
                    [
                        100.726978,
                        13.685671
                    ],
                    [
                        100.727331,
                        13.686346
                    ],
                    [
                        100.727736,
                        13.687138
                    ],
                    [
                        100.728066,
                        13.687876
                    ],
                    [
                        100.728426,
                        13.688756
                    ],
                    [
                        100.728721,
                        13.689478
                    ],
                    [
                        100.728995,
                        13.690173
                    ],
                    [
                        100.729361,
                        13.691018
                    ],
                    [
                        100.729643,
                        13.691703
                    ],
                    [
                        100.72998,
                        13.692526
                    ],
                    [
                        100.730205,
                        13.693076
                    ],
                    [
                        100.730491,
                        13.693766
                    ],
                    [
                        100.73095,
                        13.694893
                    ],
                    [
                        100.731248,
                        13.695703
                    ],
                    [
                        100.731528,
                        13.696461
                    ],
                    [
                        100.731863,
                        13.697283
                    ],
                    [
                        100.73212,
                        13.697945
                    ],
                    [
                        100.732318,
                        13.69844
                    ],
                    [
                        100.732553,
                        13.69903
                    ],
                    [
                        100.732898,
                        13.699795
                    ],
                    [
                        100.733121,
                        13.700321
                    ],
                    [
                        100.733333,
                        13.700825
                    ],
                    [
                        100.733501,
                        13.701195
                    ],
                    [
                        100.733778,
                        13.701803
                    ],
                    [
                        100.734095,
                        13.70252
                    ],
                    [
                        100.734395,
                        13.703203
                    ],
                    [
                        100.734703,
                        13.703901
                    ],
                    [
                        100.73508,
                        13.704695
                    ],
                    [
                        100.735378,
                        13.705325
                    ],
                    [
                        100.735675,
                        13.705943
                    ],
                    [
                        100.736016,
                        13.706618
                    ],
                    [
                        100.736303,
                        13.707228
                    ],
                    [
                        100.736636,
                        13.707928
                    ],
                    [
                        100.736853,
                        13.708395
                    ],
                    [
                        100.737176,
                        13.709083
                    ],
                    [
                        100.737515,
                        13.709801
                    ],
                    [
                        100.737761,
                        13.7103
                    ],
                    [
                        100.738036,
                        13.710843
                    ],
                    [
                        100.738428,
                        13.711631
                    ],
                    [
                        100.738811,
                        13.712405
                    ],
                    [
                        100.739306,
                        13.713408
                    ],
                    [
                        100.740956,
                        13.716673
                    ],
                    [
                        100.741258,
                        13.716891
                    ],
                    [
                        100.742056,
                        13.717098
                    ],
                    [
                        100.742218,
                        13.717108
                    ],
                    [
                        100.743268,
                        13.717181
                    ],
                    [
                        100.744015,
                        13.71723
                    ],
                    [
                        100.745068,
                        13.717225
                    ],
                    [
                        100.746056,
                        13.717276
                    ],
                    [
                        100.746833,
                        13.717335
                    ],
                    [
                        100.747628,
                        13.717498
                    ],
                    [
                        100.747793,
                        13.717661
                    ],
                    [
                        100.747863,
                        13.717863
                    ],
                    [
                        100.747833,
                        13.71807
                    ],
                    [
                        100.747715,
                        13.718251
                    ],
                    [
                        100.747528,
                        13.718368
                    ],
                    [
                        100.74742,
                        13.718393
                    ],
                    [
                        100.747196,
                        13.71837
                    ],
                    [
                        100.747001,
                        13.71825
                    ],
                    [
                        100.746871,
                        13.718058
                    ],
                    [
                        100.746798,
                        13.717698
                    ],
                    [
                        100.746786,
                        13.717305
                    ],
                    [
                        100.74683,
                        13.71644
                    ],
                    [
                        100.74691,
                        13.716155
                    ],
                    [
                        100.747206,
                        13.715648
                    ],
                    [
                        100.747813,
                        13.71521
                    ],
                    [
                        100.747955,
                        13.715153
                    ],
                    [
                        100.748706,
                        13.714951
                    ],
                    [
                        100.749471,
                        13.714766
                    ],
                    [
                        100.750391,
                        13.714551
                    ],
                    [
                        100.751366,
                        13.714325
                    ],
                    [
                        100.752223,
                        13.714126
                    ],
                    [
                        100.753233,
                        13.713873
                    ],
                    [
                        100.753995,
                        13.71369
                    ],
                    [
                        100.754423,
                        13.713603
                    ],
                    [
                        100.754598,
                        13.71363
                    ],
                    [
                        100.754735,
                        13.713743
                    ],
                    [
                        100.754805,
                        13.713913
                    ],
                    [
                        100.75485,
                        13.714096
                    ],
                    [
                        100.755036,
                        13.714633
                    ],
                    [
                        100.75524,
                        13.714966
                    ],
                    [
                        100.755416,
                        13.715171
                    ],
                    [
                        100.755963,
                        13.715618
                    ],
                    [
                        100.756578,
                        13.716283
                    ],
                    [
                        100.756816,
                        13.716906
                    ],
                    [
                        100.756986,
                        13.717421
                    ],
                    [
                        100.75733,
                        13.718336
                    ],
                    [
                        100.757826,
                        13.719438
                    ],
                    [
                        100.75827,
                        13.720353
                    ],
                    [
                        100.75871,
                        13.721265
                    ],
                    [
                        100.759228,
                        13.722368
                    ],
                    [
                        100.759576,
                        13.723336
                    ],
                    [
                        100.759838,
                        13.724548
                    ],
                    [
                        100.759916,
                        13.725565
                    ],
                    [
                        100.759895,
                        13.726381
                    ],
                    [
                        100.759858,
                        13.72679
                    ],
                    [
                        100.759606,
                        13.727983
                    ],
                    [
                        100.759346,
                        13.728976
                    ],
                    [
                        100.759096,
                        13.72991
                    ],
                    [
                        100.758895,
                        13.730675
                    ],
                    [
                        100.758778,
                        13.731021
                    ],
                    [
                        100.75864,
                        13.731191
                    ],
                    [
                        100.758441,
                        13.731295
                    ],
                    [
                        100.758213,
                        13.731301
                    ],
                    [
                        100.75792,
                        13.731115
                    ],
                    [
                        100.757835,
                        13.730893
                    ],
                    [
                        100.757875,
                        13.730656
                    ],
                    [
                        100.758031,
                        13.730466
                    ],
                    [
                        100.758143,
                        13.730406
                    ],
                    [
                        100.758265,
                        13.730373
                    ],
                    [
                        100.758523,
                        13.730381
                    ],
                    [
                        100.758925,
                        13.730471
                    ],
                    [
                        100.759835,
                        13.73064
                    ],
                    [
                        100.76065,
                        13.730805
                    ],
                    [
                        100.761643,
                        13.731028
                    ],
                    [
                        100.76245,
                        13.731201
                    ],
                    [
                        100.763261,
                        13.73138
                    ],
                    [
                        100.764251,
                        13.731615
                    ],
                    [
                        100.765133,
                        13.73181
                    ],
                    [
                        100.766116,
                        13.731886
                    ],
                    [
                        100.767176,
                        13.731916
                    ],
                    [
                        100.768478,
                        13.731973
                    ],
                    [
                        100.771695,
                        13.732158
                    ],
                    [
                        100.773203,
                        13.732176
                    ],
                    [
                        100.774288,
                        13.732181
                    ],
                    [
                        100.775601,
                        13.732183
                    ],
                    [
                        100.776706,
                        13.732185
                    ],
                    [
                        100.777823,
                        13.732195
                    ],
                    [
                        100.779178,
                        13.732218
                    ],
                    [
                        100.780298,
                        13.732246
                    ],
                    [
                        100.781621,
                        13.73228
                    ],
                    [
                        100.782721,
                        13.732315
                    ],
                    [
                        100.784046,
                        13.73236
                    ],
                    [
                        100.785151,
                        13.732435
                    ],
                    [
                        100.786471,
                        13.732583
                    ],
                    [
                        100.787568,
                        13.732706
                    ],
                    [
                        100.78866,
                        13.732826
                    ],
                    [
                        100.789973,
                        13.732966
                    ],
                    [
                        100.791066,
                        13.733086
                    ],
                    [
                        100.791895,
                        13.733176
                    ],
                    [
                        100.793125,
                        13.733293
                    ],
                    [
                        100.793985,
                        13.733375
                    ],
                    [
                        100.795146,
                        13.733495
                    ],
                    [
                        100.795981,
                        13.733598
                    ],
                    [
                        100.797485,
                        13.733791
                    ],
                    [
                        100.798563,
                        13.733908
                    ],
                    [
                        100.79963,
                        13.734025
                    ],
                    [
                        100.8009,
                        13.734168
                    ],
                    [
                        100.801955,
                        13.734305
                    ],
                    [
                        100.803218,
                        13.734333
                    ],
                    [
                        100.804261,
                        13.73421
                    ],
                    [
                        100.805465,
                        13.733891
                    ],
                    [
                        100.806428,
                        13.733528
                    ],
                    [
                        100.807346,
                        13.733056
                    ],
                    [
                        100.807521,
                        13.73295
                    ],
                    [
                        100.80835,
                        13.73237
                    ],
                    [
                        100.809096,
                        13.731706
                    ],
                    [
                        100.80964,
                        13.731118
                    ],
                    [
                        100.809891,
                        13.730808
                    ],
                    [
                        100.81055,
                        13.729808
                    ],
                    [
                        100.81101,
                        13.728916
                    ],
                    [
                        100.81146,
                        13.728015
                    ],
                    [
                        100.81189,
                        13.727128
                    ],
                    [
                        100.8124,
                        13.726085
                    ],
                    [
                        100.812915,
                        13.725046
                    ],
                    [
                        100.813356,
                        13.724141
                    ],
                    [
                        100.81388,
                        13.723081
                    ],
                    [
                        100.814315,
                        13.722191
                    ],
                    [
                        100.814858,
                        13.721086
                    ],
                    [
                        100.81532,
                        13.720143
                    ],
                    [
                        100.815793,
                        13.71919
                    ],
                    [
                        100.816361,
                        13.71804
                    ],
                    [
                        100.816831,
                        13.717076
                    ],
                    [
                        100.817396,
                        13.715928
                    ],
                    [
                        100.817846,
                        13.715011
                    ],
                    [
                        100.818301,
                        13.714123
                    ],
                    [
                        100.818828,
                        13.71308
                    ],
                    [
                        100.819241,
                        13.712263
                    ],
                    [
                        100.819718,
                        13.711283
                    ],
                    [
                        100.820093,
                        13.71051
                    ],
                    [
                        100.820551,
                        13.709591
                    ],
                    [
                        100.820943,
                        13.708791
                    ],
                    [
                        100.821343,
                        13.707995
                    ],
                    [
                        100.821835,
                        13.707028
                    ],
                    [
                        100.822238,
                        13.70622
                    ],
                    [
                        100.822625,
                        13.705481
                    ],
                    [
                        100.822958,
                        13.705121
                    ],
                    [
                        100.823165,
                        13.70505
                    ],
                    [
                        100.823278,
                        13.705038
                    ],
                    [
                        100.823625,
                        13.705118
                    ],
                    [
                        100.823823,
                        13.705266
                    ],
                    [
                        100.823898,
                        13.705361
                    ],
                    [
                        100.824015,
                        13.705698
                    ],
                    [
                        100.823988,
                        13.705941
                    ],
                    [
                        100.823945,
                        13.706063
                    ],
                    [
                        100.823641,
                        13.706686
                    ],
                    [
                        100.823321,
                        13.707155
                    ],
                    [
                        100.82322,
                        13.707253
                    ],
                    [
                        100.822468,
                        13.707781
                    ],
                    [
                        100.821886,
                        13.708218
                    ],
                    [
                        100.821673,
                        13.708573
                    ],
                    [
                        100.821586,
                        13.708988
                    ],
                    [
                        100.821533,
                        13.709576
                    ],
                    [
                        100.8215,
                        13.709978
                    ],
                    [
                        100.821495,
                        13.71003
                    ],
                    [
                        100.821495,
                        13.710031
                    ],
                    [
                        100.821493,
                        13.710031
                    ],
                    [
                        100.821493,
                        13.710033
                    ],
                    [
                        100.821491,
                        13.71004
                    ],
                    [
                        100.821483,
                        13.710153
                    ],
                    [
                        100.821481,
                        13.71031
                    ],
                    [
                        100.821498,
                        13.710348
                    ],
                    [
                        100.821556,
                        13.710393
                    ],
                    [
                        100.821595,
                        13.710393
                    ],
                    [
                        100.821845,
                        13.71031
                    ],
                    [
                        100.822375,
                        13.71013
                    ],
                    [
                        100.822861,
                        13.709956
                    ],
                    [
                        100.823365,
                        13.709776
                    ],
                    [
                        100.82397,
                        13.709561
                    ],
                    [
                        100.824428,
                        13.709408
                    ],
                    [
                        100.82497,
                        13.709238
                    ],
                    [
                        100.825471,
                        13.709063
                    ],
                    [
                        100.826083,
                        13.708841
                    ],
                    [
                        100.826576,
                        13.708665
                    ],
                    [
                        100.827101,
                        13.708478
                    ],
                    [
                        100.827453,
                        13.708356
                    ],
                    [
                        100.827468,
                        13.708351
                    ],
                    [
                        100.827501,
                        13.708328
                    ],
                    [
                        100.827686,
                        13.708243
                    ],
                    [
                        100.827828,
                        13.708195
                    ],
                    [
                        100.828223,
                        13.708055
                    ],
                    [
                        100.828451,
                        13.70797
                    ],
                    [
                        100.828576,
                        13.707923
                    ],
                    [
                        100.828593,
                        13.707918
                    ],
                    [
                        100.828633,
                        13.707906
                    ],
                    [
                        100.828641,
                        13.707901
                    ],
                    [
                        100.828695,
                        13.707881
                    ],
                    [
                        100.82878,
                        13.70785
                    ],
                    [
                        100.8289,
                        13.707805
                    ],
                    [
                        100.82897,
                        13.70778
                    ],
                    [
                        100.828985,
                        13.707775
                    ],
                    [
                        100.828995,
                        13.707771
                    ],
                    [
                        100.829005,
                        13.707768
                    ],
                    [
                        100.829006,
                        13.707766
                    ],
                    [
                        100.829013,
                        13.707763
                    ],
                    [
                        100.829015,
                        13.707763
                    ],
                    [
                        100.829023,
                        13.707765
                    ],
                    [
                        100.829026,
                        13.707766
                    ],
                    [
                        100.82903,
                        13.707766
                    ],
                    [
                        100.829075,
                        13.707753
                    ],
                    [
                        100.829098,
                        13.707745
                    ],
                    [
                        100.829098,
                        13.707741
                    ],
                    [
                        100.829156,
                        13.707731
                    ],
                    [
                        100.829166,
                        13.707731
                    ],
                    [
                        100.829168,
                        13.707731
                    ],
                    [
                        100.82917,
                        13.707731
                    ],
                    [
                        100.829168,
                        13.707731
                    ],
                    [
                        100.829168,
                        13.70773
                    ],
                    [
                        100.829171,
                        13.70773
                    ],
                    [
                        100.829173,
                        13.707731
                    ],
                    [
                        100.829175,
                        13.707733
                    ],
                    [
                        100.829175,
                        13.707735
                    ],
                    [
                        100.829173,
                        13.707735
                    ],
                    [
                        100.829171,
                        13.707735
                    ],
                    [
                        100.829171,
                        13.707736
                    ],
                    [
                        100.82917,
                        13.707736
                    ],
                    [
                        100.829178,
                        13.707735
                    ],
                    [
                        100.829235,
                        13.707716
                    ],
                    [
                        100.829236,
                        13.707713
                    ],
                    [
                        100.829243,
                        13.707711
                    ],
                    [
                        100.829245,
                        13.707713
                    ],
                    [
                        100.829251,
                        13.707711
                    ],
                    [
                        100.829253,
                        13.707708
                    ],
                    [
                        100.829253,
                        13.707706
                    ],
                    [
                        100.829251,
                        13.707705
                    ],
                    [
                        100.829251,
                        13.707703
                    ],
                    [
                        100.829255,
                        13.7077
                    ],
                    [
                        100.829255,
                        13.707698
                    ],
                    [
                        100.829266,
                        13.707693
                    ],
                    [
                        100.829396,
                        13.707653
                    ],
                    [
                        100.829525,
                        13.707606
                    ],
                    [
                        100.829705,
                        13.707536
                    ],
                    [
                        100.829886,
                        13.70747
                    ],
                    [
                        100.830173,
                        13.707366
                    ],
                    [
                        100.830443,
                        13.707266
                    ],
                    [
                        100.830648,
                        13.707188
                    ],
                    [
                        100.831053,
                        13.707033
                    ],
                    [
                        100.831351,
                        13.70692
                    ],
                    [
                        100.831643,
                        13.706808
                    ],
                    [
                        100.832061,
                        13.70665
                    ],
                    [
                        100.832436,
                        13.706508
                    ],
                    [
                        100.832801,
                        13.706371
                    ],
                    [
                        100.833095,
                        13.706275
                    ],
                    [
                        100.833418,
                        13.706151
                    ],
                    [
                        100.833693,
                        13.706046
                    ],
                    [
                        100.83396,
                        13.705943
                    ],
                    [
                        100.834166,
                        13.705865
                    ],
                    [
                        100.834461,
                        13.705751
                    ],
                    [
                        100.835005,
                        13.705541
                    ],
                    [
                        100.835548,
                        13.705338
                    ],
                    [
                        100.83613,
                        13.705115
                    ],
                    [
                        100.83679,
                        13.704856
                    ],
                    [
                        100.83734,
                        13.704643
                    ],
                    [
                        100.83802,
                        13.704383
                    ],
                    [
                        100.838503,
                        13.704195
                    ],
                    [
                        100.839101,
                        13.703958
                    ],
                    [
                        100.839585,
                        13.70377
                    ],
                    [
                        100.840098,
                        13.703566
                    ],
                    [
                        100.840721,
                        13.70332
                    ],
                    [
                        100.84123,
                        13.703123
                    ],
                    [
                        100.841908,
                        13.702808
                    ],
                    [
                        100.842533,
                        13.702548
                    ],
                    [
                        100.843238,
                        13.70228
                    ],
                    [
                        100.844216,
                        13.701898
                    ],
                    [
                        100.845041,
                        13.701573
                    ],
                    [
                        100.846045,
                        13.701128
                    ],
                    [
                        100.846798,
                        13.700675
                    ],
                    [
                        100.84742,
                        13.699846
                    ],
                    [
                        100.848016,
                        13.699191
                    ],
                    [
                        100.848781,
                        13.698813
                    ],
                    [
                        100.849516,
                        13.69853
                    ],
                    [
                        100.850201,
                        13.698263
                    ],
                    [
                        100.850666,
                        13.69809
                    ],
                    [
                        100.85122,
                        13.697875
                    ],
                    [
                        100.851768,
                        13.69766
                    ],
                    [
                        100.852415,
                        13.697411
                    ],
                    [
                        100.853303,
                        13.697066
                    ],
                    [
                        100.854061,
                        13.696771
                    ],
                    [
                        100.854831,
                        13.696473
                    ],
                    [
                        100.855736,
                        13.696118
                    ],
                    [
                        100.856178,
                        13.695926
                    ],
                    [
                        100.856433,
                        13.695803
                    ],
                    [
                        100.856435,
                        13.695786
                    ],
                    [
                        100.856425,
                        13.695765
                    ],
                    [
                        100.856403,
                        13.695751
                    ],
                    [
                        100.856371,
                        13.695753
                    ],
                    [
                        100.856281,
                        13.695788
                    ],
                    [
                        100.855895,
                        13.695943
                    ],
                    [
                        100.85526,
                        13.69621
                    ],
                    [
                        100.854796,
                        13.696393
                    ],
                    [
                        100.854101,
                        13.696656
                    ],
                    [
                        100.853796,
                        13.696746
                    ],
                    [
                        100.85377,
                        13.696736
                    ],
                    [
                        100.853743,
                        13.696698
                    ],
                    [
                        100.85371,
                        13.696605
                    ],
                    [
                        100.853566,
                        13.696248
                    ],
                    [
                        100.853435,
                        13.695906
                    ],
                    [
                        100.853213,
                        13.695338
                    ],
                    [
                        100.85298,
                        13.69476
                    ],
                    [
                        100.852788,
                        13.694265
                    ],
                    [
                        100.852553,
                        13.693665
                    ],
                    [
                        100.852378,
                        13.69323
                    ],
                    [
                        100.852288,
                        13.692993
                    ],
                    [
                        100.85213,
                        13.692515
                    ],
                    [
                        100.851926,
                        13.691953
                    ],
                    [
                        100.851715,
                        13.69142
                    ],
                    [
                        100.851445,
                        13.69075
                    ],
                    [
                        100.851208,
                        13.690166
                    ],
                    [
                        100.850921,
                        13.689451
                    ],
                    [
                        100.850728,
                        13.688965
                    ],
                    [
                        100.850388,
                        13.688121
                    ],
                    [
                        100.850161,
                        13.687546
                    ],
                    [
                        100.849835,
                        13.686963
                    ],
                    [
                        100.849756,
                        13.686895
                    ],
                    [
                        100.849588,
                        13.686775
                    ],
                    [
                        100.849466,
                        13.686705
                    ],
                    [
                        100.849208,
                        13.686518
                    ],
                    [
                        100.848788,
                        13.686216
                    ],
                    [
                        100.848136,
                        13.685753
                    ],
                    [
                        100.847531,
                        13.685325
                    ],
                    [
                        100.846873,
                        13.684863
                    ],
                    [
                        100.846515,
                        13.684511
                    ],
                    [
                        100.846346,
                        13.68409
                    ],
                    [
                        100.84627,
                        13.684026
                    ],
                    [
                        100.846166,
                        13.683996
                    ],
                    [
                        100.846166,
                        13.683996
                    ],
                    [
                        100.84565,
                        13.684145
                    ],
                    [
                        100.845103,
                        13.684428
                    ],
                    [
                        100.844616,
                        13.684671
                    ],
                    [
                        100.844063,
                        13.684851
                    ],
                    [
                        100.843718,
                        13.68494
                    ],
                    [
                        100.843688,
                        13.684961
                    ],
                    [
                        100.843668,
                        13.684993
                    ],
                    [
                        100.843665,
                        13.68503
                    ],
                    [
                        100.843691,
                        13.685113
                    ],
                    [
                        100.843881,
                        13.685465
                    ],
                    [
                        100.844138,
                        13.685941
                    ],
                    [
                        100.844346,
                        13.686338
                    ],
                    [
                        100.84445,
                        13.686545
                    ],
                    [
                        100.844481,
                        13.686576
                    ],
                    [
                        100.8445,
                        13.68659
                    ],
                    [
                        100.8445,
                        13.686591
                    ],
                    [
                        100.844501,
                        13.686596
                    ],
                    [
                        100.844531,
                        13.686605
                    ],
                    [
                        100.844601,
                        13.686593
                    ],
                    [
                        100.844681,
                        13.68656
                    ],
                    [
                        100.844788,
                        13.686521
                    ],
                    [
                        100.8448,
                        13.686528
                    ],
                    [
                        100.844818,
                        13.68655
                    ],
                    [
                        100.844816,
                        13.686583
                    ],
                    [
                        100.844808,
                        13.686541
                    ],
                    [
                        100.844815,
                        13.686513
                    ],
                    [
                        100.844813,
                        13.686511
                    ],
                    [
                        100.844811,
                        13.68651
                    ],
                    [
                        100.844808,
                        13.68651
                    ],
                    [
                        100.844806,
                        13.686508
                    ],
                    [
                        100.844805,
                        13.686506
                    ],
                    [
                        100.844803,
                        13.686506
                    ],
                    [
                        100.844801,
                        13.686508
                    ],
                    [
                        100.844801,
                        13.68651
                    ],
                    [
                        100.844803,
                        13.686513
                    ],
                    [
                        100.844796,
                        13.686513
                    ],
                    [
                        100.844796,
                        13.686511
                    ],
                    [
                        100.844798,
                        13.686511
                    ],
                    [
                        100.8448,
                        13.686511
                    ],
                    [
                        100.8448,
                        13.68651
                    ],
                    [
                        100.8448,
                        13.686508
                    ],
                    [
                        100.844798,
                        13.686508
                    ],
                    [
                        100.844796,
                        13.686508
                    ],
                    [
                        100.844778,
                        13.686516
                    ],
                    [
                        100.844765,
                        13.686523
                    ],
                    [
                        100.844691,
                        13.686571
                    ],
                    [
                        100.844595,
                        13.686603
                    ],
                    [
                        100.844575,
                        13.686603
                    ],
                    [
                        100.84457,
                        13.686603
                    ],
                    [
                        100.844548,
                        13.686605
                    ],
                    [
                        100.844501,
                        13.686608
                    ],
                    [
                        100.844465,
                        13.68659
                    ],
                    [
                        100.844451,
                        13.686568
                    ],
                    [
                        100.84444,
                        13.686545
                    ],
                    [
                        100.844416,
                        13.686501
                    ],
                    [
                        100.84436,
                        13.686388
                    ],
                    [
                        100.844301,
                        13.686263
                    ],
                    [
                        100.844196,
                        13.686078
                    ],
                    [
                        100.844115,
                        13.68593
                    ],
                    [
                        100.843968,
                        13.685651
                    ],
                    [
                        100.843856,
                        13.68545
                    ],
                    [
                        100.843773,
                        13.68529
                    ],
                    [
                        100.843688,
                        13.685128
                    ],
                    [
                        100.843661,
                        13.685008
                    ],
                    [
                        100.843673,
                        13.684985
                    ],
                    [
                        100.84371,
                        13.684953
                    ],
                    [
                        100.843871,
                        13.684898
                    ],
                    [
                        100.844041,
                        13.684848
                    ],
                    [
                        100.844218,
                        13.684816
                    ],
                    [
                        100.844498,
                        13.684726
                    ],
                    [
                        100.844848,
                        13.684566
                    ],
                    [
                        100.845145,
                        13.684415
                    ],
                    [
                        100.845438,
                        13.684265
                    ],
                    [
                        100.845735,
                        13.684115
                    ],
                    [
                        100.846001,
                        13.684025
                    ],
                    [
                        100.846108,
                        13.683996
                    ],
                    [
                        100.84618,
                        13.683995
                    ],
                    [
                        100.846213,
                        13.684001
                    ],
                    [
                        100.846266,
                        13.684026
                    ],
                    [
                        100.846286,
                        13.684041
                    ],
                    [
                        100.84635,
                        13.6841
                    ],
                    [
                        100.846365,
                        13.684125
                    ],
                    [
                        100.84642,
                        13.684326
                    ],
                    [
                        100.846528,
                        13.684576
                    ],
                    [
                        100.846658,
                        13.684723
                    ],
                    [
                        100.846815,
                        13.684841
                    ],
                    [
                        100.847075,
                        13.685023
                    ],
                    [
                        100.847376,
                        13.685238
                    ],
                    [
                        100.847625,
                        13.685411
                    ],
                    [
                        100.847888,
                        13.6856
                    ],
                    [
                        100.848236,
                        13.685848
                    ],
                    [
                        100.84855,
                        13.686063
                    ],
                    [
                        100.84895,
                        13.686346
                    ],
                    [
                        100.849295,
                        13.68659
                    ],
                    [
                        100.849633,
                        13.686828
                    ],
                    [
                        100.84998,
                        13.687165
                    ],
                    [
                        100.850018,
                        13.68724
                    ],
                    [
                        100.850158,
                        13.687573
                    ],
                    [
                        100.85036,
                        13.688091
                    ],
                    [
                        100.850525,
                        13.688501
                    ],
                    [
                        100.850691,
                        13.68891
                    ],
                    [
                        100.850903,
                        13.689436
                    ],
                    [
                        100.851076,
                        13.689885
                    ],
                    [
                        100.851288,
                        13.690408
                    ],
                    [
                        100.85146,
                        13.690836
                    ],
                    [
                        100.851651,
                        13.69131
                    ],
                    [
                        100.8518,
                        13.691688
                    ],
                    [
                        100.851953,
                        13.6921
                    ],
                    [
                        100.852085,
                        13.692458
                    ],
                    [
                        100.852206,
                        13.692828
                    ],
                    [
                        100.85235,
                        13.693218
                    ],
                    [
                        100.852461,
                        13.693528
                    ],
                    [
                        100.852616,
                        13.693888
                    ],
                    [
                        100.852783,
                        13.694336
                    ],
                    [
                        100.852918,
                        13.694676
                    ],
                    [
                        100.853071,
                        13.695076
                    ],
                    [
                        100.853205,
                        13.695418
                    ],
                    [
                        100.853333,
                        13.695755
                    ],
                    [
                        100.853473,
                        13.696103
                    ],
                    [
                        100.853566,
                        13.696336
                    ],
                    [
                        100.853653,
                        13.696553
                    ],
                    [
                        100.853718,
                        13.696741
                    ],
                    [
                        100.853715,
                        13.696756
                    ],
                    [
                        100.853705,
                        13.696771
                    ],
                    [
                        100.853661,
                        13.6968
                    ],
                    [
                        100.853596,
                        13.696833
                    ],
                    [
                        100.853251,
                        13.696978
                    ],
                    [
                        100.852885,
                        13.697115
                    ],
                    [
                        100.852475,
                        13.697278
                    ],
                    [
                        100.852023,
                        13.697453
                    ],
                    [
                        100.851868,
                        13.697513
                    ],
                    [
                        100.851858,
                        13.697516
                    ],
                    [
                        100.851856,
                        13.697518
                    ],
                    [
                        100.851855,
                        13.69752
                    ],
                    [
                        100.851851,
                        13.69752
                    ],
                    [
                        100.85185,
                        13.697521
                    ],
                    [
                        100.851848,
                        13.697521
                    ],
                    [
                        100.85185,
                        13.697521
                    ],
                    [
                        100.85185,
                        13.69752
                    ],
                    [
                        100.851851,
                        13.69752
                    ],
                    [
                        100.851853,
                        13.69752
                    ],
                    [
                        100.851855,
                        13.697516
                    ],
                    [
                        100.851856,
                        13.697515
                    ],
                    [
                        100.851808,
                        13.697533
                    ],
                    [
                        100.851445,
                        13.697675
                    ],
                    [
                        100.850955,
                        13.697866
                    ],
                    [
                        100.850325,
                        13.698116
                    ],
                    [
                        100.849786,
                        13.698325
                    ],
                    [
                        100.84911,
                        13.698581
                    ],
                    [
                        100.848555,
                        13.698791
                    ],
                    [
                        100.847941,
                        13.699128
                    ],
                    [
                        100.847586,
                        13.69945
                    ],
                    [
                        100.84751,
                        13.699541
                    ],
                    [
                        100.847123,
                        13.7001
                    ],
                    [
                        100.846825,
                        13.700498
                    ],
                    [
                        100.846436,
                        13.700801
                    ],
                    [
                        100.846258,
                        13.700898
                    ],
                    [
                        100.845885,
                        13.701076
                    ],
                    [
                        100.845398,
                        13.701305
                    ],
                    [
                        100.844745,
                        13.70158
                    ],
                    [
                        100.844156,
                        13.701808
                    ],
                    [
                        100.843468,
                        13.702078
                    ],
                    [
                        100.842906,
                        13.702298
                    ],
                    [
                        100.842326,
                        13.702528
                    ],
                    [
                        100.841565,
                        13.70283
                    ],
                    [
                        100.840753,
                        13.703136
                    ],
                    [
                        100.840106,
                        13.703393
                    ],
                    [
                        100.839485,
                        13.70364
                    ],
                    [
                        100.83879,
                        13.703908
                    ],
                    [
                        100.838176,
                        13.70415
                    ],
                    [
                        100.83753,
                        13.7044
                    ],
                    [
                        100.836765,
                        13.704695
                    ],
                    [
                        100.836135,
                        13.704943
                    ],
                    [
                        100.835391,
                        13.705233
                    ],
                    [
                        100.834788,
                        13.705463
                    ],
                    [
                        100.834166,
                        13.7057
                    ],
                    [
                        100.83336,
                        13.706013
                    ],
                    [
                        100.832646,
                        13.706288
                    ],
                    [
                        100.831846,
                        13.7066
                    ],
                    [
                        100.831203,
                        13.706841
                    ],
                    [
                        100.830533,
                        13.707098
                    ],
                    [
                        100.829655,
                        13.707425
                    ],
                    [
                        100.828896,
                        13.707696
                    ],
                    [
                        100.82799,
                        13.70803
                    ],
                    [
                        100.82722,
                        13.7083
                    ],
                    [
                        100.826455,
                        13.708575
                    ],
                    [
                        100.825525,
                        13.708908
                    ],
                    [
                        100.824765,
                        13.709178
                    ],
                    [
                        100.82391,
                        13.709491
                    ],
                    [
                        100.823176,
                        13.709768
                    ],
                    [
                        100.822495,
                        13.710013
                    ],
                    [
                        100.821923,
                        13.710216
                    ],
                    [
                        100.821716,
                        13.71029
                    ],
                    [
                        100.821706,
                        13.710293
                    ],
                    [
                        100.821705,
                        13.710293
                    ],
                    [
                        100.821701,
                        13.710295
                    ],
                    [
                        100.8217,
                        13.710296
                    ],
                    [
                        100.821696,
                        13.710298
                    ],
                    [
                        100.821698,
                        13.7103
                    ],
                    [
                        100.821696,
                        13.7103
                    ],
                    [
                        100.821691,
                        13.7103
                    ],
                    [
                        100.821665,
                        13.710308
                    ],
                    [
                        100.821586,
                        13.710335
                    ],
                    [
                        100.821345,
                        13.710423
                    ],
                    [
                        100.82081,
                        13.71062
                    ],
                    [
                        100.820223,
                        13.710831
                    ],
                    [
                        100.8195,
                        13.711093
                    ],
                    [
                        100.818993,
                        13.711278
                    ],
                    [
                        100.818466,
                        13.711465
                    ],
                    [
                        100.81771,
                        13.71174
                    ],
                    [
                        100.817006,
                        13.71198
                    ],
                    [
                        100.816341,
                        13.712215
                    ],
                    [
                        100.815541,
                        13.712498
                    ],
                    [
                        100.814935,
                        13.712705
                    ],
                    [
                        100.814258,
                        13.71295
                    ],
                    [
                        100.813593,
                        13.713196
                    ],
                    [
                        100.813026,
                        13.713408
                    ],
                    [
                        100.812485,
                        13.713653
                    ],
                    [
                        100.8118,
                        13.713946
                    ],
                    [
                        100.811226,
                        13.714175
                    ],
                    [
                        100.810551,
                        13.714446
                    ],
                    [
                        100.810001,
                        13.714676
                    ],
                    [
                        100.809338,
                        13.714945
                    ],
                    [
                        100.8088,
                        13.715166
                    ],
                    [
                        100.808273,
                        13.71538
                    ],
                    [
                        100.807753,
                        13.715593
                    ],
                    [
                        100.807218,
                        13.71579
                    ],
                    [
                        100.80681,
                        13.715921
                    ],
                    [
                        100.806248,
                        13.716115
                    ],
                    [
                        100.805701,
                        13.7163
                    ],
                    [
                        100.805158,
                        13.716476
                    ],
                    [
                        100.804531,
                        13.716686
                    ],
                    [
                        100.804018,
                        13.716853
                    ],
                    [
                        100.803466,
                        13.717031
                    ],
                    [
                        100.803348,
                        13.717058
                    ],
                    [
                        100.803265,
                        13.717033
                    ],
                    [
                        100.803231,
                        13.716968
                    ],
                    [
                        100.80319,
                        13.716816
                    ],
                    [
                        100.803178,
                        13.716776
                    ],
                    [
                        100.803176,
                        13.716776
                    ],
                    [
                        100.803175,
                        13.716776
                    ],
                    [
                        100.803175,
                        13.716773
                    ],
                    [
                        100.803133,
                        13.716655
                    ],
                    [
                        100.803063,
                        13.716466
                    ],
                    [
                        100.80301,
                        13.71625
                    ],
                    [
                        100.803016,
                        13.716183
                    ],
                    [
                        100.803056,
                        13.716136
                    ],
                    [
                        100.80308,
                        13.716125
                    ],
                    [
                        100.80312,
                        13.716101
                    ],
                    [
                        100.803131,
                        13.716105
                    ],
                    [
                        100.80315,
                        13.716111
                    ],
                    [
                        100.803161,
                        13.716111
                    ],
                    [
                        100.803215,
                        13.716103
                    ],
                    [
                        100.803221,
                        13.716118
                    ],
                    [
                        100.803258,
                        13.716125
                    ],
                    [
                        100.803251,
                        13.716135
                    ],
                    [
                        100.803261,
                        13.716146
                    ],
                    [
                        100.803243,
                        13.716145
                    ],
                    [
                        100.80323,
                        13.71613
                    ],
                    [
                        100.80325,
                        13.716138
                    ],
                    [
                        100.803253,
                        13.716151
                    ],
                    [
                        100.803255,
                        13.716151
                    ],
                    [
                        100.803135,
                        13.716208
                    ],
                    [
                        100.80314,
                        13.716233
                    ],
                    [
                        100.803163,
                        13.716431
                    ],
                    [
                        100.803188,
                        13.716605
                    ],
                    [
                        100.803218,
                        13.716763
                    ],
                    [
                        100.803225,
                        13.716826
                    ],
                    [
                        100.80321,
                        13.716823
                    ],
                    [
                        100.803195,
                        13.716821
                    ],
                    [
                        100.803185,
                        13.716823
                    ],
                    [
                        100.803201,
                        13.71689
                    ],
                    [
                        100.80324,
                        13.71701
                    ],
                    [
                        100.803251,
                        13.717065
                    ],
                    [
                        100.803245,
                        13.717098
                    ],
                    [
                        100.803225,
                        13.717126
                    ],
                    [
                        100.803166,
                        13.71716
                    ],
                    [
                        100.802805,
                        13.717295
                    ],
                    [
                        100.802255,
                        13.717495
                    ],
                    [
                        100.801753,
                        13.717678
                    ],
                    [
                        100.801136,
                        13.717908
                    ],
                    [
                        100.80059,
                        13.718108
                    ],
                    [
                        100.80019,
                        13.718253
                    ],
                    [
                        100.79975,
                        13.718413
                    ],
                    [
                        100.799363,
                        13.718556
                    ],
                    [
                        100.798976,
                        13.718696
                    ],
                    [
                        100.798456,
                        13.718883
                    ],
                    [
                        100.798046,
                        13.719035
                    ],
                    [
                        100.797576,
                        13.719191
                    ],
                    [
                        100.797138,
                        13.719353
                    ],
                    [
                        100.796763,
                        13.71949
                    ],
                    [
                        100.796321,
                        13.719655
                    ],
                    [
                        100.795925,
                        13.7198
                    ],
                    [
                        100.795438,
                        13.719973
                    ],
                    [
                        100.795001,
                        13.720131
                    ],
                    [
                        100.794475,
                        13.720323
                    ],
                    [
                        100.793986,
                        13.720498
                    ],
                    [
                        100.793498,
                        13.720673
                    ],
                    [
                        100.79298,
                        13.720841
                    ],
                    [
                        100.79294,
                        13.72083
                    ],
                    [
                        100.792851,
                        13.720745
                    ],
                    [
                        100.792816,
                        13.720728
                    ],
                    [
                        100.792575,
                        13.72076
                    ],
                    [
                        100.792548,
                        13.720768
                    ],
                    [
                        100.792541,
                        13.72077
                    ],
                    [
                        100.792538,
                        13.720771
                    ],
                    [
                        100.792536,
                        13.720773
                    ],
                    [
                        100.792513,
                        13.720771
                    ],
                    [
                        100.792498,
                        13.72077
                    ],
                    [
                        100.792501,
                        13.72077
                    ],
                    [
                        100.792505,
                        13.720775
                    ],
                    [
                        100.792493,
                        13.720785
                    ],
                    [
                        100.792495,
                        13.720785
                    ],
                    [
                        100.792495,
                        13.720786
                    ],
                    [
                        100.792495,
                        13.720788
                    ],
                    [
                        100.792493,
                        13.72079
                    ],
                    [
                        100.792491,
                        13.72079
                    ],
                    [
                        100.792488,
                        13.72079
                    ],
                    [
                        100.792485,
                        13.720788
                    ],
                    [
                        100.792483,
                        13.720788
                    ],
                    [
                        100.792485,
                        13.720786
                    ],
                    [
                        100.792486,
                        13.720786
                    ],
                    [
                        100.792486,
                        13.720788
                    ],
                    [
                        100.792486,
                        13.72079
                    ],
                    [
                        100.792498,
                        13.720786
                    ],
                    [
                        100.792531,
                        13.720778
                    ],
                    [
                        100.792561,
                        13.720768
                    ],
                    [
                        100.792548,
                        13.720805
                    ],
                    [
                        100.792548,
                        13.720841
                    ],
                    [
                        100.792553,
                        13.720863
                    ],
                    [
                        100.792561,
                        13.720966
                    ],
                    [
                        100.792558,
                        13.720981
                    ],
                    [
                        100.792535,
                        13.721015
                    ],
                    [
                        100.792466,
                        13.721043
                    ],
                    [
                        100.79207,
                        13.72118
                    ],
                    [
                        100.791635,
                        13.72135
                    ],
                    [
                        100.791305,
                        13.721456
                    ],
                    [
                        100.790895,
                        13.7216
                    ],
                    [
                        100.790381,
                        13.721751
                    ],
                    [
                        100.789956,
                        13.721845
                    ],
                    [
                        100.789698,
                        13.721895
                    ],
                    [
                        100.789645,
                        13.721895
                    ],
                    [
                        100.789631,
                        13.72189
                    ],
                    [
                        100.789621,
                        13.72189
                    ],
                    [
                        100.789613,
                        13.721891
                    ],
                    [
                        100.789606,
                        13.721893
                    ],
                    [
                        100.789603,
                        13.721895
                    ],
                    [
                        100.7896,
                        13.721895
                    ],
                    [
                        100.789558,
                        13.7219
                    ],
                    [
                        100.789451,
                        13.721918
                    ],
                    [
                        100.78929,
                        13.721938
                    ],
                    [
                        100.789108,
                        13.721953
                    ],
                    [
                        100.789045,
                        13.721956
                    ],
                    [
                        100.788973,
                        13.721965
                    ],
                    [
                        100.788818,
                        13.721971
                    ],
                    [
                        100.788678,
                        13.721973
                    ],
                    [
                        100.788548,
                        13.721976
                    ],
                    [
                        100.788453,
                        13.721976
                    ],
                    [
                        100.788296,
                        13.721971
                    ],
                    [
                        100.788158,
                        13.72197
                    ],
                    [
                        100.788066,
                        13.721968
                    ],
                    [
                        100.787873,
                        13.72196
                    ],
                    [
                        100.787723,
                        13.721955
                    ],
                    [
                        100.787578,
                        13.721955
                    ],
                    [
                        100.787435,
                        13.721956
                    ],
                    [
                        100.787333,
                        13.721955
                    ],
                    [
                        100.787231,
                        13.721958
                    ],
                    [
                        100.787208,
                        13.721958
                    ],
                    [
                        100.7872,
                        13.721958
                    ],
                    [
                        100.787198,
                        13.721956
                    ],
                    [
                        100.787196,
                        13.721958
                    ],
                    [
                        100.787191,
                        13.721958
                    ],
                    [
                        100.787188,
                        13.721958
                    ],
                    [
                        100.787185,
                        13.721958
                    ],
                    [
                        100.787185,
                        13.72196
                    ],
                    [
                        100.787183,
                        13.721963
                    ],
                    [
                        100.787183,
                        13.721966
                    ],
                    [
                        100.787183,
                        13.721971
                    ],
                    [
                        100.787181,
                        13.721973
                    ],
                    [
                        100.787178,
                        13.721973
                    ],
                    [
                        100.787173,
                        13.721973
                    ],
                    [
                        100.78717,
                        13.721975
                    ],
                    [
                        100.78717,
                        13.721976
                    ],
                    [
                        100.78717,
                        13.721975
                    ],
                    [
                        100.787171,
                        13.721973
                    ],
                    [
                        100.787173,
                        13.721973
                    ],
                    [
                        100.787175,
                        13.721976
                    ],
                    [
                        100.787176,
                        13.721975
                    ],
                    [
                        100.787178,
                        13.721971
                    ],
                    [
                        100.787181,
                        13.721971
                    ],
                    [
                        100.787183,
                        13.721971
                    ],
                    [
                        100.787183,
                        13.72197
                    ],
                    [
                        100.78718,
                        13.72197
                    ],
                    [
                        100.787175,
                        13.72197
                    ],
                    [
                        100.787168,
                        13.72197
                    ],
                    [
                        100.787161,
                        13.72197
                    ],
                    [
                        100.787156,
                        13.721971
                    ],
                    [
                        100.787108,
                        13.721965
                    ],
                    [
                        100.786858,
                        13.721956
                    ],
                    [
                        100.786678,
                        13.721966
                    ],
                    [
                        100.786655,
                        13.721961
                    ],
                    [
                        100.786478,
                        13.72197
                    ],
                    [
                        100.786391,
                        13.721965
                    ],
                    [
                        100.7863,
                        13.721955
                    ],
                    [
                        100.786206,
                        13.72195
                    ],
                    [
                        100.785913,
                        13.72194
                    ],
                    [
                        100.785601,
                        13.721925
                    ],
                    [
                        100.785533,
                        13.721921
                    ],
                    [
                        100.785443,
                        13.721916
                    ],
                    [
                        100.785311,
                        13.721915
                    ],
                    [
                        100.785168,
                        13.721913
                    ],
                    [
                        100.785016,
                        13.721903
                    ],
                    [
                        100.784916,
                        13.721896
                    ],
                    [
                        100.784815,
                        13.721883
                    ],
                    [
                        100.784638,
                        13.721896
                    ],
                    [
                        100.7844,
                        13.721895
                    ],
                    [
                        100.783878,
                        13.721881
                    ],
                    [
                        100.783381,
                        13.72188
                    ],
                    [
                        100.782785,
                        13.721863
                    ],
                    [
                        100.782368,
                        13.721868
                    ],
                    [
                        100.78181,
                        13.721825
                    ],
                    [
                        100.781293,
                        13.721796
                    ],
                    [
                        100.78079,
                        13.721778
                    ],
                    [
                        100.780373,
                        13.721773
                    ],
                    [
                        100.779925,
                        13.721781
                    ],
                    [
                        100.779555,
                        13.721776
                    ],
                    [
                        100.779306,
                        13.721765
                    ],
                    [
                        100.779133,
                        13.72175
                    ],
                    [
                        100.779065,
                        13.721746
                    ],
                    [
                        100.779008,
                        13.721743
                    ],
                    [
                        100.778873,
                        13.721741
                    ],
                    [
                        100.778628,
                        13.721738
                    ],
                    [
                        100.778485,
                        13.721733
                    ],
                    [
                        100.778355,
                        13.721731
                    ],
                    [
                        100.778281,
                        13.72173
                    ],
                    [
                        100.778278,
                        13.72173
                    ],
                    [
                        100.778255,
                        13.721728
                    ],
                    [
                        100.778203,
                        13.721725
                    ],
                    [
                        100.778181,
                        13.721723
                    ],
                    [
                        100.778178,
                        13.721723
                    ],
                    [
                        100.778176,
                        13.721723
                    ],
                    [
                        100.778173,
                        13.721723
                    ],
                    [
                        100.77817,
                        13.721723
                    ],
                    [
                        100.778168,
                        13.721725
                    ],
                    [
                        100.778165,
                        13.721726
                    ],
                    [
                        100.778163,
                        13.721726
                    ],
                    [
                        100.778156,
                        13.721726
                    ],
                    [
                        100.778156,
                        13.721723
                    ],
                    [
                        100.778103,
                        13.721718
                    ],
                    [
                        100.77806,
                        13.721716
                    ],
                    [
                        100.778053,
                        13.721718
                    ],
                    [
                        100.778026,
                        13.721718
                    ],
                    [
                        100.777883,
                        13.721725
                    ],
                    [
                        100.77765,
                        13.721721
                    ],
                    [
                        100.77727,
                        13.721711
                    ],
                    [
                        100.776846,
                        13.721691
                    ],
                    [
                        100.77665,
                        13.721661
                    ],
                    [
                        100.776603,
                        13.721626
                    ],
                    [
                        100.77655,
                        13.721518
                    ],
                    [
                        100.776541,
                        13.72103
                    ],
                    [
                        100.776525,
                        13.720698
                    ],
                    [
                        100.776495,
                        13.71939
                    ],
                    [
                        100.776516,
                        13.718746
                    ],
                    [
                        100.776523,
                        13.718191
                    ],
                    [
                        100.776533,
                        13.717521
                    ],
                    [
                        100.77654,
                        13.717303
                    ],
                    [
                        100.776538,
                        13.715168
                    ],
                    [
                        100.776418,
                        13.714468
                    ],
                    [
                        100.77626,
                        13.713853
                    ],
                    [
                        100.776081,
                        13.713153
                    ],
                    [
                        100.77585,
                        13.71226
                    ],
                    [
                        100.775648,
                        13.711525
                    ],
                    [
                        100.775428,
                        13.710675
                    ],
                    [
                        100.775248,
                        13.709983
                    ],
                    [
                        100.775071,
                        13.709295
                    ],
                    [
                        100.774858,
                        13.708458
                    ],
                    [
                        100.774668,
                        13.707751
                    ],
                    [
                        100.774433,
                        13.706885
                    ],
                    [
                        100.774238,
                        13.706148
                    ],
                    [
                        100.774041,
                        13.705401
                    ],
                    [
                        100.773811,
                        13.704505
                    ],
                    [
                        100.773596,
                        13.703738
                    ],
                    [
                        100.773366,
                        13.702946
                    ],
                    [
                        100.773121,
                        13.702026
                    ],
                    [
                        100.7729,
                        13.701183
                    ],
                    [
                        100.772731,
                        13.700528
                    ],
                    [
                        100.772586,
                        13.699975
                    ],
                    [
                        100.77246,
                        13.699513
                    ],
                    [
                        100.772435,
                        13.699416
                    ],
                    [
                        100.772435,
                        13.699415
                    ],
                    [
                        100.772418,
                        13.699355
                    ],
                    [
                        100.772343,
                        13.699248
                    ],
                    [
                        100.772223,
                        13.699228
                    ],
                    [
                        100.772155,
                        13.699241
                    ],
                    [
                        100.771746,
                        13.69935
                    ],
                    [
                        100.771095,
                        13.699516
                    ],
                    [
                        100.770503,
                        13.69966
                    ],
                    [
                        100.769786,
                        13.699838
                    ],
                    [
                        100.769148,
                        13.7
                    ],
                    [
                        100.768515,
                        13.700168
                    ],
                    [
                        100.767886,
                        13.700326
                    ],
                    [
                        100.767481,
                        13.700413
                    ],
                    [
                        100.767315,
                        13.700388
                    ],
                    [
                        100.767275,
                        13.70035
                    ],
                    [
                        100.767226,
                        13.70024
                    ],
                    [
                        100.767101,
                        13.699793
                    ],
                    [
                        100.766968,
                        13.6993
                    ],
                    [
                        100.76677,
                        13.69854
                    ],
                    [
                        100.766576,
                        13.697833
                    ],
                    [
                        100.766321,
                        13.696935
                    ],
                    [
                        100.766128,
                        13.69618
                    ],
                    [
                        100.765923,
                        13.695421
                    ],
                    [
                        100.765686,
                        13.694506
                    ],
                    [
                        100.765483,
                        13.693738
                    ],
                    [
                        100.765278,
                        13.692975
                    ],
                    [
                        100.765086,
                        13.692228
                    ],
                    [
                        100.76481,
                        13.691183
                    ],
                    [
                        100.76461,
                        13.690428
                    ],
                    [
                        100.764415,
                        13.689673
                    ],
                    [
                        100.764178,
                        13.688765
                    ],
                    [
                        100.763976,
                        13.68801
                    ],
                    [
                        100.763743,
                        13.687106
                    ],
                    [
                        100.763541,
                        13.686348
                    ],
                    [
                        100.763305,
                        13.685436
                    ],
                    [
                        100.763096,
                        13.684671
                    ],
                    [
                        100.762901,
                        13.683918
                    ],
                    [
                        100.762663,
                        13.682991
                    ],
                    [
                        100.76246,
                        13.682215
                    ],
                    [
                        100.762258,
                        13.681463
                    ],
                    [
                        100.762018,
                        13.68056
                    ],
                    [
                        100.761825,
                        13.67982
                    ],
                    [
                        100.76158,
                        13.678921
                    ],
                    [
                        100.761346,
                        13.678031
                    ],
                    [
                        100.761161,
                        13.677313
                    ],
                    [
                        100.760975,
                        13.676605
                    ],
                    [
                        100.760746,
                        13.675736
                    ],
                    [
                        100.760551,
                        13.674983
                    ],
                    [
                        100.760308,
                        13.67406
                    ],
                    [
                        100.760101,
                        13.673266
                    ],
                    [
                        100.759895,
                        13.672486
                    ],
                    [
                        100.759656,
                        13.671573
                    ],
                    [
                        100.759458,
                        13.67082
                    ],
                    [
                        100.75922,
                        13.669916
                    ],
                    [
                        100.759025,
                        13.66919
                    ],
                    [
                        100.758841,
                        13.668481
                    ],
                    [
                        100.75862,
                        13.667653
                    ],
                    [
                        100.758446,
                        13.666986
                    ],
                    [
                        100.758243,
                        13.666231
                    ],
                    [
                        100.758028,
                        13.665518
                    ],
                    [
                        100.757761,
                        13.664481
                    ],
                    [
                        100.757543,
                        13.66357
                    ],
                    [
                        100.757286,
                        13.662563
                    ],
                    [
                        100.75708,
                        13.661786
                    ],
                    [
                        100.756883,
                        13.66102
                    ],
                    [
                        100.756626,
                        13.660071
                    ],
                    [
                        100.756418,
                        13.65928
                    ],
                    [
                        100.75617,
                        13.658326
                    ],
                    [
                        100.755955,
                        13.657526
                    ],
                    [
                        100.75574,
                        13.656718
                    ],
                    [
                        100.75549,
                        13.655781
                    ],
                    [
                        100.755291,
                        13.655021
                    ],
                    [
                        100.755015,
                        13.65412
                    ],
                    [
                        100.754636,
                        13.653406
                    ],
                    [
                        100.754146,
                        13.65255
                    ],
                    [
                        100.753745,
                        13.65184
                    ],
                    [
                        100.753351,
                        13.651131
                    ],
                    [
                        100.752986,
                        13.65061
                    ],
                    [
                        100.752756,
                        13.650391
                    ],
                    [
                        100.752353,
                        13.650131
                    ],
                    [
                        100.751896,
                        13.649956
                    ],
                    [
                        100.751576,
                        13.649895
                    ],
                    [
                        100.751081,
                        13.649836
                    ],
                    [
                        100.750103,
                        13.649708
                    ],
                    [
                        100.749295,
                        13.649606
                    ],
                    [
                        100.74833,
                        13.649483
                    ],
                    [
                        100.747588,
                        13.649298
                    ],
                    [
                        100.747323,
                        13.649156
                    ],
                    [
                        100.746858,
                        13.648785
                    ],
                    [
                        100.746761,
                        13.648671
                    ],
                    [
                        100.746406,
                        13.648036
                    ],
                    [
                        100.746106,
                        13.647355
                    ],
                    [
                        100.745795,
                        13.646651
                    ],
                    [
                        100.745395,
                        13.64576
                    ],
                    [
                        100.745046,
                        13.644988
                    ],
                    [
                        100.74465,
                        13.644061
                    ],
                    [
                        100.74435,
                        13.643286
                    ],
                    [
                        100.744295,
                        13.64313
                    ],
                    [
                        100.743996,
                        13.64235
                    ],
                    [
                        100.74367,
                        13.64156
                    ],
                    [
                        100.743321,
                        13.640785
                    ],
                    [
                        100.742901,
                        13.63986
                    ],
                    [
                        100.742486,
                        13.63892
                    ],
                    [
                        100.742136,
                        13.638131
                    ],
                    [
                        100.741721,
                        13.637193
                    ],
                    [
                        100.741366,
                        13.636406
                    ],
                    [
                        100.741016,
                        13.635615
                    ],
                    [
                        100.740585,
                        13.634648
                    ],
                    [
                        100.740235,
                        13.633838
                    ],
                    [
                        100.739886,
                        13.633053
                    ],
                    [
                        100.7395,
                        13.632115
                    ],
                    [
                        100.739216,
                        13.631315
                    ],
                    [
                        100.738866,
                        13.630368
                    ],
                    [
                        100.738535,
                        13.629596
                    ],
                    [
                        100.738126,
                        13.628638
                    ],
                    [
                        100.737831,
                        13.627868
                    ],
                    [
                        100.73746,
                        13.626996
                    ],
                    [
                        100.737158,
                        13.62628
                    ],
                    [
                        100.736886,
                        13.62564
                    ],
                    [
                        100.73655,
                        13.624903
                    ],
                    [
                        100.73628,
                        13.62428
                    ],
                    [
                        100.736016,
                        13.623685
                    ],
                    [
                        100.73574,
                        13.62305
                    ],
                    [
                        100.73563,
                        13.62283
                    ],
                    [
                        100.735458,
                        13.622755
                    ],
                    [
                        100.73534,
                        13.622781
                    ],
                    [
                        100.735156,
                        13.622861
                    ],
                    [
                        100.734978,
                        13.622953
                    ],
                    [
                        100.734901,
                        13.62306
                    ],
                    [
                        100.734888,
                        13.623135
                    ],
                    [
                        100.734905,
                        13.62321
                    ],
                    [
                        100.735111,
                        13.623593
                    ],
                    [
                        100.735378,
                        13.624251
                    ],
                    [
                        100.735651,
                        13.624936
                    ],
                    [
                        100.735955,
                        13.625741
                    ],
                    [
                        100.736318,
                        13.626663
                    ],
                    [
                        100.736655,
                        13.62746
                    ],
                    [
                        100.7369,
                        13.628025
                    ],
                    [
                        100.737161,
                        13.62863
                    ],
                    [
                        100.7374,
                        13.629165
                    ],
                    [
                        100.737745,
                        13.629823
                    ],
                    [
                        100.73804,
                        13.630333
                    ],
                    [
                        100.738333,
                        13.63089
                    ],
                    [
                        100.738636,
                        13.631566
                    ],
                    [
                        100.738853,
                        13.632068
                    ],
                    [
                        100.738935,
                        13.632358
                    ],
                    [
                        100.738915,
                        13.632383
                    ],
                    [
                        100.738888,
                        13.632398
                    ],
                    [
                        100.738791,
                        13.632433
                    ],
                    [
                        100.738753,
                        13.632468
                    ],
                    [
                        100.738748,
                        13.632471
                    ],
                    [
                        100.738743,
                        13.632475
                    ],
                    [
                        100.73874,
                        13.632476
                    ],
                    [
                        100.738738,
                        13.63248
                    ],
                    [
                        100.738735,
                        13.63248
                    ],
                    [
                        100.738731,
                        13.632481
                    ],
                    [
                        100.738726,
                        13.632483
                    ],
                    [
                        100.738723,
                        13.632485
                    ],
                    [
                        100.738695,
                        13.632518
                    ],
                    [
                        100.73868,
                        13.632538
                    ],
                    [
                        100.73853,
                        13.63267
                    ],
                    [
                        100.738293,
                        13.63283
                    ],
                    [
                        100.738228,
                        13.632873
                    ],
                    [
                        100.73811,
                        13.632936
                    ],
                    [
                        100.73803,
                        13.633005
                    ],
                    [
                        100.737981,
                        13.633053
                    ],
                    [
                        100.737831,
                        13.633168
                    ],
                    [
                        100.737785,
                        13.633211
                    ],
                    [
                        100.737783,
                        13.633235
                    ],
                    [
                        100.737775,
                        13.633238
                    ],
                    [
                        100.737776,
                        13.633215
                    ],
                    [
                        100.737801,
                        13.633128
                    ],
                    [
                        100.737798,
                        13.633111
                    ],
                    [
                        100.73779,
                        13.63308
                    ],
                    [
                        100.73779,
                        13.633073
                    ],
                    [
                        100.737791,
                        13.63307
                    ],
                    [
                        100.737793,
                        13.633066
                    ],
                    [
                        100.737796,
                        13.633065
                    ],
                    [
                        100.737798,
                        13.633071
                    ],
                    [
                        100.737798,
                        13.633073
                    ],
                    [
                        100.737796,
                        13.633075
                    ],
                    [
                        100.737793,
                        13.633076
                    ],
                    [
                        100.737791,
                        13.633075
                    ],
                    [
                        100.737793,
                        13.633075
                    ],
                    [
                        100.737795,
                        13.63308
                    ],
                    [
                        100.737795,
                        13.633085
                    ],
                    [
                        100.737793,
                        13.633085
                    ],
                    [
                        100.737795,
                        13.633083
                    ],
                    [
                        100.737796,
                        13.633086
                    ],
                    [
                        100.737798,
                        13.633086
                    ],
                    [
                        100.737803,
                        13.633086
                    ],
                    [
                        100.73781,
                        13.633088
                    ],
                    [
                        100.737816,
                        13.633088
                    ],
                    [
                        100.737823,
                        13.63309
                    ],
                    [
                        100.737836,
                        13.633086
                    ],
                    [
                        100.73784,
                        13.633085
                    ],
                    [
                        100.73784,
                        13.633083
                    ],
                    [
                        100.737841,
                        13.633083
                    ],
                    [
                        100.737843,
                        13.633083
                    ],
                    [
                        100.737841,
                        13.633085
                    ],
                    [
                        100.737823,
                        13.633085
                    ],
                    [
                        100.737816,
                        13.633095
                    ],
                    [
                        100.737805,
                        13.633095
                    ],
                    [
                        100.737803,
                        13.633098
                    ],
                    [
                        100.737801,
                        13.633096
                    ]
                ],
                "type": "LineString"
            },
            "id": 4
        }
    ]
}
import React, { Fragment, useEffect, useState } from 'react'
import { Dialog, DialogTitle, Stack, DialogContent, DialogActions, IconButton, Button, Grid, TextField, Box, MenuItem, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import MobileScreenShareOutlinedIcon from '@mui/icons-material/MobileScreenShareOutlined';
import { Api } from '../../components/api';
import { useSnackbar } from 'notistack';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DeviceshareTouserid from './DeviceshareTouserid';

export default function Deviceshare({ device, account }) {

    const [open, setOpen] = useState(false)
    const [devices, setDevices] = useState([])



    useEffect(() => {
        if (open) {
            Api.get(`/v0/gps/deviceshare?device=${device.device}`)
                .then(res => {
                    setDevices(res.data)
                })
                .catch(error => {
                    console.log('error', error)
                })
        }
    }, [open])

    const getDevices = () => {
        Api.get(`/v0/gps/deviceshare?device=${device.device}`)
            .then(res => {
                setDevices(res.data)
            })
            .catch(error => {
                console.log('error', error)
            })
    }


    const onClickOpen = () => {
        setOpen(true)
    }
    const onClickClose = () => {
        setOpen(false)
    }
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleSnackbar = ({ message, variant }) => {
        enqueueSnackbar(message,
            {
                autoHideDuration: 1000,
                anchorOrigin: {
                    horizontal: "right",
                    vertical: 'bottom'
                },
                variant: variant
            });
    }

    const handleSnackWithAction = ({ message, onHandle }) => {
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => (
                <Fragment>
                    <Stack direction="row" spacing={1}>
                        <Button
                            size='small'
                            color="error"
                            variant="contained"
                            onClick={() => {
                                closeSnackbar(key)
                                onHandle()
                            }}
                        >
                            <CheckRoundedIcon fontSize="small" />
                        </Button>
                        <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                            <CloseRoundedIcon fontSize="small" />
                        </Button>
                    </Stack>
                </Fragment>
            )
        });
    };
    const handleDel = (item) => {
        var data = {
            ...item
        }
        Api.delete('/v0/gps/deviceshare', { data: data })
            .then(res => {
                handleSnackbar({ message: "ลบข้อมูลสำเร็จ", variant: "success" })
                getDevices();
            })
            .catch(error => {
                const { message } = error
                handleSnackbar({ variant: 'error', message: "ลบข้อมูลไม่สำเร็จ" });
            })
    };


    return (
        <div>
            <IconButton size="small" onClick={onClickOpen}>
                <MobileScreenShareOutlinedIcon fontSize="small" />
            </IconButton>
            <Dialog
                open={open}
                maxWidth="md"
                fullWidth={true}
                onClose={onClickClose}
                slotProps={{
                    backdrop: {
                        style: {
                            background: "#a7d8de",
                            opacity: 0.2
                        }
                    }
                }}
            >
                <DialogTitle>แชร์อุปกรณ์ {device?.devicename}</DialogTitle>
                <DialogContent dividers>
                    <Box>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>userid</TableCell>
                                    <TableCell sx={{ justifyContent: "end", display: "flex" }} >
                                        <DeviceshareTouserid account={account} device={device} getDevices={getDevices} />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    devices.map((deviceRow, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>{deviceRow.device}</TableCell>
                                                <TableCell sx={{ justifyContent: "end", display: "flex" }}><IconButton size="small" onClick={() => { handleSnackWithAction({ message: "ต้องการที่จะลบ?", onHandle: () => { handleDel(deviceRow) } }) }}><DeleteOutlinedIcon fontSize="small" /></IconButton></TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClickClose}>ยกเลิก</Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}

import styled from '@emotion/styled';
import { LoadingButton, LocalizationProvider, MobileDateTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, Container, Divider, Grid, TextField, Typography, Autocomplete, Button, TableContainer, Table, TableHead, TableCell, TableBody, TableRow, TablePagination, IconButton, Dialog, DialogContent, useMediaQuery } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import Page from '../../components/Page'
import * as Yup from 'yup';
import { Api } from '../../components/api';
import { fDateTo } from '../../utils/formatTime';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import TreeViewGroup from './TreeViewGroup';
import { useReactToPrint } from 'react-to-print';
import HasPermission from '../../haspermission/HasPermission';

const listTableHead = [
    {
        title: 'ทะเบียนรถ',
    },
    {
        title: 'หมายเลขรถ',
    },
    {
        title: 'เวลาเข้า',
    },
    {
        title: 'เวลาออก',
    },
    {
        title: 'เวลารวม',
    },
    {
        title: 'มาตรวัดระยะทาง (Km)',
    },
    {
        title: 'ชื่อสถานี',
    },
    {
        title: 'จังหวัด',
    }
]

export default function Offlinedevice({ account, userId, permissions }) {
    const navigate = useNavigate()
    const [devices, setDevices] = useState([])
    const [poiLogs, setPoiLogs] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);



    useEffect(() => {

    }, [userId])

    const schema = Yup.object().shape({

    });
    const selectedTime = new Date();
    const formik = useFormik({
        initialValues: {
            deviceid: null,
            // speed: 60,
            date_s: selectedTime.setHours(0, 0, 0),
            date_e: selectedTime.setHours(23, 59, 59),
        },
        validationSchema: schema,
        onSubmit: (values, { setSubmitting }) => {
            const { deviceid, date_s, date_e,
                // speed
            } = values
            const { cocode } = account;
            const req = {
                // cocode: cocode,
                userid: userId,
                list: checked,
                dateStart: fDateTo(date_s, 'yyyy-MM-dd HH:mm:ss'),
                dateEnd: fDateTo(date_e, 'yyyy-MM-dd HH:mm:ss')
            }
            Api.post('/Tracking/PoiLogByDevices', req).then((res) => {
                const { data } = res
                setPoiLogs(data)
                setPage(0)
                setSubmitting()
            }).catch((e) => {
                setPoiLogs([])
                setPage(0)
                setSubmitting()
            })
        }
    })


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;
    const [checked, setChecked] = useState([])

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <Page>
            <Container>
                <Box flexGrow={1} padding={1}>
                    <Typography variant="h4">Event : Offline device</Typography>
                </Box>
                <Box>
                    <HasPermission permissions={permissions} scope={["print"]}>
                        <Button onClick={handlePrint}>พิมพ์</Button>
                    </HasPermission>
                </Box>
            </Container>
            <Divider />
            <MainStyle>
                <Container sx={{
                    padding: 3,
                    // justifyContent: "center", display: "flex"
                }} >
                    <Box ref={componentRef} className="section-to-print">
                        <Box sx={{
                            marginLeft: "0.5cm", marginRight: "0.5cm",
                            // width: "21cm"
                        }}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={200}>ทะเบียนรถ</TableCell>
                                            <TableCell width={200}>เลขที่อุปกรณ์</TableCell>
                                            <TableCell width={200}>ระยะเวลา</TableCell>
                                            <TableCell width={200}>สถานะล่าสุด</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            [...Array(150)].map((value, index) => {
                                                return (
                                                    <TableRow sx={{ padding: "10px" }} key={index}>
                                                        <TableCell>32-1235</TableCell>
                                                        <TableCell>001100203</TableCell>
                                                        <TableCell>3วัน</TableCell>
                                                        <TableCell>ไม่มีสัญญาณ</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Container>
            </MainStyle>
        </Page >
    )
}

const MainStyle = styled(Box)(({ theme }) => ({
    margin: 'auto',
    overflow: 'auto',
    paddingTop: 10,
    paddingBottom: 60,
    paddingLeft: 10,
    paddingRight: 10
}));

// const MemoizedTreeView = React.memo(TreeViewGroup)
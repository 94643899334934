import React, { useState } from 'react'
import { Stack, IconButton, TextField, Switch, Divider, Typography, Dialog, DialogContent, DialogActions, DialogTitle, Box, Grid, Button, Backdrop, FormControlLabel, Checkbox, Table, TableHead, TableRow, TableBody, TableCell } from '@mui/material'
import axios from 'axios'
import { Api } from '../../components/api'
import CloseIcon from '@mui/icons-material/Close';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import { fDateTo } from '../../utils/formatTime';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

export default function AddDeviceCerDlt() {
    const [open, setOpen] = useState(false)
    const [dltCer, setDltCer] = useState(
        {
            "cogcode": "SIT",
            "cocode": "SIT",
            "docno": "",
            "vender_name": "บริษัท เอสไอที คอนซัลแตนท์ จำกัด",
            "vender_add": "234 ถนนประดิษฐ์มนูธรรม แขวงนวลจันทร์ เขตบึงกุ่ม กรุงเทพมหานคร 10230",
            "vender_tel": "02-7669500 , 0816895725",
            "unit_id": "",
            "gpsmodel": "FIFOTRACK",
            "gpstype": "A500",
            "gpslicense": "",
            "mcrmodel": "OCOM",
            "mcrtype": "CR1300",
            "installdate": fDateTo(new Date(), "yyyy-MM-dd"),
            "customer_name": "บริษัท เฟิสท์ทรานสปอร์ต จำกัด",
            "customer_add": "998/99 ถนนหลวงแพ่ง แขวงทับยาว เขตลาดกระบัง กรุงเทพฯ 10520",
            "customer_tel": "0-2001-1820",
            "vehicle_id": "32-9173",
            "vehicle_province": " กรุงเทพมหานคร ",
            "vehicle_chassis_no": "LFZBJCNK8DAS09768",
            "vehicle_type": "SUNLONG",
            "issuedate": fDateTo(new Date(), "yyyy-MM-dd"),
            "issueby": "คุณสุพจน์ ทักษ์ประดิษฐ์",
            "status": null,
            "revokedate": null,
            "dltdate": null
        }
    )

    const onClickOpen = () => {
        setOpen(true)
    }
    const onClickClose = () => {
        setOpen(false)
    }

    const onChangeDate = (value, name) => {
        if (String(value) === "Invalid Date") {
            setDltCer({ ...dltCer, [name]: value })
            // setFieldValue(name, value)
        } else {
            setDltCer({ ...dltCer, [name]: fDateTo(value, "yyyy-MM-dd") })
            // setFieldValue(name, fDateTo(value, "yyyy-MM-dd"))
        }
    }
    return (
        <div>
            <IconButton onClick={onClickOpen} size="small" ><PostAddOutlinedIcon fontSize="small" /></IconButton>
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={open}
                onClose={onClickClose}
            >
                <DialogTitle>
                    <Stack spacing={1} direction="row">
                        <Typography variant="h6">ข้อมูลใบรับรอง</Typography>
                    </Stack>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClickClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box padding={"10px"}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Box>
                                <Box marginBottom={"10px"}>
                                    <Typography>ข้อมูลการติดตั้ง</Typography>
                                </Box>
                                <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
                                <Grid container spacing={1}>
                                    <Grid item md={3}>เลขที่หนังสือ</Grid>
                                    <Grid item md={9}><TextField value={dltCer.docno} fullWidth size="small" /></Grid>
                                    <Grid item md={3}>ชื่อผู้ให้บริการ</Grid>
                                    <Grid item md={9}><TextField value={dltCer.vender_name} fullWidth size="small" /></Grid>
                                    <Grid item md={3}>ที่อยู่</Grid>
                                    <Grid item md={9}><TextField value={dltCer.vender_add} multiline rows={3} fullWidth size="small" /></Grid>
                                    <Grid item md={3}>เบอร์โทรศัพท์</Grid>
                                    <Grid item md={9}><TextField value={dltCer.vender_tel} fullWidth size="small" /></Grid>
                                    <Grid item md={3}>วันที่ติดตั้ง</Grid>
                                    <Grid item md={9}>
                                        <DatePicker
                                            onChange={(e) => { onChangeDate(e, "installdate") }}
                                            value={dltCer.installdate}
                                            inputFormat="dd/MM/yyyy"
                                            format="yyy/MM/dd"
                                            renderInput={(params) => <TextField size="small" placeholder="DD/MM/YYYY"  {...params} />}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Divider sx={{ marginTop: "10px" }} />
                            <Box marginTop={"10px"} marginBottom={"10px"}>
                                <Typography>เครื่องบันทึกข้อมูลการเดินทาง</Typography>
                            </Box>
                            <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
                            <Grid container spacing={1}>
                                <Grid item md={3}>เลขที่กรมการขนส่งทางบก</Grid>
                                <Grid item md={3}><TextField value={dltCer.gpslicense} fullWidth size="small" /></Grid>
                                <Grid item md={2}>หมายเลขเครื่อง</Grid>
                                <Grid item md={4}><TextField value={dltCer.unit_id} fullWidth size="small" /></Grid>

                                <Grid item md={3}>ชนิด</Grid>
                                <Grid item md={3}><TextField value={dltCer.gpsmodel} fullWidth size="small" /></Grid>
                                <Grid item md={2}>แบบ</Grid>
                                <Grid item md={4}><TextField value={dltCer.gpstype} fullWidth size="small" /></Grid>

                            </Grid>
                            <Box>
                                <Divider sx={{ marginTop: "10px" }} />
                                <Box marginTop={"10px"} marginBottom={"10px"}>
                                    <Typography>เครื่องอ่านบัตรชนิดแถบแม่เหล็ก</Typography>
                                </Box>
                                <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
                                <Grid container spacing={1}>

                                    <Grid item md={3}>ชนิด</Grid>
                                    <Grid item md={3}><TextField value={dltCer.mcrmodel} fullWidth size="small" /></Grid>
                                    <Grid item md={2}>แบบ</Grid>
                                    <Grid item md={4}><TextField value={dltCer.mcrtype} fullWidth size="small" /></Grid>
                                </Grid>
                            </Box>
                            <Box>
                                <Divider sx={{ marginTop: "10px" }} />
                                <Box marginTop={"10px"} marginBottom={"10px"}>
                                    <Typography>ข้อมูลผู้ประกอบการขนส่ง</Typography>
                                </Box>
                                <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
                                <Grid container spacing={1}>
                                    <Grid item md={3}>ชื่อ</Grid>
                                    <Grid item md={9}><TextField value={dltCer.customer_name} fullWidth size="small" /></Grid>
                                    <Grid item md={3}>ที่อยู่</Grid>
                                    <Grid item md={9}><TextField value={dltCer.customer_add} multiline rows={3} fullWidth size="small" /></Grid>
                                    <Grid item md={3}>เบอร์โทร</Grid>
                                    <Grid item md={9}><TextField value={dltCer.customer_tel} fullWidth size="small" /></Grid>

                                    <Grid item md={3}>เลขทะเบียน</Grid>
                                    <Grid item md={3}><TextField value={dltCer.vehicle_id} fullWidth size="small" /></Grid>
                                    <Grid item md={2}>จังหวัด</Grid>
                                    <Grid item md={4}><TextField value={dltCer.vehicle_province} fullWidth size="small" /></Grid>

                                    <Grid item md={3}>ยี่ห้อ</Grid>
                                    <Grid item md={3}><TextField value={dltCer.vehicle_type} fullWidth size="small" /></Grid>
                                    <Grid item md={2}>หมายเลขคัสซี</Grid>
                                    <Grid item md={4}><TextField value={dltCer.vehicle_chassis_no} fullWidth size="small" /></Grid>

                                    <Grid item md={3}>ออกให้ ณ วันที่</Grid>
                                    <Grid item md={3}>
                                        <DatePicker
                                            onChange={(e) => { onChangeDate(e, "installdate") }}
                                            value={dltCer.issuedate}
                                            inputFormat="dd/MM/yyyy"
                                            format="yyy/MM/dd"
                                            renderInput={(params) => <TextField size="small" placeholder="DD/MM/YYYY"  {...params} />}
                                        />
                                    </Grid>
                                    <Grid item md={2}>ผู้ลงชื่อ</Grid>
                                    <Grid item md={4}><TextField value={dltCer.issueby} fullWidth size="small" /></Grid>

                                    <Grid item md={3}>สถานะ</Grid>
                                    <Grid item md={9}>
                                        <Switch inputProps={{ 'aria-label': 'สถานะ' }} />
                                    </Grid>
                                </Grid>
                            </Box>
                            {/* <Grid container spacing={1}>
                            <Grid item md={6}><TextField label="เลขที่หนังสือ" value={dltCer.docno} fullWidth size="small" /></Grid>
                            <Grid item md={6}><TextField label="ชื่อผู้ให้บริการ" value={dltCer.vender_name} fullWidth size="small" /></Grid>
                            <Grid item md={6}><TextField label="ที่อยู่" value={dltCer.vender_add} fullWidth size="small" /></Grid>
                            <Grid item md={6}><TextField label="เบอร์โทรศัพท์" value={dltCer.vender_tel} fullWidth size="small" /></Grid>
                            <Grid item md={6}><TextField label="หมายเลขเครื่อง" value={dltCer.unit_id} fullWidth size="small" /></Grid>
                            <Grid item md={6}><TextField label="เครื่องบันทึกข้อมูลการเดินทาง ชนิด" value={dltCer.gpsmodel} fullWidth size="small" /></Grid>
                            <Grid item md={6}><TextField label="แบบ" value={dltCer.gpstype} fullWidth size="small" /></Grid>
                            <Grid item md={6}><TextField label="การรับรองจากกรมการขนส่งทางบกเลขที่" value={dltCer.gpslicense} fullWidth size="small" /></Grid>
                            <Grid item md={6}><TextField label="เครื่องอ่านบัตรชนิดแถบแม่เหล็ก ชนิด" value={dltCer.mcrmodel} fullWidth size="small" /></Grid>
                            <Grid item md={6}><TextField label="แบบ" value={dltCer.mcrtype} fullWidth size="small" /></Grid>
                            <Grid item md={6}><TextField label="วันที่ติดตั้ง" value={dltCer.installdate} fullWidth size="small" /></Grid>
                            <Grid item md={6}><TextField label="ชื่อผู้ประกอบการขนส่ง / เจ้าของรถ" value={dltCer.customer_name} fullWidth size="small" /></Grid>
                            <Grid item md={6}><TextField label="ที่อยู่" value={dltCer.customer_add} fullWidth size="small" /></Grid>
                            <Grid item md={6}><TextField label="เบอร์โทร" value={dltCer.customer_tel} fullWidth size="small" /></Grid>
                            <Grid item md={6}><TextField label="เลขทะเบียน" value={dltCer.vehicle_id} fullWidth size="small" /></Grid>
                            <Grid item md={6}><TextField label="จังหวัด" value={dltCer.vehicle_province} fullWidth size="small" /></Grid>
                            <Grid item md={6}><TextField label="หมายเลขคัสซี" value={dltCer.vehicle_chassis_no} fullWidth size="small" /></Grid>
                            <Grid item md={6}><TextField label="ยี่ห้อ" value={dltCer.vehicle_type} fullWidth size="small" /></Grid>
                            <Grid item md={6}><TextField label="ออกให้ ณ วันที่" value={dltCer.issuedate} fullWidth size="small" /></Grid>
                            <Grid item md={6}><TextField label="ผู้ลงชื่อ" value={dltCer.issueby} fullWidth size="small" /></Grid>
                            <Grid item md={6}>
                                <Switch inputProps={{ 'aria-label': 'สถานะ' }} />
                            </Grid>
                        </Grid> */}
                        </LocalizationProvider>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button>บันทึก</Button>
                </DialogActions>
            </Dialog >

        </div >
    )
}

import React, { Fragment, useEffect, useState } from 'react'
import { Dialog, DialogTitle, Stack, DialogContent, DialogActions, IconButton, Button, Grid, TextField, Box, MenuItem, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import MobileScreenShareOutlinedIcon from '@mui/icons-material/MobileScreenShareOutlined';
import { Api } from '../../components/api';
import { useSnackbar } from 'notistack';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';

export default function DeviceshareTouserid({ device, account, getDevices }) {

    const [open, setOpen] = useState(false)
    const [accounts, setAccounts] = useState([])
    const [search, setSearch] = useState('')
    const [isContype, setIsContype] = useState('21')
    const [contypes, setContypes] = useState([])
    const [groups, setGroups] = useState([])



    useEffect(() => {
        if (open) {
            const { cocode, cogcode, c_coname, contype } = account;
            Api.get(`v0/if/ContactByContype?contype=${isContype}&cname=${c_coname}`)
                .then(res => {
                    var listAccounts = res.data;
                    setAccounts(listAccounts);
                })
                .catch(e => {
                    console.log('error', e)
                })
        }
    }, [open, isContype])

    const getContypes = () => {
        const { cocode, cogcode, c_coname } = account;
        Api.get(`/v0/if/Contypes`, { headers: { CompanyGroup: cogcode } })
            .then((res) => {
                setContypes(res.data)
            })
    }

    const onClickOpen = () => {
        getContypes()
        setOpen(true)
    }
    const onClickClose = () => {
        setOpen(false)
    }
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleSnackbar = ({ message, variant }) => {
        enqueueSnackbar(message,
            {
                autoHideDuration: 1000,
                anchorOrigin: {
                    horizontal: "right",
                    vertical: 'bottom'
                },
                variant: variant
            });
    }

    const handleSnackWithAction = ({ message, onHandle }) => {
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => (
                <Fragment>
                    <Stack direction="row" spacing={1}>
                        <Button
                            size='small'
                            color="error"
                            variant="contained"
                            onClick={() => {
                                closeSnackbar(key)
                                onHandle()
                            }}
                        >
                            <CheckRoundedIcon fontSize="small" />
                        </Button>
                        <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                            <CloseRoundedIcon fontSize="small" />
                        </Button>
                    </Stack>
                </Fragment>
            )
        });
    };

    const onHandleGetUserId = (id) => {
        Api.get(`/v0/app/GetUserID?id=${id}`)
            .then((res) => {
                // userid
                const data = res.data
                const datapost = JSON.stringify({ device: data.userid, name: device.devicename, device_share: device.device })
                Api.post('/v0/gps/deviceshare', datapost)
                    .then(res => {
                        getDevices()
                        handleSnackbar({ message: "เพิ่มข้อมูลสำเร็จ", variant: "success" })
                        onClickClose()
                    })
                    .catch(error => {
                        const { message } = error
                        handleSnackbar({ variant: 'error', message: "เพิ่มข้อมูลไม่สำเร็จ / ข้อมูลซ้ำ" });
                    })
            })
            .catch(err => {
                handleSnackbar({ message: "ไม่พบ บัญชีเข้าสู่ระบบ", variant: "error" })
            })
    }

    return (
        <div>
            <IconButton size="small" onClick={onClickOpen}>
                <AddBoxOutlinedIcon fontSize="small" />
            </IconButton>
            <Dialog
                open={open}
                maxWidth="md"
                fullWidth={true}
                onClose={onClickClose}
                slotProps={{
                    backdrop: {
                        style: {
                            background: "#a7d8de",
                            opacity: 0.2
                        }
                    }
                }}
            >
                <DialogTitle>
                    <Stack direction="row" spacing={1}>
                        {/* {
                            (account?.contype === "00" || account?.contype === "10") && ( */}
                        <TextField size="small" value={isContype} onChange={(e) => { setIsContype(e.target.value) }} select >
                            {
                                contypes.map((value, index) => {
                                    return (
                                        <MenuItem key={`contypes${index}`} value={value.contype} >{value.contypename}</MenuItem>
                                    )
                                })
                            }
                        </TextField>
                        {/* )
                        } */}
                        <TextField size="small" placeholder="ค้นหารายชื่อผู้ใช้งาน" value={search} onChange={(e) => { setSearch(e.target.value) }} />
                    </Stack>
                </DialogTitle>
                <DialogContent dividers>
                    <Box>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ชื่อ</TableCell>
                                    <TableCell>นามสกุล</TableCell>
                                    <TableCell>email</TableCell>
                                    <TableCell>

                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    accounts.filter(v => v.firstname.toUpperCase().includes(search.toUpperCase())).map((accRow, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>{accRow.firstname}</TableCell>
                                                <TableCell>{accRow.lastname}</TableCell>
                                                <TableCell>{accRow.email}</TableCell>
                                                <TableCell sx={{ justifyContent: "end", display: "flex" }}>
                                                    <IconButton size="small" onClick={() => { onHandleGetUserId(accRow.id) }}>
                                                        <AddBoxRoundedIcon fontSize="small" />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClickClose}>ยกเลิก</Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}

import React, { useState } from 'react'
import { Box, Collapse, TextField, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useSnackbar } from 'notistack';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';

import { Api } from '../../../components/api';

export default function ButtonDialogCreateCustomer({ disabled, onHandle, dataAccount, car_type, employees, onHandleGet }) {
    const formint = {
        "cogcode": dataAccount?.cogcode,
        "cocode": dataAccount?.cocode,
        "contype": "31",
        "concode": "0",
        "prefixid": "",
        "firstname": "",
        "lastname": "",
        "sex": "M",
        "PassportID": "",
        "ThaiID": "",
        "membercard": "",
        "phone": "",
        "status": "U",
        "birthday": null,
        "email": "",
        "c_coname": dataAccount?.c_coname,
        "c_cobcode": dataAccount?.c_cobcode,
        "c_cobname": dataAccount?.c_cobname,
        "taxid": "",
        "positionid": ""
    }

    const [form, setForm] = useState(formint)
    const [formUser, setFormUser] = useState({
        "userid": "",
        "password": ""
    })
    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
        setForm(formint)
    }
    const handleSave = (event) => {
        event.preventDefault();
        onGetCname()
    }


    const [openUserId, setOpenUserId] = useState(false)

    const handleOpenUserId = () => {
        setOpenUserId(true)
    }
    const handleCloseUserId = () => {
        setOpenUserId(false)
    }
    const handleSaveUserId = (event) => {
        event.preventDefault();
        createUserID()
    }


    const onGetCname = () => {
        const { cogcode, cocode } = dataAccount
        Api.get(`v0/if/Cname?cname=${form.c_coname}`, {
            headers: {
                CompanyGroup: cogcode
            }
        })
            .then(res => {
                createContact()
            })
            .catch(err => {
                if (err.response.status === 404) {
                    CreateCname()
                    return
                }
                console.log('err', err)
            })
    }


    const CreateCname = () => {
        createContact()
    }


    const createContact = () => {
        const { cogcode, cocode } = dataAccount
        const data = JSON.stringify({
            ...form,
            cogcode: cogcode,
            cocode: cocode
        });
        Api.post(`/v0/if/Contact`, data, { headers: { CompanyGroup: cogcode } })
            .then((res) => {
                const { id } = res.data
                setFormUser(f => {
                    return {
                        ...f,
                        id: id
                    }
                })
                onHandleGet()
                handleClose()
                handleOpenUserId()
                handleClickVariant("บันทึกสำเร็จ", "success")

            })
            .catch((err) => {
                handleClickVariant("บันทึกไม่สำเร็จ", "error")
            })
    }

    const createUserID = () => {
        // const { id } = contact
        const { cogcode, cocode } = dataAccount
        const data = JSON.stringify({
            ...formUser
        });
        Api.post(`/v0/app/CreateUserID`, data, { headers: { CompanyGroup: cogcode } })
            .then((res) => {
                handleClickVariant("บันทึกสำเร็จ", "success")
                onHandleGet()
                handleCloseUserId()
            })
            .catch((err) => {
                handleClickVariant("บันทึกไม่สำเร็จ", "error")
            })
    }

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleClickVariant = (message, variant) => {
        enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });
    };

    const handleSnackWithAction = ({ message, onHandle }) => {
        closeSnackbar()
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => {
                return (
                    <React.Fragment>
                        <Stack direction="row" spacing={1}>
                            <Button
                                size='small'
                                color="error"
                                variant="contained"
                                onClick={() => {
                                    closeSnackbar(key)
                                    onHandle()
                                }}
                            >
                                <CheckRoundedIcon fontSize="small" />
                            </Button>
                            <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                                <CloseRoundedIcon fontSize="small" />
                            </Button>
                        </Stack>
                    </React.Fragment>
                )
            }
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target
        setForm(s => {
            return {
                ...s,
                [name]: value
            }
        })
    }
    const handleChangeUser = (e) => {
        const { name, value } = e.target
        setFormUser(s => {
            return {
                ...s,
                [name]: value
            }
        })
    }

    return (
        <Box>
            <IconButton onClick={handleOpen}>
                <PersonAddAltRoundedIcon />
            </IconButton>
            <Dialog
                open={open}
                maxWidth="sm"
                fullWidth={true}
                onClose={handleClose}
                slotProps={{
                    backdrop: {
                        style: {
                            // background: "#F8F8FF", opacity: 0.3
                            background: "#a7d8de", opacity: 0.2
                            // background: "#000", opacity: 0.1
                        }
                    }
                }}
            >
                <DialogTitle>สร้างข้อมูล</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => { setOpen(false) }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box>
                        <Stack >
                            <form id="FormSave" onSubmit={handleSave} >
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={12}>
                                        <TextField fullWidth size="small" label="คำนำหน้า" onChange={handleChange} value={form.prefixid} name='prefixid' />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField fullWidth size="small" label="ชื่อ" onChange={handleChange} value={form.firstname} name='firstname' required />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField fullWidth size="small" label="นามสกุล" onChange={handleChange} value={form.lastname} name='lastname' required />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField fullWidth size="small" label="วันเกิด" onChange={handleChange} value={form.birthday} name='birthday' />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField fullWidth size="small" label="เบอร์โทร" onChange={handleChange} value={form.phone} name='phone' />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField fullWidth size="small" label="email" onChange={handleChange} value={form.email} name='email' />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField fullWidth size="small" label="เลขที่ผู้เสียภาษี" onChange={handleChange} value={form.taxid} name='taxid' />
                                    </Grid>
                                </Grid>
                            </form>
                        </Stack>
                    </Box>
                </DialogContent >
                <DialogActions>
                    <Button type="submit" form="FormSave">บันทึก</Button>
                </DialogActions>
            </Dialog >




            <Dialog
                open={openUserId}
                maxWidth="sm"
                fullWidth={true}
                onClose={handleCloseUserId}
                slotProps={{
                    backdrop: {
                        style: {
                            // background: "#F8F8FF", opacity: 0.3
                            background: "#a7d8de", opacity: 0.2
                            // background: "#000", opacity: 0.1
                        }
                    }
                }}
            >
                <DialogTitle>สร้างบัญชีเข้าสู่ระบบ</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseUserId}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box>
                        <Stack >
                            <form id="FormSaveUserId" onSubmit={handleSaveUserId}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={12}>
                                        <TextField fullWidth size="small" label="userid" onChange={handleChangeUser} value={formUser.userid} name='userid' required />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField fullWidth size="small" type="password" label="password" onChange={handleChangeUser} value={formUser.password} name='password' required />
                                    </Grid>
                                </Grid>
                            </form>
                        </Stack>
                    </Box>
                </DialogContent >
                <DialogActions>
                    <Button type="submit" form="FormSaveUserId">บันทึก</Button>
                </DialogActions>
            </Dialog >
        </Box >
    )
}
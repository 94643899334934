import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Box, Stack, Typography, IconButton, Divider, TextField } from '@mui/material';
import ModeEditSharpIcon from '@mui/icons-material/ModeEditSharp';
import AddBoxIcon from '@mui/icons-material/AddBox';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import _ from 'lodash';
import { Virtuoso } from 'react-virtuoso';
import { makeStyles } from '@mui/styles';

export default function ControlSide({ poigeolist, map, onChangeSelect, poigeolistSelect, onDeletePoi }) {
    return (
        <div>
            <Virtuoso
                style={{ height: `calc(100vh - 120px)` }}
                data={poigeolist}
                itemContent={(index, data) => {
                    return (
                        <Box>
                            <Stack direction="row" spacing={1}>
                                <Box sx={{ width: `calc(100% - 60px)`, maxWidth: `calc(100% - 60px)`, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                    {data.poi_name}
                                </Box>
                                <Box>
                                    <Stack direction="row" spacing={1}>
                                        {
                                            poigeolistSelect.indexOf(data.poi_id) >= 0 ?
                                                <IconButton size="small" onClick={() => { onChangeSelect({ mode: "remove", select: data.poi_id }) }} ><VisibilityOutlinedIcon fontSize="inherit" /></IconButton>
                                                :
                                                <IconButton size="small" onClick={() => { onChangeSelect({ mode: "select", select: data.poi_id }) }} ><VisibilityOffOutlinedIcon fontSize="inherit" /></IconButton>
                                        }
                                        <IconButton size="small" onClick={() => { onDeletePoi(data.poi_id) }}><DeleteForeverOutlinedIcon fontSize="inherit" /></IconButton>
                                    </Stack>
                                </Box>
                            </Stack>
                        </Box>
                    )
                }
                }
            />
        </div>
    )
}

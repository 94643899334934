import React, { useEffect, useRef, useState } from 'react'
import { Apimongodb } from '../../components/api'
import { styled, alpha } from '@mui/material/styles';
import { Box, Stack, Paper, TextField, AppBar, Toolbar, IconButton, Typography, Drawer, useMediaQuery, Backdrop, Snackbar, Button } from '@mui/material';
import BottomNavigationBar from '../../layouts/BottomNavigationBar';
import { MHidden } from '../../components/@material-extend';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import ControlSide from './ControlSide';
import ContainerMap from './ContainerMap';
import { useSnackbar } from 'notistack';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export default function MainPoi({ account }) {
    const { c_coname } = account;

    const [open, setOpen] = useState(false);
    const [poigeolist, setpoigeolist] = useState([]);
    const [poigeolistshow, setpoigeolistshow] = useState([]);


    const [map, setMap] = useState(null);
    const [search, setSearch] = useState("");
    const refMapStyle = useRef(null);
    const [selectedMapStyle, setSelectedMapStyle] = useState(localStorage.getItem("selectedMapStyle") || "LongdoMap");


    const [poigeolistSelect, setpoigeolistSelect] = useState([]);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleClickVariant = ({ message, variant }) => {
        enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });

    };
    const handleSnackWithAction = ({ message, onHandle }) => {
        closeSnackbar()
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => {
                return (
                    <React.Fragment>
                        <Stack direction="row" spacing={1}>
                            <Button
                                size='small'
                                color="error"
                                variant="contained"
                                onClick={() => {
                                    closeSnackbar(key)
                                    onHandle()
                                }}
                            >
                                <CheckRoundedIcon fontSize="small" />
                            </Button>
                            <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                                <CloseRoundedIcon fontSize="small" />
                            </Button>
                        </Stack>
                    </React.Fragment>
                )
            }
        });
    };


    useEffect(() => {
        Apimongodb.get(`/Tracking/poigeocodeall?cname=${c_coname}`)
            .then(res => {
                setpoigeolist(res.data.result)
                // setpoigeolistSelect(select)
                onGetPoigeocodelist(res.data.result, true)
            })
            .catch(err => {
                console.log('err', err)
            })
    }, [])
    const onGetPoigeoByCname = (result, firsttime) => {
        Apimongodb.get(`/Tracking/poigeocodeall?cname=${c_coname}`)
            .then(res => {
                setpoigeolist(res.data.result)

                onGetPoigeocodelist(res.data.result)
            })
            .catch(err => {
                console.log('err', err)
            })
    }


    const onGetPoigeocodelist = (result, firsttime) => {
        const select = result.map(v => v.poi_id)
        if (firsttime) {
            setpoigeolistSelect(select)
        }
        var data = JSON.stringify({
            "cname": "FIRST",
            "poi_id": select
        });
        Apimongodb.post(`/Tracking/poigeocodelist`, data)
            .then(res => {
                setpoigeolistshow(res.data.result)
            })
            .catch(err => {
                console.log('err', err)
            })
    }

    const onChangeSelect = ({ mode, select }) => {
        if (mode === "select") {
            const ndata = poigeolistSelect.concat(select)
            setpoigeolistSelect(ndata)
        } else {
            const ndata = poigeolistSelect.filter(v => v !== select)
            setpoigeolistSelect(ndata)
        }
    }

    const onDeletePoi = (poi_id) => {
        handleSnackWithAction({
            message: "ต้องการที่จะลบ?", onHandle: () => {
                const req = poigeolistshow.find(v => v.poi_id === poi_id)
                Apimongodb.delete(`/Tracking/poigeocode`, { data: req })
                    .then((res) => {
                        onGetPoigeoByCname()
                    })
                    .catch((error) => {
                        handleClickVariant({ message: "ลบข้อมูลไม่สำเร็จ", variant: "error" })
                        console.log(error);
                    });
            }
        })
    }




    const poigeolistfilter = poigeolist.filter(v => v.poi_name.toLowerCase().includes(search.toLowerCase()))
    const poigeolistshowfilter = poigeolistshow.filter((item) => {
        return poigeolistSelect.indexOf(item.poi_id) >= 0
    }).filter(v => v.poi_name.toLowerCase().includes(search.toLowerCase()))




    return (
        <RootStyle>
            <AppBarStyle elevation={3} >
                <ToolbarStyle>
                    <IconButton onClick={() => setOpen(!open)} sx={{ mr: 1, color: 'text.primary' }}>
                        <MenuRoundedIcon />
                    </IconButton>
                </ToolbarStyle>
            </AppBarStyle>
            <DrawerStyle >
                <MHidden width="mdUp">
                    <Drawer
                        open={open}
                        onClose={() => setOpen(!open)}
                        // variant="permanent"
                        PaperProps={{
                            sx: { width: 320 }
                        }}
                    >
                        <Box sx={{ px: 1, py: 1 }}>
                            <Toolbar style={{ minHeight: '64px', paddingLeft: 35, paddingRight: 35 }}>
                                <Typography variant='h4' >POI</Typography>
                                <Box sx={{ flexGrow: 1 }} />
                            </Toolbar>
                        </Box>
                        <Box sx={{ paddingX: "5px" }}>
                            <ControlSide poigeolist={poigeolistfilter} map={map} onChangeSelect={onChangeSelect} poigeolistSelect={poigeolistSelect} onDeletePoi={onDeletePoi} />
                        </Box>
                    </Drawer>
                </MHidden>
                <MHidden width="mdDown">
                    <Drawer
                        open={true}
                        variant="permanent"
                        PaperProps={{
                            sx: { width: 320, height: 'calc(100% - 56px)', bottom: 56, padding: '10px' }
                        }}
                    >
                        <Box>
                            <Toolbar style={{ minHeight: '30px', paddingLeft: "0px", paddingRight: "0px" }}>
                                {/* <Typography variant='h4'>POI</Typography>
                                <Box sx={{ flexGrow: 1 }} /> */}
                                <TextField size="small" placeholder='POI' fullWidth value={search} onChange={(e) => { setSearch(e.target.value) }} />
                            </Toolbar>
                        </Box>
                        <Box sx={{ paddingX: "5px" }}>
                            <ControlSide poigeolist={poigeolistfilter} map={map} onChangeSelect={onChangeSelect} poigeolistSelect={poigeolistSelect} onDeletePoi={onDeletePoi} />
                        </Box>
                        <Paper sx={{ position: 'fixed', width: 320, bottom: 0, left: 0, right: 0, zIndex: 2 }} elevation={3}>
                            <BottomNavigationBar ative="settings" />
                        </Paper>
                    </Drawer>
                </MHidden>
            </DrawerStyle>
            <MainStyle>
                <Box>
                    <ContainerMap
                        map={map}
                        setMap={setMap}
                        refMapStyle={refMapStyle}
                        selectedMapStyle={selectedMapStyle}
                        setSelectedMapStyle={setSelectedMapStyle}
                        poigeolistshow={poigeolistshowfilter}
                        onGetPoigeoByCname={onGetPoigeoByCname}
                        setpoigeolistshow={setpoigeolistshow}
                        onChangeSelect={onChangeSelect}
                    />
                </Box>
                <MHidden width="mdUp">
                    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                        <BottomNavigationBar ative="settings" />
                    </Paper>
                </MHidden>
            </MainStyle>
        </RootStyle>
    )
}


const RootStyle = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden'
});
const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    [theme.breakpoints.down('md')]: {
        paddingTop: 66,
        paddingBottom: 56,
    }
}));


const AppBarStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    width: 0,
    maxHeight: 64,
    [theme.breakpoints.down('md')]: {
        // width: `calc(100% - ${350 + 1}px)`
        width: "100%"
    }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    WebkitBoxAlign: 'center',
    alignItems: 'center',
    paddingLeft: '16px',
    paddingRight: '16px',
    minHeight: '64px',
}));
const DrawerStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        flexShrink: 0,
        width: 320
    },
    width: 0,
}));


// const DrawControl = ({ editMode, setEditMode, editRef, drawCircle }) => {

import EditLocationAltTwoToneIcon from '@mui/icons-material/EditLocationAltTwoTone';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import { Box, IconButton } from '@mui/material';
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import { useState } from 'react';
import PolylineRoundedIcon from '@mui/icons-material/PolylineRounded';
import RectangleOutlinedIcon from '@mui/icons-material/RectangleOutlined';
import HexagonOutlinedIcon from '@mui/icons-material/HexagonOutlined';
import HasPermission from '../../haspermission/HasPermission';

export default function DrawControl({ editRef, setPoiSelect, setHandleEditData, permissions }) {

    return (
        <Box
            sx={{
                zIndex: 999,
                position: "absolute",
                top: "10px",
                textAlign: "center",
                // left: "50%",
                left: "10px",
                backgroundColor: "white",
                borderRadius: "5px",
                flexDirection: "row",
                display: "flex"
            }}
        >
            <Box
                sx={{
                    width: "36px",
                    height: "36px",
                    margin: "2px"
                }}
            >
                <IconButton
                    onClick={() => {
                        setPoiSelect(null)
                        setHandleEditData(null)
                        if (editRef?._toolbars.edit._activeMode !== null) {
                            editRef?._toolbars.draw._modes.circle.handler.disable();
                        }

                        editRef?._toolbars.draw._modes.rectangle.handler.disable();
                        editRef?._toolbars.draw._modes.polyline.handler.disable();
                        editRef?._toolbars.draw._modes.polygon.handler.disable();

                        editRef?._toolbars.draw._modes.circle.handler.enable();
                    }}
                >
                    <CircleTwoToneIcon fontSize="small" />
                </IconButton>
            </Box>
            <Box
                sx={{
                    width: "36px",
                    height: "36px",
                    margin: "2px"
                }}
            >
                <IconButton
                    // disabled
                    onClick={() => {
                        setPoiSelect(null)
                        setHandleEditData(null)
                        if (editRef?._toolbars.edit._activeMode !== null) {
                            editRef?._toolbars.draw._modes.polyline.handler.disable();
                        }

                        editRef?._toolbars.draw._modes.rectangle.handler.disable();
                        editRef?._toolbars.draw._modes.circle.handler.disable();
                        editRef?._toolbars.draw._modes.polygon.handler.disable();

                        editRef?._toolbars.draw._modes.polyline.handler.enable();
                    }}
                >
                    <PolylineRoundedIcon fontSize="small" />
                </IconButton>
            </Box>
            <Box
                sx={{
                    width: "36px",
                    height: "36px",
                    margin: "2px"
                }}
            >
                <IconButton
                    // disabled
                    onClick={() => {
                        setPoiSelect(null)
                        setHandleEditData(null)
                        if (editRef?._toolbars.edit._activeMode !== null) {
                            editRef?._toolbars.draw._modes.rectangle.handler.disable();
                        }

                        editRef?._toolbars.draw._modes.polyline.handler.disable();
                        editRef?._toolbars.draw._modes.circle.handler.disable();
                        editRef?._toolbars.draw._modes.polygon.handler.disable();

                        editRef?._toolbars.draw._modes.rectangle.handler.enable();
                    }}
                >
                    <RectangleOutlinedIcon fontSize="small" />
                </IconButton>
            </Box>
            <Box
                sx={{
                    width: "36px",
                    height: "36px",
                    margin: "2px"
                }}
            >
                <IconButton
                    // disabled
                    onClick={() => {
                        setPoiSelect(null)
                        setHandleEditData(null)
                        if (editRef?._toolbars.edit._activeMode !== null) {
                            editRef?._toolbars.draw._modes.polygon.handler.disable();
                        }

                        editRef?._toolbars.draw._modes.polyline.handler.disable();
                        editRef?._toolbars.draw._modes.circle.handler.disable();
                        editRef?._toolbars.draw._modes.rectangle.handler.disable();

                        editRef?._toolbars.draw._modes.polygon.handler.enable();
                    }}
                >
                    <HexagonOutlinedIcon fontSize="small" />
                </IconButton>
            </Box>
        </Box>
    )
}
import React, { useState } from 'react'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker, LocalizationProvider, TimePicker } from '@mui/lab';
import { Box, Collapse, TextField, Stack, Grid, Checkbox, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/styles';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useSnackbar } from 'notistack';
import DialogDriver from './DialogDriver';
import _ from 'lodash';
import DialogBushostess from './DialogBushostess';
import DialogTrip from './DialogTrip';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import ButtonDialogWork from './ButtonDialogWork';
import ButtonDialogWorkUpdate from './ButtonDialogWorkUpdate';
import { fDateTo } from '../../utils/formatTime';
import DialogTripUpdate from './DialogTripUpdate';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepIcon from '@mui/material/StepIcon';
import WorkTypeDialog from './WorkTypeDialog';
import CustomerDialog from './CustomerDialog';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function CreateBooking() {
  const [expanded, setExpanded] = React.useState(false);



  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const phoneRegExp = /((\+66|0)(\d{1,2}-?\d{3}-?\d{3,4}))/;

  const FormSchema = Yup.object().shape({
    booking_type: Yup.string().required('required'),
    booking_work_type: Yup.string().required('required'),
    contact_phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').min(10).required('required'),
    contact: Yup.string().required('required'),
    customer: Yup.object().shape({
      // prefix: Yup.string().required('required'), 
      firstname: Yup.string().required('required'),
      lastname: Yup.string().required('required'),
    })
    // customer: Yup.string().required('required'),
  });
  const formik = useFormik({
    initialValues: {
      "booking_id": "",
      "date_create": "",
      "booking_type": "",
      "booking_start": fDateTo(new Date(), "yyyy-MM-dd"),
      "booking_start_time": "00:00",
      "booking_end": fDateTo(new Date(), "yyyy-MM-dd"),
      "booking_end_time": "23:59",
      "booking_work_type": "",
      "booking_ref": "",
      "contact_phone": "",
      "contact": "",
      "status": "",
      "remark": "",
      "requirement": [],
      "customer": {
        "prefix": "",
        "firstname": "",
        "lastname": "",
        "address": "",
        "address_type": "",
        "branch": "",
        "district": "",
        "subdistrict": "",
        "province": "",
        "postcode": "",
        "taxid": "",
        "phone": "",
      },
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {

      console.log('values', values)
      setValues(b => {
        return {
          ...b,
          trips: []
        }
      })
      setActiveStep(1)
    }
  });
  const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setErrors, resetForm, setFieldValue, setValues, values } = formik;



  const { enqueueSnackbar, closeSnackbar } = useSnackbar();


  const handleClickVariant = (message, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });

  };



  const handleSnackWithAction = ({ message, onHandle }) => {
    closeSnackbar()
    enqueueSnackbar(message, {
      anchorOrigin: {
        horizontal: "center",
        vertical: 'bottom'
      },
      variant: 'default',
      // style: { bgcolor: "whitesmoke !important" },
      action: (key) => {
        return (
          <React.Fragment>
            <Stack direction="row" spacing={1}>
              <Button
                size='small'
                color="error"
                variant="contained"
                onClick={() => {
                  closeSnackbar(key)
                  onHandle()
                }}
              >
                <CheckRoundedIcon fontSize="small" />
              </Button>
              <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                <CloseRoundedIcon fontSize="small" />
              </Button>
            </Stack>
          </React.Fragment>
        )
      }
    });
  };


  const onHandleCreateWork = (trip_id, cars_control_id, work) => {

    const gt = values.trips.find(t => t.trip_id === trip_id)
    const nt = values.trips.filter(t => t.trip_id !== trip_id)

    const ct = gt.cars_control.find(ct => ct.cars_control_id === cars_control_id)
    const nct = gt.cars_control.filter(ct => ct.cars_control_id !== cars_control_id)

    const { works } = ct

    const n_t = { ...ct, works: [...works, work] }
    const n_b = [...nt, { ...gt, cars_control: [...nct, n_t] }]

    const newwork = { ...values, trips: n_b }



    setValues(newwork)
    handleClickVariant("เพิ่มข้อมูลเสร็จสิ้น !", "success")


  }
  const onHandleChangeWork = (trip_id, cars_control_id, work) => {

    const gt = values.trips.find(t => t.trip_id === trip_id)
    const nt = values.trips.filter(t => t.trip_id !== trip_id)

    const ct = gt.cars_control.find(ct => ct.cars_control_id === cars_control_id)
    const nct = gt.cars_control.filter(ct => ct.cars_control_id !== cars_control_id)

    const { works } = ct
    const n_w = works.filter(ct => ct.car_id !== work.car_id)

    const n_t = { ...ct, works: [...n_w, work] }
    const n_b = [...nt, { ...gt, cars_control: [...nct, n_t] }]


    setValues(E => {
      return ({ ...E, trips: n_b, })
    })
    handleClickVariant("เพิ่มข้อมูลเสร็จสิ้น !", "success")


  }

  const onHandleDelCar = (trip_id, car_id, cars_control_id) => {
    const gt = values.trips.find(t => t.trip_id === trip_id)
    const nt = values.trips.filter(t => t.trip_id !== trip_id)

    const ct = gt.cars_control.find(ct => ct.cars_control_id === cars_control_id)
    const nct = gt.cars_control.filter(ct => ct.cars_control_id !== cars_control_id)

    const { works } = ct

    const nw = works.filter(t => t.car_id !== car_id)

    const n_t = { ...ct, works: [...nw] }
    const n_b = [...nt, { ...gt, cars_control: [...nct, n_t] }]


    setValues(E => {
      return ({ ...E, trips: n_b, })
    })
    handleClickVariant("ลบข้อมูลเสร็จสิ้น !", "success")

  }

  const calculateSum = (data) => {
    let sum = 0;
    data.forEach(item => {
      sum += _.isString(item.car_capacity) ? Number(item.car_capacity) : item.car_capacity;
    });
    return sum;
  };


  const onHandleSubmit = (trip) => {
    setValues(b => {
      return ({ ...b, trips: [...b.trips, trip] })
    })
  }

  const onHandleSubmitEdit = (trip) => {
    const nt = values.trips.filter(t => t.trip_id !== trip.trip_id)
    setValues(b => {
      return ({ ...b, trips: [...nt, trip] })
    })
  }

  const handleCustomer = (e) => {

    const { name, value } = e.target
    setValues(e => {
      return ({
        ...e, customer: { ...e.customer, [name]: value }
      })
    })
  }

  const onChangeBooking = (e) => {
    const { name, value } = e.target
    setValues(b => {
      return ({
        ...b,
        [name]: value
      })
    })
  }

  const onChangeBookingDate = (name, value) => {
    setFieldValue(name, value)
  }



  const haveCars = (value) => {
    var check = false
    value?.cars_control.map((cars) => {
      cars?.works.map(c => {
        check = true
        return
      })
    })
    return check
  }


  const onDelTrip = (trip_id) => {
    const filter = values.trips.filter(t => t.trip_id !== trip_id)
    setValues(e => {
      return {
        ...e,
        trips: filter
      }
    })
    handleClickVariant("ลบข้อมูลเสร็จสิ้น !", "success")
  }


  const onChangeType = (d) => {



    setValues(v => {
      return ({
        ...v,
        ...d
      })
    })
  }

  const onChangeCustomer = (d) => {
    setValues(b => {
      return ({
        ...b,
        customer: { ...d }
      })
    })
  }

  const [activeStep, setActiveStep] = useState(0)



  const calculateEmp = (data = []) => {
    // let sum = 0;
    // data.forEach(item => {
    //   // sum += _.isString(item.works?.drivers) ? Number(item.car_capacity) : item.car_capacity;
    //   // แก้ต่อ
    // });
    // return sum;
    return data.length
  };


  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box sx={{ padding: "10px" }}>
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              <Step>
                <StepLabel
                // StepIconComponent={(props) => {
                //   return (<StepIcon {...props}>
                //     <VideoLabelIcon />
                //   </StepIcon>)
                // }}
                >รับเรื่อง</StepLabel>
              </Step>
              <Step>
                <StepLabel>จัดรถ</StepLabel>
              </Step>
              <Step>
                <StepLabel>ยืนยัน</StepLabel>
              </Step>
              <Step>
                <StepLabel>วิ่งงาน</StepLabel>
              </Step>
              <Step>
                <StepLabel>ชำระเงิน</StepLabel>
              </Step>
            </Stepper>
          </Box>
          <Stack spacing={1}>
            <Box><Typography variant="h6" sx={{ textAlign: "center" }} >ใบจอง</Typography></Box>
            <Box>
              <Stack >
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Grid container spacing={1} >
                      <Grid item xs={12} md={6}>
                        <Grid container spacing={1} >
                          <Grid item xs={5} md={5}>
                            <TextField size="small" label='กลุ่มประเภท' fullWidth
                              value={values.booking_type}
                              // onChange={onChangeBooking}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end" >
                                    <WorkTypeDialog onHandle={(d) => { onChangeType(d) }} />
                                    {/* <IconButton size="small" ><SearchIcon fontSize="inherit" /></IconButton> */}
                                  </InputAdornment>
                                )
                              }}
                              name='booking_type'
                              error={Boolean(touched.booking_type && errors.booking_type)}
                              helperText={touched.booking_type && errors.booking_type}
                              {...getFieldProps('booking_type')}
                            />
                          </Grid>
                          <Grid item xs={7} md={7}>
                            <TextField size="small" label='ประเภทงาน'
                              name='booking_work_type'
                              {...getFieldProps('booking_work_type')}
                              error={Boolean(touched.booking_work_type && errors.booking_work_type)}
                              helperText={touched.booking_work_type && errors.booking_work_type}
                              fullWidth
                              // onChange={onChangeBooking}
                              value={values.booking_work_type}
                            />
                          </Grid>

                          <Grid item xs={6} md={6}>
                            <DesktopDatePicker
                              disablePast
                              label="วันที่เริ่มจอง"
                              inputFormat="dd/MM/yyyy"
                              value={values.booking_start}
                              name='booking_start'
                              onChange={(value) => { onChangeBookingDate("booking_start", value) }}
                              renderInput={(params) => <TextField size="small" label='วันที่เริ่มจอง' name='booking_start' fullWidth {...params} />}
                            />
                            {/* <TextField size="small" label='วันที่เริ่มจอง' name='booking_start' fullWidth
                          value={booking.booking_start}
                          onChange={onChangeBooking}

                        /> */}
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <TimePicker
                              ampm={false}
                              label="เวลา"
                              value={fDateTo(new Date(fDateTo(new Date(), "yyyy-MM-dd") + " " + values?.booking_start_time), "yyyy-MM-dd HH:mm")}
                              name='booking_start_time'
                              onChange={(value) => { onChangeBookingDate("booking_start_time", fDateTo(value, "HH:mm")) }}
                              renderInput={(params) => <TextField size="small" label='รับเวลา' name='pickup_time' fullWidth {...params} />}
                            />
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <DesktopDatePicker
                              disablePast
                              label="วันที่สิ้นสุดจอง"
                              inputFormat="dd/MM/yyyy"
                              value={values.booking_end}
                              name='booking_end'
                              onChange={(value) => { onChangeBookingDate("booking_end", value) }}
                              renderInput={(params) => <TextField size="small" label='วันที่สิ้นสุดจอง' name='booking_end' fullWidth {...params} />}
                            />
                            {/* <TextField size="small" label='วันที่สิ้นสุดจอง' name='booking_end' fullWidth
                          value={booking.booking_end}
                          onChange={onChangeBooking}

                        /> */}
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <TimePicker
                              ampm={false}
                              label="เวลา"
                              value={fDateTo(new Date(fDateTo(new Date(), "yyyy-MM-dd") + " " + values?.booking_end_time), "yyyy-MM-dd HH:mm")}
                              name='booking_end_time'
                              onChange={(value) => { onChangeBookingDate("booking_end_time", fDateTo(value, "HH:mm")) }}
                              renderInput={(params) => <TextField size="small" label='รับเวลา' name='pickup_time' fullWidth {...params} />}
                            />
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <TextField size="small" label='คำนำหน้า' name='prefix' fullWidth
                              value={values.customer.prefix}
                              onChange={handleCustomer}
                            // {...getFieldProps("customer.prefix")}
                            // error={Boolean(touched.customer?.prefix && errors.customer?.prefix)}
                            // helperText={touched.customer?.prefix && errors.customer?.prefix}
                            // InputLabelProps={{ shrink: formCus.prefix ? true : false }} 
                            />
                          </Grid>
                          <Grid item xs={12} md={5}>
                            <TextField size="small" label='ชื่อ' fullWidth
                              // InputLabelProps={{ shrink: formCus.name ? true : false }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end" >
                                    <CustomerDialog onHandle={(d) => { onChangeCustomer(d) }} />
                                  </InputAdornment>
                                )
                              }}
                              value={values.customer.firstname}
                              onChange={handleCustomer}
                              name='firstname'
                              {...getFieldProps("customer.firstname")}
                              error={Boolean(touched.customer?.firstname && errors.customer?.firstname)}
                              helperText={touched.customer?.firstname && errors.customer?.firstname}
                            />
                          </Grid>
                          <Grid item xs={12} md={5}>
                            <TextField size="small" label='นามสกุล' fullWidth
                              value={values.customer.lastname}
                              onChange={handleCustomer}
                              name='lastname'
                              {...getFieldProps("customer.lastname")}

                              error={Boolean(touched.customer?.lastname && errors.customer?.lastname)}
                              helperText={touched.customer?.lastname && errors.customer?.lastname}
                            />
                          </Grid>
                        </Grid>
                        <Collapse in={true} timeout="auto" unmountOnExit style={{ marginTop: "10px", marginBottom: "10px" }}>
                          <Grid container spacing={1} >
                            <Grid item xs={8} md={8}>
                              <TextField size="small" label='เลขที่ผู้เสียภาษี' name='taxid' fullWidth
                                value={values.customer.taxid !== null ? values.customer.taxid : ""}
                                onChange={handleCustomer}

                              // InputLabelProps={{ shrink: formCus.taxId ? true : false }}
                              />
                            </Grid>
                            <Grid item xs={4} md={4}>
                              <TextField size="small" label='สาขา' name='branch' fullWidth
                                value={values.customer.branch}
                                onChange={handleCustomer}

                              // InputLabelProps={{ shrink: formCus.branch ? true : false }}
                              />
                            </Grid>
                            <Grid item xs={9} md={9}>
                              <TextField size="small" label='ที่อยู่' name='address' fullWidth
                                value={values.customer.address}
                                onChange={handleCustomer}

                              // InputLabelProps={{ shrink: formCus.address ? true : false }}
                              />
                            </Grid>
                            <Grid item xs={3} md={3}>
                              <TextField size="small" label='ประเภทที่อยู่' name='address_type' fullWidth
                                value={values.customer.address_type}
                                onChange={handleCustomer}

                              // InputLabelProps={{ shrink: formCus.addresstype ? true : false }} 
                              />
                            </Grid>
                            <Grid item xs={6} md={6}>
                              <TextField size="small" label='แขวง/ตำบล' name='subdistrict' fullWidth
                                value={values.customer.subdistrict}
                                onChange={handleCustomer}

                              // InputLabelProps={{ shrink: formCus.subdistrict ? true : false }} 
                              />
                            </Grid>
                            <Grid item xs={6} md={6}>
                              <TextField size="small" label='เขต/อำเภอ' name='district' fullWidth
                                value={values.customer.district}
                                onChange={handleCustomer}

                              // InputLabelProps={{ shrink: formCus.district ? true : false }} 
                              />
                            </Grid>
                            <Grid item xs={6} md={6}>
                              <TextField size="small" label='จังหวัด' name='province' fullWidth
                                value={values.customer.province}
                                onChange={handleCustomer}


                              // InputLabelProps={{ shrink: formCus.province ? true : false }}
                              />
                            </Grid>
                            <Grid item xs={6} md={6}>
                              <TextField size="small" label='รหัสไปรษณีย์' name='postcode' fullWidth
                                value={values.customer.postcode}
                                onChange={handleCustomer}


                              // InputLabelProps={{ shrink: formCus.zipCode ? true : false }}
                              />
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <TextField size="small" label='เบอร์ติดต่อ' name='phone' fullWidth
                                value={values.customer.phone}
                                onChange={handleCustomer}

                              // InputLabelProps={{ shrink: formCus.tel ? true : false }}
                              />
                            </Grid>
                          </Grid>
                        </Collapse>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Grid container spacing={1} >
                          <Grid item xs={12} md={12}>
                            <TextField size="small" label='เลขที่อ้างอิง' name='booking_ref' fullWidth
                              // onChange={onChangeBooking}
                              {...getFieldProps('booking_ref')}
                              value={values.booking_ref}
                            />
                          </Grid>
                          <Grid item xs={5} md={5}>
                            <TextField size="small" label='ผู้ติดต่อ' name='contact' fullWidth
                              // onChange={onChangeBooking}
                              {...getFieldProps('contact')}
                              value={values.contact}
                              error={Boolean(touched.contact && errors.contact)}
                              helperText={touched.contact && errors.contact}
                            />
                          </Grid>
                          <Grid item xs={7} md={7}>
                            <TextField size="small" label='เบอร์โทร' name='contact_phone' fullWidth
                              {...getFieldProps('contact_phone')}
                              // onChange={onChangeBooking}
                              value={values.contact_phone}
                              error={Boolean(touched.contact_phone && errors.contact_phone)}
                              helperText={touched.contact_phone && errors.contact_phone}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Autocomplete
                              multiple
                              limitTags={2}
                              id="multiple-limit-tags"
                              value={values.requirement}
                              isOptionEqualToValue={(option, value) => option?.title === value.title}
                              options={[{ title: "น้ำดื่ม" }, { title: "ผ้าเย็น" }, { title: "ขนม" }]}
                              getOptionLabel={(option) => option?.title}
                              onChange={(event, newValue) => {
                                setFieldValue("requirement", newValue);
                              }}
                              renderInput={(params) => (
                                <TextField size="small" label='รายละเอียดความต้องการ' {...params} sx={{ overFlow: "auto" }} fullWidth />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField size="small" label='หมายเหตุ' multiline maxRows={3} minRows={3}
                              value={values.remark}
                              {...getFieldProps('remark')}
                              // onChange={onChangeBooking}
                              name='remark' fullWidth />
                            {/* <Stack spacing={1} direction="row">
                          <Checkbox name='autoTrip' />
                          <Typography sx={{ paddingTop: "8px" }} >Auto trip</Typography>
                        </Stack> */}
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Button
                              type="submit"
                              variant="outlined">บันทึก</Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                </FormikProvider>
                <Box sx={{ marginBottom: "10px" }}>
                  <Divider />
                </Box>
                {
                  values.trips && (
                    <Box>
                      <Box sx={{ padding: "10px" }}>
                        <DialogTrip dropoff_date={values.booking_end} pickup_date={values.booking_start} onHandleSubmit={onHandleSubmit} />
                      </Box>
                      <Box sx={{ minHeight: "250px", height: "400px", borderStyle: "solid", borderColor: "#e5e7eb", borderRadius: "10px", overflow: "auto" }}>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow >
                              <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", width: 60, textAlign: "left" }}>

                              </TableCell>
                              <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                เลขที่ใบเดินทาง
                              </TableCell>
                              <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                วันที่เริ่มจอง
                              </TableCell>
                              <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                วันที่สิ้นสุดจอง
                              </TableCell>
                              <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                ต้นทาง
                              </TableCell>
                              <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                ปลายทาง
                              </TableCell>
                              <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                จำนวนรถ
                              </TableCell>
                              <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                พนักงานคนขับ
                              </TableCell>
                              <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                พนักงานต้อนรับ
                              </TableCell>
                              <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                action
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              _.orderBy(values.trips, ["trip_id", ["asc"]]).map((value, index) => {
                                // _.orderBy(booking.trips, ["tripNo"], ["asc"]).map((value, index) => {
                                return (
                                  <React.Fragment key={`TableRow-${index + 1}`}>
                                    <TableRow  >
                                      <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", width: 60, textAlign: "left" }}>
                                        {index + 1}
                                      </TableCell>
                                      <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                        {value.trip_id}
                                      </TableCell>
                                      <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                        {/* {fDateTo(value.dateStart, "dd-MM-yyyy")} */}
                                        {fDateTo(value.pickup_date, "dd-MM-yyyy")}
                                      </TableCell>
                                      <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                        {/* {fDateTo(value.dateEnd, "dd-MM-yyyy")} */}
                                        {fDateTo(value.dropoff_date, "dd-MM-yyyy")}
                                      </TableCell>
                                      <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                        {value.pickup_location}
                                      </TableCell>
                                      <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                        {value.dropoff_location}
                                      </TableCell>
                                      <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                        {value.trips_cars.map(v => v.car_type + ":" + v.car_capacity)}
                                      </TableCell>
                                      <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "center" }}>
                                        {value.employees.driver_capacity}
                                      </TableCell>
                                      <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "center" }}>
                                        {value.employees.bushostess_capacity}
                                        {/* {value.employees.bushostess_capacity + "," + value.employees.bushostess_capacity} */}
                                      </TableCell>
                                      <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                        <Stack spacing={1} direction="row" justifyContent="end">
                                          <DialogTripUpdate data={value} onHandleSubmit={onHandleSubmitEdit} />
                                          <IconButton onClick={() => {
                                            handleSnackWithAction({
                                              message: "ต้องการลบข้อมูลทริป?", onHandle: () => {
                                                onDelTrip(value.trip_id)
                                              }
                                            })
                                          }} size="small"
                                            disabled={haveCars(value)}
                                          ><DeleteOutlineIcon fontSize="inherit" /></IconButton>
                                        </Stack>
                                      </TableCell>
                                    </TableRow>
                                    {
                                      _.orderBy(value.cars_control, ["cars_control_id", ["asc"]]).map((ct, ict) => {
                                        return (
                                          <>
                                            <TableRow >
                                              <TableCell colSpan={1} sx={{ padding: "5px", whiteSpace: "nowrap", width: 60, textAlign: "left" }}>
                                                <ReportProblemRoundedIcon color="warning" />
                                              </TableCell>
                                              <TableCell colSpan={8} sx={{ padding: "5px", whiteSpace: "nowrap", width: 60, textAlign: "left" }}>
                                                {`${ct.car_type} : ${ct.car_name}`}
                                              </TableCell>
                                              <TableCell colSpan={2} sx={{ padding: "5px", whiteSpace: "nowrap", width: 60, textAlign: "right" }}>
                                                {/* <IconButton onClick={() => { setOpenTripD(true) }} disabled={value.cars.length >= calculateSum(value.trips_cars)} size="small"><AddOutlinedIcon fontSize="inherit" /></IconButton> */}

                                                <ButtonDialogWork
                                                  // disabled={calculateEmp}
                                                  employees={value.employees}
                                                  car_type={ct.car_type}
                                                  data={value}
                                                  onHandle={(work) => { onHandleCreateWork(value.trip_id, ct.cars_control_id, work) }}
                                                />
                                              </TableCell>
                                            </TableRow>
                                            {
                                              calculateSum(value.trips_cars) > 0 && (
                                                <TableRow >
                                                  <TableCell colSpan={11} sx={{ padding: "0px" }}>
                                                    <Box>
                                                      <Table>
                                                        <TableBody>
                                                          {
                                                            _.orderBy(ct.works, ["car_id", ["asc"]]).map((work, Index) => {
                                                              return (
                                                                <TableRow>
                                                                  <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", width: 60, textAlign: "left" }}>

                                                                  </TableCell>
                                                                  <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                                    <TextField size="small" label='วันที่เริ่ม' fullWidth
                                                                      value={fDateTo(work.pickup_date, "dd/MM/yyyy")}
                                                                      name="pickup_date"
                                                                      disabled
                                                                      inputProps={{
                                                                        style: {
                                                                          height: "15px",
                                                                          fontSize: "12px"
                                                                        }
                                                                      }}
                                                                      InputLabelProps={{ style: { fontSize: '12px' } }}
                                                                    />
                                                                  </TableCell>
                                                                  <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                                    <TextField size="small" label='วันที่สิ้นสุด' fullWidth
                                                                      name="dropoff_date"
                                                                      value={fDateTo(work.dropoff_date, "dd/MM/yyyy")}
                                                                      disabled
                                                                      inputProps={{
                                                                        style: {
                                                                          height: "15px",
                                                                          fontSize: "12px"
                                                                        }
                                                                      }}
                                                                      InputLabelProps={{ style: { fontSize: '12px' } }}
                                                                    />
                                                                  </TableCell>
                                                                  <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                                    <TextField size="small" label='ทะเบียนรถ' fullWidth
                                                                      value={work.licensePlate}
                                                                      disabled
                                                                      inputProps={{
                                                                        style: {
                                                                          height: "15px",
                                                                          fontSize: "12px"
                                                                        }
                                                                      }}
                                                                      InputLabelProps={{ style: { fontSize: '12px' } }}
                                                                    />
                                                                  </TableCell>
                                                                  <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                                    <TextField size="small" label='พนักงาน' fullWidth
                                                                      value={work?.drivers?.map((v, i) => v.name).toString()}
                                                                      disabled
                                                                      inputProps={{
                                                                        style: {
                                                                          height: "15px",
                                                                          fontSize: "12px"
                                                                        }
                                                                      }}
                                                                      InputLabelProps={{ style: { fontSize: '12px' } }}
                                                                    />
                                                                  </TableCell>
                                                                  <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                                    <Stack spacing={1} direction="row" justifyContent="end">
                                                                      <ButtonDialogWorkUpdate
                                                                        employees={value.employees}
                                                                        data={work} onHandle={(work) => { onHandleChangeWork(value.trip_id, ct.cars_control_id, work) }} />
                                                                      <IconButton onClick={() => { handleSnackWithAction({ message: "ต้องการลบข้อมูลรถ?", onHandle: () => { onHandleDelCar(value.trip_id, work.car_id, ct.cars_control_id) } }) }} size="small"><DeleteOutlineIcon fontSize="inherit" /></IconButton>
                                                                    </Stack>
                                                                  </TableCell>
                                                                </TableRow>
                                                              )
                                                            })
                                                          }
                                                        </TableBody>
                                                      </Table>
                                                    </Box>
                                                  </TableCell>
                                                </TableRow >
                                              )
                                            }</>
                                        )
                                      })
                                    }
                                  </React.Fragment>
                                )
                              })
                            }
                          </TableBody >
                        </Table >
                      </Box>
                    </Box>)
                }
              </Stack>
            </Box>
          </Stack>
        </Box>
      </LocalizationProvider>
    </div>
  )
}
